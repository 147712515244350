import { useState } from 'react'
import useRest from 'hooks/useRest'
import { UploadFile } from 'antd/lib/upload/interface'
import { TFile } from 'pages/Files/type'
import { AxiosResponse } from 'axios'


export type TFileResponse = {
  code?: number
  fileName?: string
  isError?: boolean
  message?: string
  errorDetail?: TErrorDetail[]
  logDownloadId?: number | null
  key?: string
  date?: string
}

export type TErrorDetail = {
  row: number
  column: string
  message: string
}

export type TFileComplete = TFileResponse & UploadFile & TFile


export type TImportResponse = TCommonResponse & {content: TFileResponse[]}

export type IportRestContent = {
  commonResponse: TCommonResponse
  success: {
    requestId: string
  }
}

export type TImportRestResponse = TCommonResponse & {
  content: Array<IportRestContent>
}


const useImport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const importFiles = async (data: FormData, triggerLoading = true) => {
    if (triggerLoading) setIsLoading(true)
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
    const url = '/time-check/import'
    const response = await rest.postHeader(url, data, header)
    if (triggerLoading) setIsLoading(false)
    return response as AxiosResponse<TImportRestResponse>
  }

  const getErrorLog = async (id: number, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = `/time-check/error-log/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  return {
    isLoading,
    importFiles,
    getErrorLog,
  }
}

export default useImport