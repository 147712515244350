import styled from 'styled-components'

export const TemplateHeader = styled.div`
    font-size: 19px;
    margin: 27px 0 22px;
`

export const FieldBox = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    &:nth-child(n+2) {
        margin: 16px 0;
    }
`

export const FieldHeader = styled.span``

export const FieldTitle = styled.span`
    position: relative;
    padding-left: 10px;
`

export const FieldDesciption = styled.span`
    padding-left: 10px;
    color: ${({ theme }): any => theme.colors.MEDIUM_GRAY};
`

export const FieldExample = styled(FieldDesciption)`
    font-size: 12px;
`