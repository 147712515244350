import { useState } from 'react'
import useRest from 'hooks/useRest'



const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()
  const url = process.env.REACT_APP_KEYCLOAK as string

  const requestToken = async (data: URLSearchParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const header = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }
    const response = await rest.postHeader(url, data, header)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  return {
    isLoading,
    requestToken,
  }
}

export default useLogin