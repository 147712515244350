import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { ExpandableConfig } from 'antd/lib/table/interface'
import { DataTable, Edit, ExpandIcon } from 'components'
import {
  Select, Tooltip,
} from 'antd'
import ModalEmployeeForm from './Modal'
import { DialogContext } from 'hooks/dialog/context'
import useEmployees, {
  TCollaborators,
  TEmployeesParams,
  TCompanies,
} from 'repositories/useEmployees'
import { ColumnsType } from 'antd/lib/table'
import RelateContent from './RelateContent'
import usePagination from 'hooks/usePagination'
import {
  Content,
  Header,
  Title,
  SubTitle,
  Input,
  Button,
  InputBox,
  FilterContainer,
} from './styles'
import {
  ClearOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'

const EmployeesRelate = (): JSX.Element => {
  const { t } = useTranslation('namespaces')
  const employees = useEmployees()
  const pagination = usePagination()
  const { createDialog } = useContext(DialogContext)

  const [employeesList, setEmployeesList] = useState<TCollaborators[]>([])
  const [companiesOptions, setCompaniesOptions] = useState<TSelectOption[]>([])
  const [companiesList, setCompaniesList] = useState<TCompanies[]>([])
  const [employeeName, setEmployeeName] = useState<string>('')
  const [employeeNameFiltered, setEmployeeNameFiltered] = useState<string>('')
  const [emailOrderDirOptions, setEmailOrderDirOptions] = useState<
  'ASC' | 'DESC' | null
  >()

  const openModal = (key: number, clockNameModal: string, email: string): void => {
    createDialog({
      id: 'modal',
      open: false,
      Component: ModalEmployeeForm,
      props: {
        keyModal: key,
        clockName: clockNameModal,
        email,
        companiesList,
        companiesOptions,
        updateEmployees: getEmployees,
      },
    })
  }

  const getCompaniesList = async (): Promise<void> => {
    const response = await employees.getCompanies()
    if (!response) return

    const _companiesOptions = response.content.map((options) => ({
      label: options.name,
      value: options.id,
    }))
    setCompaniesOptions(_companiesOptions)
    setCompaniesList(response.content)
  }

  const getEmployees = async (isResetingPagination: boolean): Promise<void> => {
    const currentPage = isResetingPagination ? 0 : pagination?.data.current ?? 0
    const params: TEmployeesParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination?.data.pageSize ?? 1,
      name: employeeNameFiltered,
      orderDir: emailOrderDirOptions && emailOrderDirOptions,
      orderField: emailOrderDirOptions && 'EMAIL',
    }
    const response = await employees.getEmployees(params)
    if (!response) {
      setEmployeeNameFiltered('')
      return
    }
    const { collaborators, pageable } = response.content
    pagination.changeTotalPages(pageable.totalPages)
    const _collaborators = collaborators.map((collaborator) => ({
      ...collaborator,
      key: String(collaborator.id),
    }))
    setEmployeesList(_collaborators)
  }

  useEffect(() => {
    if (employeeName === '') {
      setEmployeeNameFiltered('')
    }
  }, [employeeName])

  useEffect(() => {
    pagination.setDefaultPagination()
    getEmployees(true)
  }, [employeeNameFiltered])

  useEffect(() => {
    getCompaniesList()
    getEmployees(false)
  }, [])

  useEffect(() => {
    if (pagination.data.current) getEmployees(false)
  }, [pagination.data.current, pagination.data.pageSize])

  useEffect(() => {
    pagination.setDefaultPagination()
    emailOrderDirOptions && getEmployees(true)
    !emailOrderDirOptions && getEmployees(true)
  }, [emailOrderDirOptions])

  const handleCleanFilter = (): void => {
    pagination.setDefaultPagination()
    setEmailOrderDirOptions(null)
  }

  const getColumnEmployeeEmailFilterProps = () => ({
    filterDropdown: (): JSX.Element => (
      <>
        <FilterContainer>
          <Select
            id='orderDir'
            placeholder={t('sorting direction', {
              ns: namespaces.pages.employeesRelate,
            })}
            defaultValue={emailOrderDirOptions}
            onChange={(value: any): void => {
              setEmailOrderDirOptions(value)
            }}
            options={[
              {
                label: t('ascending', { ns: namespaces.common }),
                value: 'ASC',
              },
              {
                label: t('descending', { ns: namespaces.common }),
                value: 'DESC',
              },
            ]}
          />
        </FilterContainer>
        <Button
          label={<ClearOutlined />}
          id='clean-filter'
          shape='default'
          type='dashed'
          onClick={(): void => handleCleanFilter()}
        />
      </>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <>
        <Tooltip
          placement='bottom'
          title={t('sorting direction', {
            ns: namespaces.pages.employeesRelate,
          })}
        >
          <SortAscendingOutlined
            style={{ color: filtered ? '#1890ff' : undefined }}
          />
          <SortDescendingOutlined
            style={{ color: filtered ? '#1890ff' : undefined }}
          />
        </Tooltip>
      </>
    ),
  })

  const columns: ColumnsType<TCollaborators> = [
    {
      title: `${t('nameClock', { ns: namespaces.common })}`,
      dataIndex: 'name',
      key: 'id',
    },
    {
      title: `${t('emailEmployee', { ns: namespaces.common })}`,
      dataIndex: 'email',
      key: 'email',
      ...getColumnEmployeeEmailFilterProps(),
      // sorter: (a, b) => {
      //   if (a.email === b.email) return 0
      //   if (a.email === null) return -1
      //   if (b.email === null) return 1
      //   return a.email.localeCompare(b.email)
      // },
      render: (text, record): JSX.Element => (
        <span>{record.email ? record.email : '-'}</span>
      ),
    },
    {
      title: `${t('action', { ns: namespaces.common })}`,
      dataIndex: 'Icone',
      key: 'action',
      align: 'center',
      render: (text, record): JSX.Element => (
        <a
          title={t('edit', { ns: namespaces.common })}
          onClick={(): void => openModal(record.id, record.name, record.email)}
        >
          <Edit />
        </a>
      ),
    },
  ]


  const changeInputValue = (value: string): void => {
    if (value.length < 50) setEmployeeName(value)
  }
  const expandable = {
    expandedRowRender: (record) => {
      const _record = record as TCollaborators
      return (
        <RelateContent
          key={_record.key}
          relations={_record.jiraNameList}
          companies={companiesList}
        />
      )
    },
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandIcon
        expanded={String(expanded)}
        onExpand={onExpand}
        record={record}
      />
    ),
  } as ExpandableConfig<object>

  return (
    <Content>
      <Header>
        <Title>{t('title', { ns: namespaces.pages.employeesRelate })}</Title>
        <SubTitle>
          {t('sub title', { ns: namespaces.pages.employeesRelate })}
        </SubTitle>
      </Header>
      <InputBox>
        <Input
          placeholder={t('employeeName', { ns: namespaces.common })}
          value={employeeName}
          onChange={(event): void => changeInputValue(event.target.value)}
          onPressEnter={(): void => {
            setEmployeeNameFiltered(employeeName)
          }}
          allowClear
        />
        <Button
          shape='default'
          type='primary'
          loading={employees.isLoading}
          label={t('filter', { ns: namespaces.common })}
          id='nameFilterButton'
          onClick={(): void => {
            setEmployeeNameFiltered(employeeName)
          }}
        />
      </InputBox>
      <DataTable
        expandRowByClick
        expandable={expandable}
        data={employeesList}
        columns={columns}
        pagination={pagination.data}
        onChange={pagination.onChangeTable}
        loading={employees.isLoading}
        emptyMessage={t('info description', {
          ns: namespaces.pages.employeesRelate,
        })}
      />
    </Content>
  )
}

export default EmployeesRelate
