import styled from 'styled-components'
import { Avatar } from 'antd'
import { BellOutlined } from '@ant-design/icons'

import LogoImage from 'assets/logo.png'

export const Container = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: ${({ theme }): any => theme.colors.THEME};
    padding: 0 20px;
`

export const Logo = styled.h1`
    background: url(${LogoImage}) no-repeat;
    background-size: auto;
    width: 165px;
    height: 60px;
    text-indent: -9999px;
    overflow: hidden;
    margin: 0;
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

export const Notification = styled(Avatar)`
    background-color: ${({ theme }): any => theme.colors.WHITE};
`

export const CustomIconBell = styled(BellOutlined)`
    color: ${({ theme }): any => theme.colors.MEDIUM_GRAY};
`