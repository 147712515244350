export const namespaces = {
  pages: {
    home: 'home',
    login: 'login',
    employees: 'employees',
    employeesUpdate: 'employeesUpdate',
    employeesRelate: 'employeesRelate',
    descriptionAnalysis: 'descriptionAnalysis',
    costCenterRelate: 'costCenterRelate',
    settings: 'settings',
    analysisHours: 'analysisHours',
    importFile: 'importFile',
    consultFiles: 'consultFiles',
    consolidated: 'consolidated',
    period: 'period',
    employeesRegister: 'employeesRegister'
  },
  common: 'common',
  layout: 'layout',
  errors: 'errors',
  table: 'table',
  template: {
    importFile: 'importFileTemplate',
    modalImportingFiles: 'modalImportingFiles',
  }
}

export const languages = {
  pt: 'pt',
  en: 'en-US',
}