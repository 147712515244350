import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Container,
  Logo,
  Content,
  // Notification,
  // CustomIconBell
} from './styles'
import Profile from './Profile'
// import InputSearch from './InputSearch'

// const IconBell = <CustomIconBell />

const Header = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Container>
      <Logo onClick={(): void => navigate('/')} />
      <Content>
        {/* <InputSearch /> */}
        {/* <Notification icon={IconBell} /> */}
        <Profile />
      </Content>
    </Container>
  )
}

export default Header