import styled from 'styled-components'
import { InfoCircleFilled, DownloadOutlined } from '@ant-design/icons'
import { Button } from 'components'

export const Content = styled.div`
  padding: 40px 40px 0px 40px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
`

export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 40px;
`

export const RowButtons = styled.div`
    display: flex;
    justify-content: space-between;

    > div, .ant-form, .ant-form > div {
        gap: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-form-item {
          margin: 0;
        }
        button, .ant-input  {
            min-width: 32px;
            height: 30px;
        }
        .export-icon {
          min-width: fit-content;
          background: none;
          outline: none;
          border: none;
          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          button:disabled{
            background: none;
            border: none;
            width: 32px;
            height: 32px;
            svg {
              color: ${({ theme }): any => theme.colors.MEDIUM_GRAY};
                width: 20px;
                height: 20px;
            }
          }
          svg {
              color: ${({ theme }): any => theme.colors.MEDIUM_GRAY};
                width: 20px;
                height: 20px;
            }
        }
    }

    .span-grid-name {
      font-weight: normal;
      color: #868686;
    }
`

export const ExportButton = styled(Button)`
  width: 30px;
`

export const Info = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28, 74px;
  padding-bottom: 40px;
`

export const IconInfo = styled(InfoCircleFilled)`
  margin-right: 5px;
  svg {
    fill: ${({ theme }): any => theme.colors.YELLOW};
  }
`

export const DownloadIcon = styled(DownloadOutlined)`
  svg {
    fill: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
  }
`

export const DownloadIconWhite = styled(DownloadOutlined)`
  svg {
    fill: #fff;
  }
`

export const BodyForm = styled.div`
  margin-top: 2rem;

  .button-export-consolidated {
    background-color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
    color: #fff;
  }
  
  .ant-form-item {
    margin-bottom: 0;
  }
`

export const SessionBodyFormInputs = styled.div`
  width: 37%;

  .prop-inputs {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }

  span.span-input { 
    font-weight: normal;
    color: #868686;
  }

  .space-grid {
    display: flex;
    align-items: center;
    font-weight: normal;
    color: #868686;
  }

  .space-button-grid {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .border-buttons-default {
    border-radius: 3px;
    border: none;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
      width: 100%;
  }
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
      width: 100%;
  }
`

export const Footer = styled.div`
 display: grid;
 justify-content: flex-end;
`
