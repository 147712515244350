import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 30px;

  .ant-upload.ant-upload-drag {
    background: ${({ theme }): string => `${theme.colors.LIGHT_GRAY} !important`};
  }
`
export const Card = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;

  > p {
    align-self: center;
    color: ${({ theme }): any => theme.colors.GRAY};

    svg {
      margin-right: 5px;
      height: 1em;
      width: 1em;
    }
  }
`
