import i18n from 'i18n'
import { Locale } from 'antd/lib/locale-provider'
import ptBR from 'antd/lib/locale/pt_BR'
import enUS from 'antd/lib/locale/en_US'
import moment from 'moment'
import 'moment/locale/pt-br'

export type TTrackerLocaleInfo = {
  detectedLanguage: string
  momentDateFormat: string
}

export const getDetectedLocaleToAntdFormat = (): Locale => {
  let detectedLanguage = ptBR

  switch (i18n.language) {
    case 'en-US':
    case 'en':
      detectedLanguage = enUS
      moment.locale('en')
      break

    case 'pt-BR':
    case 'pt':
      detectedLanguage = ptBR
      moment.locale('pt-br')
      break

    default:
      detectedLanguage = ptBR
      moment.locale('pt-br')
      break
  }

  return detectedLanguage
}

export const getLocaleInfo = (): TTrackerLocaleInfo => {
  const defaultInfo = {
    detectedLanguage: 'pt-br',
    momentDateFormat: 'DD/MM/YYYY',
  }

  switch (i18n.language) {
    case 'en-US':
    case 'en':
      defaultInfo.detectedLanguage = 'en'
      break

    case 'pt-BR':
    case 'pt':
      defaultInfo.detectedLanguage = 'pt-br'
      break

    default:
      defaultInfo.detectedLanguage = 'pt-br'
      break
  }

  return defaultInfo
}