import styled from 'styled-components'
import { Button } from 'antd'

export const Container = styled.div`
  width: 100%;
  padding: 10px;
`

export const PrimaryButton = styled(Button)`
  color: ${({ theme, type }): any => type === 'ghost' ? theme.colors.MEDIUM_GRAY : theme.colors.WHITE};
  width: 100%;
  height: 44px;
  border-style: hidden;
  border-radius: ${({ shape }): any => shape === 'default' && '3px'};
  align-self: right;
  background: ${({ theme, type }): any => {
    switch (type) {
      case 'primary':
        return theme.colors.PRIMARY_BLUE
      case 'dashed':
        return theme.colors.RED
      case 'ghost':
        return 'rgba(255, 255, 255, 0.0)'
      default:
        return `linear-gradient(to right,${theme.colors.BLUE} , ${theme.colors.GREEN} )`
    }
  }};

  &:hover,:focus {
    color: ${({ theme, type }): any => type === 'ghost' ? theme.colors.MEDIUM_GRAY : theme.colors.WHITE};
    background: ${({ theme, type }): any => {
    switch (type) {
      case 'primary':
        return theme.colors.PRIMARY_BLUE
      case 'dashed':
        return theme.colors.RED
      case 'ghost':
        return 'rgba(255, 255, 255, 0.0)'
      default:
        return `linear-gradient(to right,${theme.colors.BLUE} , ${theme.colors.GREEN} )`
    }
  }};
  }
`
