import styled from 'styled-components'
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const LogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
`

export const Header = styled.div`
  margin-bottom: 50px;
  text-align: center;
`

export const HeaderFile = styled.div`
  margin-bottom: 10px;
  text-align: center;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
`

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    width: 330px;
  }
`
export const BoxFile = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    width: 150px;
  }
`
export const LogBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  li, p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    width: 100%;
    padding-bottom: 5px;
  }

  p {
    text-align: center;
  }
`

export const GreenCheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }): any => theme.colors.GREEN};
  margin: 0 35px 50px 35px;

  svg {
    height: 11em;
    width: 11em;
  }
`

export const YellowCheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }): any => theme.colors.YELLOW};
  margin: 0 35px 50px 35px;

  svg {
    height: 11em;
    width: 11em;
}
`

export const RedCheckIcon = styled(CloseCircleFilled)`
  color: ${({ theme }): any => theme.colors.RED};
  margin: 0 35px 50px 35px;

  svg {
    height: 11em;
    width: 11em;
}
`
export const YelloExclamationIcon = styled(ExclamationCircleOutlined)`
  color: ${({ theme }): any => theme.colors.YELLOW};
  margin: 0 35px 50px 35px;

  svg {
    height: 11em;
    width: 11em;
  }
`


export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`
export const ContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`
export const ContainerModalBtns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
`

export const ClockIcon = styled(ClockCircleOutlined)`
  svg {
    color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
    height: 200px;
    width: 200px;
    align-self: center;
  }
`
export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
`


export const BoldText = styled.p`
  color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
  //font-weight: bolder;
`