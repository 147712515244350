import styled from 'styled-components'

export const Content = styled.div`
  padding: 40px 40px 0px 40px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
`
export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 40px;
`
export const FormBody = styled.div`
  width: 400px;
`

