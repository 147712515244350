import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Calendar = (props: TProps): JSX.Element => {
  const {
    size = '16',
    color = '#868686'
  } = props

  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.48053 14.2631H2.47368C2.08284 14.2631 1.708 14.1078 1.43163 13.8315C1.15526 13.5551 1 13.1803 1 12.7894V3.94732C1 3.55647 1.15526 3.18163 1.43163 2.90526C1.708 2.6289 2.08284 2.47363 2.47368 2.47363H11.3158C11.7066 2.47363 12.0815 2.6289 12.3578 2.90526C12.6342 3.18163 12.7895 3.55647 12.7895 3.94732V6.89469' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.0526 15.0002C13.6804 15.0002 15 13.6806 15 12.0528C15 10.4251 13.6804 9.10547 12.0526 9.10547C10.4248 9.10547 9.10526 10.4251 9.10526 12.0528C9.10526 13.6806 10.4248 15.0002 12.0526 15.0002Z' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.8421 1V3.94737' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.94736 1V3.94737' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 6.89453H12.7895' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.0526 10.9443V12.0525L12.7895 12.7894' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default Calendar