import React, {
  useContext,
  useCallback,
  useMemo
} from 'react'

import { DialogContext } from 'hooks/dialog/context'

import { Card } from './styles'

type TProps = {
  handleClose: () => void
  handleConfirmation?: () => void
  isModalVisible: boolean,
  title: string
  children: React.ReactChild
  id: string
  hasButtons?: boolean
}

const SaveModal = (props: TProps): JSX.Element => {
  const {
    handleClose,
    handleConfirmation,
    isModalVisible,
    title,
    children,
    id,
    hasButtons,
  } = props
  const { removeDialog } = useContext(DialogContext)

  const close = useCallback(() => handleClose(), [id, handleClose, removeDialog])

  const footer = useMemo(() => hasButtons ? hasButtons : null, [hasButtons])

  return (
    <Card
      transitionName=''
      maskTransitionName=''
      closable
      okText='Salvar'
      footer={footer}
      title={title}
      open={isModalVisible}
      onOk={handleConfirmation}
      onCancel={close}
    >
      {children}
    </Card>
  )
}

export default SaveModal