import { namespaces } from 'i18n/i18n.constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldBox, FieldDesciption, FieldExample, FieldTitle } from './TemplateJiraOrAttendanceStyle'

const TemplateJira = (): JSX.Element => {
  const { t } = useTranslation('namespaces')
  const dateExample = new Date().toLocaleDateString('pt-br')
  return (
    <>
      <FieldBox>
        <FieldTitle>Empresa</FieldTitle>
        <FieldDesciption>{t('company description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: Monitora; Senior; Argo</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Cliente</FieldTitle>
        <FieldDesciption>{t('customer description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: Copérnico Portal; Informa Markets; Senior; Argo Solutions</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Projeto</FieldTitle>
        <FieldDesciption>{t('project description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: Copérnico Portal; Informa Markets - Customer Portal; Argo Arquitetura</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Jira</FieldTitle>
        <FieldDesciption>{t('jira description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: COPPORTAL-294; INFORMACP-605; WMSCT4-5898; AA-5</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Centro de custo</FieldTitle>
        <FieldDesciption>{t('cost center description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: 000031010-Copernico:Portal:DSTM:DevQA; 000032006-InformaMarkets:CP:DSTM:Sustentacao; Senior-centro-de-custo.</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Colaborador</FieldTitle>
        <FieldDesciption>{t('employee name description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: Maria Silva; maria silva</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Data</FieldTitle>
        <FieldDesciption>{t('register activity date description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {dateExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Tempo</FieldTitle>
        <FieldDesciption>{t('register worked time description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: 1,5; 2,98; 7,69;</FieldExample>
      </FieldBox>
    </>

  )
}

export default TemplateJira