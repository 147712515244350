import axios from 'services/axios'
import axiosStatic, {
  AxiosError, AxiosRequestConfig, CancelToken, Method, AxiosResponse,
} from 'axios'

import useErrorHandler from 'hooks/useErrorHandler'

/*
  Unfortunately we can't avoid adding some sort of wrapper on httpClients one way or another.
*/
export interface RequestConfigsWrapper {
  url?: string;
  method?: Method;
  bodyData?: any;
  queryStringData?: any;
  headers?: any;
  timeout?: number;
  responseType?: | 'arraybuffer'| 'blob'| 'document'| 'json'| 'text'| 'stream';
  cancelToken?: CancelToken;
  nativeConfigs?: AxiosRequestConfig;
  trackerDevOptions?: DeveloperOptions;
}


export interface ClientRequestConfig extends AxiosRequestConfig {

}

export interface ResponseWrapper<T = any, D = any> extends AxiosResponse<T, D> {

}

export interface DeveloperOptions {
  returnResponseRef: boolean
}

export interface ErrorWrapper<T = unknown, D = any> extends AxiosError<T, D> {
  // extending to avoid coupling between our code and technical details of the currently used request library
}

export const isRequestLibraryError = (error: unknown): error is ErrorWrapper<any, any> => {
  return (error instanceof Error)
    && 'response' in error
    && 'request' in error
    && 'isAxiosError' in error
}

const useRest = () => {
  const { handleApiErrorDesambiguation } = useErrorHandler()

  const generateCancelToken = () => {
    return axiosStatic.CancelToken.source()
  }

  const get = async (
    url: string,
    params: Object = {},
    customHandleError?: (error: AxiosError) => any,
    clientConfigs?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const _params: AxiosRequestConfig = {
        params,
        ...clientConfigs
      }

      const response = await axios.get(url, _params)

      return response.data
    } catch (error) {
      const _error = error as AxiosError
      if (customHandleError) {
        customHandleError(_error)
        return
      }

      if (!_error.response?.status) {
        return
      }

      handleApiErrorDesambiguation(_error.response)
    }
  }

  const post = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any,
    clientConfigs?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const response = await axios.post(url, body, clientConfigs)

      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      if (!_error.response?.status) {
        return
      }

      handleApiErrorDesambiguation(_error.response)
    }
  }

  const generalRequest = (options: RequestConfigsWrapper): Promise<AxiosResponse<any, any>> => {
    return axios.request({
      method: options.method,
      url: options.url,
      data: options.bodyData,
      params: options.queryStringData,
      headers: options.headers,
      timeout: options.timeout,
      responseType: options.responseType,
      cancelToken: options.cancelToken,
    })
  }

  const post2 = (options: RequestConfigsWrapper): Promise<AxiosResponse<any, any>> => {
    options.method = 'post'
    return generalRequest(options)
  }

  const get2 = (options: RequestConfigsWrapper): Promise<AxiosResponse<any, any>> => {
    options.method = 'get'
    return generalRequest(options)
  }

  const put2 = (options: RequestConfigsWrapper): Promise<AxiosResponse<any, any>> => {
    options.method = 'put'
    return generalRequest(options)
  }

  const patch2 = (options: RequestConfigsWrapper): Promise<AxiosResponse<any, any>> => {
    options.method = 'patch'
    return generalRequest(options)
  }

  const delete2 = (options: RequestConfigsWrapper): Promise<AxiosResponse<any, any>> => {
    options.method = 'delete'
    return generalRequest(options)
  }

  const patch = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any,
    clientConfigs?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const response = await axios.patch(url, body, clientConfigs)

      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      if (!_error.response?.status) {
        return
      }

      handleApiErrorDesambiguation(_error.response)
    }
  }

  const postHeader = async (
    url: string,
    body: Object = {},
    header: Object = {},
    customHandleError?: (error: AxiosError) => any,
    clientConfigs?: AxiosRequestConfig,
  ): Promise<AxiosResponse<any> | undefined> => {
    try {
      const response = await axios.post(url, body, header)

      return response
    } catch (error) {
      const _error = error as AxiosError
      if (customHandleError) {
        customHandleError(_error)
        return
      }

      if (!_error.response?.status) {
        return
      }

      handleApiErrorDesambiguation(_error.response)
    }
  }

  const deleteReq = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any,
    clientConfigs?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const response = await axios.delete(url, body)

      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      if (!_error.response?.status) {
        return
      }

      handleApiErrorDesambiguation(_error.response)
    }
  }

  const put = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any,
    clientConfigs?: AxiosRequestConfig,
  ): Promise<any> => {
    try {
      const response = await axios.put(url, body, clientConfigs)

      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      if (!_error.response?.status) {
        return
      }

      handleApiErrorDesambiguation(_error.response)
    }
  }

  return {
    get, post, put, patch, postHeader, deleteReq, generateCancelToken, isRequestLibraryError,
    get2, post2, put2, patch2, delete2
  }
}

export default useRest