import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'hooks/useAuth'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles'

type TProps = {
  children: React.ReactNode
}

const Providers = ({ children }: TProps): JSX.Element => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        {children}
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>
)

export default Providers
