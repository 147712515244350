import React, { useState, useRef } from 'react'
import {
  Row, Col, Modal, Form, Button, Typography, Dropdown,
  Table, Input, Space, Empty, notification, Select,
  Card, TablePaginationConfig
} from 'antd'
import moment, { Moment } from 'moment'
import {
  MailOutlined,
  DownOutlined,
} from '@ant-design/icons'
import useTimeAnalysis, {
  TDescriptionAnalysisParams,
  TDescription,
  TJobDescriptionAnalysisRecord,
  ErrorWrapper,
  ResponseWrapper,
  isRequestLibraryError,
  TDescriptionAnalysisDetailsParams,
  TDescriptionAnalysisDetailResponse,
  TJobDescriptionAnalysisDetailRecord,
  TNotificationDescriptionAnalysisParams,
  TDescriptionAnalysisResponse
} from 'repositories/useTimeAnalysis'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import Datepicker from 'components/DatePicker'
import { FileView } from 'components'
import { getLocaleInfo } from 'utils/languageHelper'
import { isDateLessThanCurrent } from 'utils/helpers'
import useSocket from 'hooks/useSocket'
import useErrorHandler from 'hooks/useErrorHandler'
import { useAuth } from 'hooks/useAuth'
import './DescriptionAnalysis.scss'


const AnalysisDescription = (): JSX.Element => {
  interface TablePagination {
    current: number | undefined,
    defaultCurrent: number | undefined,
    pageSize: number | undefined,
    showQuickJumper: boolean | {
      goButton?: React.ReactNode;
    },
    total: number | undefined,
    itemsInPage: number | undefined,
  }

  interface IDescriptionAnalysisForm {
    intervalDate: Array<Moment>
    employeeName: string
  }

  interface ISendEmailForm {
    datePicker: Moment
    responsibleListField: Array<string>
  }

  interface IExecuteFilterArgs {
    page?: number
    pageSize?: number
    orderField?: string
    orderDir?: string
    formValues?: IDescriptionAnalysisForm
  }

  const { t } = useTranslation('namespaces')
  const [filterFormInstance] = Form.useForm()
  const [formModalSendInstance] = Form.useForm()
  const timeAnalysis = useTimeAnalysis()
  const socket = useSocket()
  const localeInfo = getLocaleInfo()
  const { removeUserData, isSignedIn } = useAuth()
  const { handleInvalidTokenError } = useErrorHandler()
  moment.locale(localeInfo.detectedLanguage)

  const [selectedRows, setSelectedRows] = useState<TJobDescriptionAnalysisRecord[]>([])
  const [lastSubmittedFormData, setLastSubmittedFormData] = useState<IDescriptionAnalysisForm>({} as IDescriptionAnalysisForm)
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(false)
  const [isLoadingDetailsModal, setIsLoadingDetailsModal] = useState<boolean>(false)
  const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false)
  const [tableDataSource, setTableDataSource] = useState<TJobDescriptionAnalysisRecord[]>([])
  const [tablePagination, setTablePagination] = useState<TablePagination>({
    pageSize: 10,
    current: 1,
    defaultCurrent: 1,
    showQuickJumper: true,
    total: 0,
    itemsInPage: undefined,
  })
  const [detailsData, setDetailsData] = useState<TJobDescriptionAnalysisDetailRecord[] | undefined>(undefined)
  const [emptyMessage, setEmptyMessage] = useState<string>(t('select', { ns: namespaces.pages.descriptionAnalysis }))
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState<boolean>(false)
  const [sendType, setSendType] = useState<'ALL' | 'SELECTED' | undefined>(undefined)
  const isSummaryOpen = useRef<boolean>(false)

  const buttonListItems = [
    {
      key: 1,
      title: t('notify all', { ns: namespaces.pages.descriptionAnalysis }),
      label: t('notify all', { ns: namespaces.pages.descriptionAnalysis }),
      icon: <MailOutlined />,
      disabled: !tableDataSource.length,

      onClick: (): void => {
        openModalSendToAll()
      }

    },
    {
      key: 2,
      title: t('notificate selected', { ns: namespaces.pages.descriptionAnalysis }),
      label: t('notificate selected', { ns: namespaces.pages.descriptionAnalysis }),
      icon: <MailOutlined />,
      disabled: !selectedRows.length || !tableDataSource.length,

      onClick: (): void => {
        openModalSendToSelected()
      }
    },
  ]

  const openModalSendToSelected = (): void => {
    setSendType('SELECTED')
    setIsSendModalOpen(true)
  }

  const openModalSendToAll = (): void => {
    setSendType('ALL')
    setIsSendModalOpen(true)
  }

  const executeFilter = async (
    args: IExecuteFilterArgs = {
      page: 0,
      pageSize: 10,
    }
  ): Promise<void> => {
    if (!args.formValues?.intervalDate[0]) {
      return
    }

    let response
    let responseError: ErrorWrapper<TDescriptionAnalysisResponse> | undefined
    let genericError: Error | undefined

    setIsLoadingFilter(true)

    const [from, to] = args.formValues.intervalDate

    const params: TDescriptionAnalysisParams = {
      startDate: moment(from).format('YYYY-MM-DD') ?? '',
      endDate: moment(to).format('YYYY-MM-DD') ?? '',
      pageSize: 10,
      page: (args?.page && args?.page >= 1) ? args.page : 0,
      collaboratorName: args.formValues.employeeName,
    }

    try {
      response = await timeAnalysis.requestDescriptionAnalysis({
        params,
        developerOptions: {
          returnResponseRef: true
        }
      })
    } catch (error) {
      console.log('error', error)

      if (isRequestLibraryError(error)) {
        responseError = error
      } else {
        genericError = error as Error
      }
    }

    switch (true) {
      case (responseError?.response?.status === 401 && isSignedIn):
        removeUserData()
        handleInvalidTokenError()
        break

      case (response?.data.detailCode === 17001): {
        // "Inadequate work logs listed but the result set is empty", "No Error", 17001L
        setEmptyMessage(t('queryOkEmptyResult', { ns: namespaces.pages.descriptionAnalysis }))
        setTableDataSource(response?.data.content.jobDescriptionAnalysis || [])
        const pagination = tablePagination
        pagination.total = response?.data.content.pageable.totalElements
        pagination.current = (response?.data.content.pageable.pageNumber || 0) + 1
        pagination.itemsInPage = response?.data.content.pageable.elementsInThisPage

        setTablePagination((previous) => {
          return ({
            ...previous,
            ...pagination,
          })
        })
      } break

      case (responseError?.response?.data?.detailCode === -17002):
        // "There was an error fetching the collaborators", "Has Error", -17002L
        notification.error({
          message: t('error', { ns: namespaces.common }),
          description: t('collaboratorError', { ns: namespaces.pages.descriptionAnalysis })
        })
        break

      case (responseError?.response?.data.detailCode === -17001):
        // "There was an error fetching the worklog analysis", "Has Error", -17001L
        notification.error({
          message: t('error', { ns: namespaces.common }),
          description: t('analysisError', { ns: namespaces.pages.descriptionAnalysis })
        })
        break

      case (response?.data.detailCode === 14000): {
        // "List all by filter successfully", "No Error", 14000L
        setTableDataSource(response?.data.content.jobDescriptionAnalysis || [])

        const pagination = tablePagination
        pagination.total = response?.data.content.pageable.totalElements
        pagination.current = (response?.data.content.pageable.pageNumber || 0) + 1
        pagination.itemsInPage = response?.data.content.pageable.elementsInThisPage

        setTablePagination((previous) => {
          return ({
            ...previous,
            ...pagination,
          })
        })
      } break

      default:
        notification.error({
          message: t('error', { ns: namespaces.common }),
          description: t('genericError', { ns: namespaces.common })
        })
        break
    }

    setIsLoadingFilter(false)
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows_: TJobDescriptionAnalysisRecord | TJobDescriptionAnalysisRecord[]): void => {
      const _rows = Array.isArray(selectedRows_) ? selectedRows_ : [selectedRows_]
      setSelectedRows(_rows)
    },
  }

  const openDetailsModal = async (record: TJobDescriptionAnalysisRecord): Promise<void> => {
    setIsLoadingDetailsModal(true)

    if (!lastSubmittedFormData.intervalDate[0]) {
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('genericError', { ns: namespaces.common })
      })

      setIsLoadingDetailsModal(false)
      return
    }

    let response: ResponseWrapper<TDescriptionAnalysisDetailResponse> | undefined
    let responseError: ErrorWrapper<TDescriptionAnalysisDetailResponse> | undefined
    let genericError: Error | undefined

    const params: TDescriptionAnalysisDetailsParams = {
      startDate: lastSubmittedFormData.intervalDate[0].format('YYYY-MM-DD') ?? '',
      endDate: lastSubmittedFormData.intervalDate[1].format('YYYY-MM-DD') ?? '',
      employeeID: record.employeeId.toString()
    }

    try {
      response = await timeAnalysis.requestDescriptionDetails({
        params,
        developerOptions: {
          returnResponseRef: true
        }
      })
    } catch (error: unknown) {
      console.log('error', error)

      if (isRequestLibraryError(error)) {
        responseError = error
      } else {
        genericError = error as Error
      }
    }

    switch (true) {
      case (responseError?.response?.status === 401 && isSignedIn):
        removeUserData()
        handleInvalidTokenError()
        break

      case (response?.data.detailCode === 17007):
        // "Inadequate work logs details listed but empty response", NO_ERROR, 17007L
        setDetailsData([])
        break

      case (response?.data.detailCode === 17002):
        // "Inadequate work logs details listed ", NO_ERROR, 17002L
        setDetailsData(response?.data.content.worklogAnalysis)
        setIsDetailsModalOpen(true)
        break

      case (responseError?.response?.data.detailCode === -17001):
        // "There was an error fetching the worklog analysis", HAS_ERROR,-17001L
        notification.error({
          message: t('error', { ns: namespaces.common }),
          description: t('analysisError', { ns: namespaces.pages.descriptionAnalysis })
        })
        break

      case (responseError?.response?.data.detailCode === -1000):
      default:
        // "Something unexpected happened", HAS_ERROR, -1000L
        notification.error({
          message: t('error', { ns: namespaces.common }),
          description: t('genericError', { ns: namespaces.common })
        })
        break
    }

    setIsLoadingDetailsModal(false)
  }

  const translateFailedSend = (failed: string): string => {
    const [error, identificator, port] = failed.split(',')

    if (failed.match('Authentication unsuccessful'))
      return t('Authentication unsuccessful', { ns: namespaces.errors })

    if (failed.match('STARTTLS is required to send mail'))
      return t('STARTTLS is required to send mail', { ns: namespaces.errors })

    switch (error) {
      case 'Illegal address':
        return t('Illegal Address', { ns: namespaces.errors }).replace('{user}', identificator)
      case 'Invalid Addresses':
        return t('Invalid Addresses', { ns: namespaces.errors }).replace('{user}', identificator)
      case 'Couldn\'t connect to host':
        return `${t('Couldn\'t connect to host', { ns: namespaces.errors })}: ${identificator}, ${port}`
      default: return t('default', { ns: namespaces.errors })
    }
  }

  const closeSendModal = (): void => {
    formModalSendInstance.resetFields()
    setIsSendModalOpen(false)
  }

  const handleSend = (formValues: ISendEmailForm): void => {
    const colaboratorIndexes = []

    let collaboratorSelectList: TJobDescriptionAnalysisRecord[] = []

    if (sendType === 'ALL') {
      collaboratorSelectList = tableDataSource
    } else if (sendType === 'SELECTED') {
      collaboratorSelectList = selectedRows
    }

    if (collaboratorSelectList !== null) {
      for (let index = 0; index < collaboratorSelectList.length; index++) {
        colaboratorIndexes.push(collaboratorSelectList[index].employeeId)
      }
    }

    const propsToSend: TNotificationDescriptionAnalysisParams = {
      collaboratorList: colaboratorIndexes,
      correctionLimit: moment(formValues.datePicker).format('YYYY-MM-DD'),
      endDate: lastSubmittedFormData.intervalDate[1].format('YYYY-MM-DD'),
      responsibleList: formValues.responsibleListField,
      startDate: lastSubmittedFormData.intervalDate[0].format('YYYY-MM-DD'),
      collaboratorSelectList: null
    }

    const stompClient = socket.socketConnect()
    stompClient.activate()

    stompClient.onConnect = async (): Promise<void> => {
      const response = await timeAnalysis.postNotificationDescriptionAnalysisEmail(propsToSend)

      setIsLoadingSend(false)

      if (!response?.content?.requestId) {
        notification.error({
          message: t('send error', { ns: namespaces.pages.descriptionAnalysis }),
          placement: 'topRight',
        })

        stompClient.deactivate()
        return
      }

      Modal.info({
        content: t('bulk request sent', { ns: namespaces.pages.descriptionAnalysis }),
        onOk: () => {
          // just close
        }
      })

      closeSendModal()

      const subscription = stompClient.subscribe(`/topic/messages/${response.content.requestId}`, (messageOutput) => {
        console.log('messageOutput', messageOutput)
        const jsonParse = JSON.parse(messageOutput.body)

        let _failedSendList: any[] = []

        switch (true) {
          case (jsonParse.body.detailCode === -18000):
            _failedSendList.push({
              key: 0,
              description: t('the email settings were not found', { ns: namespaces.errors }),
            })
            break

          case (jsonParse.body.detailCode === 17005):
          case (jsonParse.body.detailCode === 17006):
            // "The email(s) was(were) sent successfully.", NO_ERROR, 17005
            // "There were failures to send.",NO_ERROR, 17006

            if (jsonParse.body.content.success.failedMailingList.length) {
              const { failedMailingList } = jsonParse.body.content.success

              _failedSendList = failedMailingList.map((failed: string, index: any) => ({
                key: index,
                description: translateFailedSend(failed)
              }))
            }
            break

          case (jsonParse.body.detailCode === -17000):
          case (jsonParse.body.detailCode === -1000):
          case (!jsonParse?.body?.content?.success):
          default:
            // "Something unexpected happened",NO_ERROR, -17000L
            // UNEXPECTED_REASON, HAS_ERROR, -1000L
            _failedSendList.push({
              key: 0,
              description: t('default', { ns: namespaces.errors }),
            })
            break
        }

        if (_failedSendList.length) {
          const errorTitle = t('there was an error sending the emails', { ns: namespaces.pages.descriptionAnalysis })
          notification.warning({
            message: errorTitle,
            duration: 0,
            btn: (
              <Button
                disabled={isSummaryOpen.current}
                onClick={(): void => {
                  if (!isSummaryOpen.current) {
                    isSummaryOpen.current = true

                    Modal.info({
                      centered: true,
                      maskClosable: false,
                      title: t('details', { ns: namespaces.common }),
                      onOk: () => {
                        // close
                      },
                      afterClose: () => {
                        isSummaryOpen.current = false
                      },
                      okText: 'Ok',
                      style: { width: '1024px' },
                      content: (
                        <Card>
                          <Table
                            size='small'
                            pagination={false}
                            columns={[
                              {
                                title: t('shipping history', { ns: namespaces.pages.descriptionAnalysis }),
                                dataIndex: 'description',
                              }
                            ]}
                            dataSource={_failedSendList}
                            scroll={{ y: 350 }}
                          />
                        </Card>
                      )
                    })
                  }
                }}
              >
                {t('details', { ns: namespaces.common })}
              </Button>
            ),
            placement: 'topRight',
          })

          subscription.unsubscribe()
          stompClient.deactivate()
          return
        }

        notification.success({
          message: t('the emails were sent succesfully', { ns: namespaces.pages.descriptionAnalysis }),
          placement: 'topRight',
          duration: 0
        })

        subscription.unsubscribe()
        stompClient.deactivate()
      })
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
  ): void => {
    setIsLoadingFilter(true)

    setTablePagination((previous) => {
      return ({
        ...previous,
        current: pagination.current
      })
    })

    setIsLoadingFilter(false)

    executeFilter({
      page: (pagination?.current)
        ? pagination?.current - 1
        : 0,
      formValues: lastSubmittedFormData
    })
  }

  return (
    <div
      className='AnalysisDescription'
    >
      <Row
        gutter={[24, 24]}
      >
        <Col
          xs={{ span: 24 }}
        >
          <Row
            className='titlerow'
            gutter={[24, 24]}
          >
            <Col
              xs={{ span: 24 }}
            >
              <Typography.Title className='AnalysisDescription__PageTitle'>
                {t('description analysis', { ns: namespaces.pages.descriptionAnalysis })}
              </Typography.Title>
            </Col>
          </Row>

          <Row
            className='AnalysisDescription__SubtitleRow'
            gutter={[24, 24]}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 21 }}
            >
              <Typography.Text className='AnalysisDescription__PageSubtitle'>
                {t('description info', { ns: namespaces.pages.descriptionAnalysis })}
              </Typography.Text>
            </Col>

            <Col
              xs={{ span: 24 }}
              md={{ span: 3 }}
            >
              <Dropdown menu={{ items: buttonListItems }}>
                <Button>
                  <Space>
                    {t('options', { ns: namespaces.common })}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>

          <Row
            gutter={[24, 24]}
          >
            <Col
              xs={{ span: 24 }}
            >
              <Form
                form={filterFormInstance}
                layout='vertical'
                onFinish={
                  (values): void => {
                    setLastSubmittedFormData(values)
                    executeFilter({
                      page: 0,
                      formValues: values
                    })
                  }
                }
                onFinishFailed={
                  (errorInfo): void => {
                    console.log('form error', errorInfo)
                  }
                }
              >
                <Row
                  gutter={[24, 24]}
                >
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <Form.Item
                      name='intervalDate'
                      label={(
                        <Typography.Text
                          className='AnalysisDescription__FormLabel'
                        >
                          {t('period', { ns: namespaces.common })}
                        </Typography.Text>
                      )}
                    >
                      <Datepicker.RangePicker
                        style={{
                          width: '100%',
                        }}
                        format={localeInfo.momentDateFormat}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                  >
                    <Form.Item
                      noStyle
                      shouldUpdate
                    >
                      {
                        (formInstanceArgument): JSX.Element => {
                          return (
                            <Form.Item
                              name='employeeName'
                              label={(
                                <Typography.Text
                                  className='AnalysisDescription__FormLabel'
                                >
                                  {t('employeeName', { ns: namespaces.common })}
                                </Typography.Text>
                              )}
                            >
                              <Input
                                allowClear
                                disabled={!formInstanceArgument.getFieldValue('intervalDate')}
                              />
                            </Form.Item>
                          )
                        }
                      }
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ offset: 7, span: 3 }}
                  >
                    <Form.Item
                      noStyle
                      shouldUpdate
                    >
                      {
                        (formInstanceArg): JSX.Element => {
                          return (
                            <Form.Item
                              label=' '
                            >
                              <Button
                                block
                                type='primary'
                                htmlType='submit'
                                loading={isLoadingFilter || isLoadingDetailsModal}
                                disabled={!formInstanceArg.getFieldValue('intervalDate')}
                              >
                                {t('filter', { ns: namespaces.common })}
                              </Button>
                            </Form.Item>
                          )
                        }
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row
            gutter={[24, 24]}
          >
            <Col xs={{ span: 24 }}>

              {
                !isLoadingFilter && tableDataSource !== null && !tableDataSource?.length
                  ? (
                    <Empty
                      description={emptyMessage}
                    />
                  )
                  : (
                    <Table
                      onChange={handleTableChange}
                      pagination={{
                        pageSize: tablePagination.pageSize || 10,
                        current: tablePagination.current || 1,
                        defaultCurrent: tablePagination.defaultCurrent || 1,
                        showQuickJumper: tablePagination.showQuickJumper || true,
                        total: tablePagination.total || 0,
                      }}
                      rowSelection={rowSelection}
                      loading={isLoadingFilter || isLoadingDetailsModal}
                      dataSource={tableDataSource}
                      rowKey={(record: TDescription): string => record.employeeName}
                      columns={[
                        {
                          title: t('name', { ns: namespaces.pages.descriptionAnalysis }),
                          dataIndex: 'employeeName',
                          key: 'employeeName',
                        },

                        {
                          title: `${t('action', { ns: namespaces.common })}:`,
                          dataIndex: 'action',
                          key: 'action',
                          align: 'center',

                          render: (text, record): JSX.Element => (
                            <Button
                              type='link'
                              htmlType='button'
                              title={t('view', { ns: namespaces.common })}
                              icon={<FileView />}
                              onClick={
                                (): void => {
                                  openDetailsModal(record)
                                }
                              }
                            />
                          ),
                        }

                      ]}
                    />
                  )
              }
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        open={isDetailsModalOpen}
        destroyOnClose={true}
        footer={null}
        title={t('worklog detail', { ns: namespaces.pages.descriptionAnalysis })}
        width='70%'
        bodyStyle={{
          width: '100%',
        }}
        onCancel={
          (): void => {
            setIsDetailsModalOpen(false)
          }
        }
      >
        <Row
          gutter={[24, 24]}
        >
          <Col
            xs={24}
          >
            <Table
              style={{
                marginBottom: '50px'
              }}
              pagination={false}
              columns={[
                {
                  title: t('date', { ns: namespaces.pages.descriptionAnalysis }),
                  dataIndex: 'date',
                  key: 'date',
                  align: 'center',
                },

                {
                  title: t('project', { ns: namespaces.pages.descriptionAnalysis }),
                  dataIndex: 'project',
                  key: 'project',
                  align: 'center',
                },

                {
                  title: t('jira', { ns: namespaces.pages.descriptionAnalysis }),
                  dataIndex: 'task',
                  key: 'task',
                  align: 'center',
                },
                {
                  title: t('description', { ns: namespaces.pages.descriptionAnalysis }),
                  dataIndex: 'worklogDescription',
                  key: 'worklogDescription',
                  align: 'left',
                },
              ]}
              loading={false}
              dataSource={detailsData}
              scroll={{
                y: '150px'
              }}
            />
          </Col>
        </Row>
        <Row
          justify='center'
          gutter={[24, 24]}
        >
          <Col
            xs={24}
          >
            <Button
              type='primary'
              htmlType='reset'
              onClick={
                (): void => {
                  setIsDetailsModalOpen(false)
                }
              }
              block
            >
              {t('close', { ns: namespaces.pages.descriptionAnalysis })}
            </Button>
          </Col>
        </Row>
      </Modal>


      <Modal
        open={isSendModalOpen}
        destroyOnClose={true}
        footer={null}
        title={t('send notification', { ns: namespaces.pages.descriptionAnalysis })}
        onCancel={
          (): void => {
            closeSendModal()
          }
        }
      >
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }}>
            <Form
              form={formModalSendInstance}
              layout='vertical'
              onFinish={
                (formValues): void => {
                  handleSend(formValues)
                }
              }
              onFinishFailed={
                (errorInfo): void => {
                  console.log('form error', errorInfo)
                }
              }
              initialValues={{
                responsibleListField: [],
              }}
            >
              <Row
                gutter={[24, 24]}
              >
                <Col
                  xs={{ span: 24 }}
                >
                  <Form.Item
                    name='datePicker'
                    label={t('time limit', { ns: namespaces.pages.descriptionAnalysis })}
                    id='datePicker'
                    rules={[
                      {
                        required: true,
                        message: t('correction limit is required', { ns: namespaces.pages.descriptionAnalysis })
                      },
                      ({
                        validator: (_, value): Promise<string> => {
                          if (isDateLessThanCurrent(value) && value) {
                            return Promise.reject(t('the correction date cannot be less than the current one', { ns: namespaces.pages.descriptionAnalysis }))
                          }

                          return Promise.resolve('')
                        }
                      })
                    ]}
                    required
                  >
                    <Datepicker
                      style={{ width: '100%' }}
                      placeholder={t('date', { ns: namespaces.common })}
                      format={localeInfo.momentDateFormat}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24 }}
                >
                  <Form.Item
                    id='responsibleListField'
                    name='responsibleListField'
                    className='responsibleListField'
                    label={t('responsible', { ns: namespaces.pages.descriptionAnalysis })}
                  >
                    <Select
                      id='responsibleList'
                      mode='tags'
                      placeholder={t('responsible', { ns: namespaces.pages.descriptionAnalysis })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                justify='end'
                gutter={[24, 24]}
              >
                <Col
                  xs={{ span: 5 }}
                >
                  <Form.Item>
                    <Button
                      type='default'
                      htmlType='reset'
                      onClick={
                        (): void => {
                          closeSendModal()
                        }
                      }
                      loading={isLoadingSend}
                    >
                      {t('cancel', { ns: namespaces.pages.descriptionAnalysis })}
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 5 }}
                >
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={isLoadingSend}
                    >
                      {t('send', { ns: namespaces.pages.descriptionAnalysis })}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default AnalysisDescription
