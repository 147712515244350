import styled from 'styled-components'
import { Card, Modal } from 'antd'

export const CustomModal = styled(Modal)`
    .ant-modal-body {
        min-height: 200px;
    }
`

export const CardHistory = styled(Card)`
    .ant-card-body {
        padding: 0;
    }
`