import styled from 'styled-components'
import { InfoCircleFilled } from '@ant-design/icons'
import { List, Input as Inp } from 'antd'
import { Button as Bt } from 'components'

export const Content = styled.div`
  padding: 30px 25px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
  height: auto;
`
export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 20px;
`
export const Info = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28, 74px;
  padding-bottom: 40px;
`
export const IconInfo = styled(InfoCircleFilled)`
  margin-right: 5px;
  svg {
    fill: ${({ theme }): any => theme.colors.YELLOW};
  }
`
export const Footer = styled.div`
 display: grid;
 justify-content: flex-end;
`

export const GridList = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  font-weight: 700;

  span:last-child {
    text-align: center;
  }
`

export const ListItem = styled(List.Item)`
  display: grid;
  grid-template-columns: 60% 40%;

  span:last-child {
    text-align: center;
  }
`

export const CustomList = styled(List)`
  margin: 0 75px 0 50px;
`

export const Input = styled(Inp)`
  width: 22.5%;
  height: 30px;
`

export const Button = styled(Bt)`
  width: 15% !important;
  border-radius: 3px;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  button{
    width: 100%;
    height: 30px;
  }
  div {
    width: 120px;
  }
`
export const FilterContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
padding: 10px;
`