import styled from 'styled-components'
import Background from '../../assets/loginBackground.png'
import TextField from 'components/Form/TextField'
import { Button } from 'antd'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
`

export const Cover = styled.div`
    background-image: ${({ theme }): any => theme.colors.THEME};
    width: 65%;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
        display: none;
    }
`

export const BackgroundIMG = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${Background});
    display: flex;
    justify-content: center;
`


export const LoginBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    padding: 6.5vw;
    position: relative;

    .ant-form{
        display: flex;
        flex-direction: column;
    }

    div.ant-form-item-label {
        padding-bottom: 0;
    }

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
        width: 100%;
        background-image: ${({ theme }): any => theme.colors.THEME};
    }
`

export const Logo = styled.img`
    width: 550px; 
    height: 208px;
    align-self: center;
`

export const TextInput = styled(TextField)`
    justify-content: flex-start;
`

export const Title = styled.h1`
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
        color: ${({ theme }): any => theme.colors.WHITE};
    }
`

export const Label = styled.h2`
    font-size: 18px;
    font-weight: 400;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
        color: ${({ theme }): any => theme.colors.WHITE};
    }
`

export const PrimaryButton = styled(Button)`
    background-color: ${({ theme }): any => theme.colors.MEDIUM_GRAY};
    color: ${({ theme }): any => theme.colors.WHITE};
    width: 192px;
    border-style: hidden;
    border-radius: 3px;
    align-self: center;
`

export const LoginButton = styled(Button)`
    background-color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
    color: ${({ theme }): any => theme.colors.WHITE};
    border-style: hidden;
    border-radius: 3px;
    margin-top: 20px;
`

export const CompanySignature = styled.div`
    text-align: center;
    margin-top: 15px;
    margin-right: 5px;
    font-style: italic;
    font-size: .8rem;
    color: #868686;
    position: absolute;
    bottom: 15px;
    width: 64.5%;
    display: flex;
    justify-content: center;

    a.linkGreenCompany {
        color: #a5cd39;
    }
`