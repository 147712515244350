import { Modal } from 'components'
import { namespaces } from 'i18n/i18n.constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TemplateAppoint from './TemplateAttendance'
import TemplateAttendanceSaturno from './TemplateAttendanceSaturno'
import TemplateJira from './TemplateJira'
import { Title, SubTitle, TemplateToggle, TemplatesType, TemplateContent, Divider } from './TemplateModalStyles'
interface ITemplateModalProps {
  isOpen: boolean,
  handleClose: React.Dispatch<React.SetStateAction<boolean>>
}

const TemplateModal: React.FC<ITemplateModalProps> = ({ isOpen, handleClose }): JSX.Element => {
  const [activeTemplate, setActiveTemplate] = useState<'jira' | 'attendanceRM' | 'attendanceSaturno'>('jira')
  const { t } = useTranslation('namespace')
  return (
    <Modal
      id='template-CustomModal'
      isModalVisible={isOpen}
      handleClose={(): void => handleClose(false)}
      width={800}
      transitionName=''
      maskTransitionName=''
    >
      <TemplateContent>
        <Title>{t('template import', { ns: namespaces.template.importFile })}</Title>
        <Divider />
        <SubTitle>{t('required fields description', { ns: namespaces.template.importFile })}</SubTitle>
        <TemplatesType>
          <TemplateToggle isActive={activeTemplate === 'jira'} onClick={(): void => setActiveTemplate('jira')}>{t('jira sheet import', { ns: namespaces.template.importFile })}</TemplateToggle>
          <TemplateToggle isActive={activeTemplate === 'attendanceRM'} onClick={(): void => setActiveTemplate('attendanceRM')}>{t('rm timesheet import', { ns: namespaces.template.importFile })}</TemplateToggle>
          <TemplateToggle isActive={activeTemplate === 'attendanceSaturno'} onClick={(): void => setActiveTemplate('attendanceSaturno')}>{t('saturno timesheet import', { ns: namespaces.template.importFile })}</TemplateToggle>
        </TemplatesType>
        {activeTemplate === 'jira' && (
          <TemplateJira />
        )}
        {activeTemplate === 'attendanceRM' && (
          <TemplateAppoint />
        )}
        {activeTemplate === 'attendanceSaturno' && (
          <TemplateAttendanceSaturno />
        )}
      </TemplateContent>
    </Modal>
  )
}

export { TemplateModal }