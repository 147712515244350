import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Edit = (props: TProps): JSX.Element => {
  const {
    size = '16',
    color = '#868686'
  } = props

  return (
    <svg width={size} height={size} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.1988 13.067H0.933237V2.8014H7.10193L8.03517 1.86816H0.933237C0.685727 1.86816 0.448354 1.96649 0.273339 2.1415C0.0983228 2.31652 0 2.55389 0 2.8014V13.067C0 13.3145 0.0983228 13.5519 0.273339 13.7269C0.448354 13.9019 0.685727 14.0002 0.933237 14.0002H11.1988C11.4464 14.0002 11.6837 13.9019 11.8587 13.7269C12.0338 13.5519 12.1321 13.3145 12.1321 13.067V6.06773L11.1988 7.00097V13.067Z' fill={color} />
      <path d='M13.7792 1.79327L12.2067 0.220766C12.1369 0.150787 12.054 0.0952658 11.9627 0.0573829C11.8714 0.0195 11.7736 0 11.6747 0C11.5759 0 11.4781 0.0195 11.3868 0.0573829C11.2955 0.0952658 11.2126 0.150787 11.1428 0.220766L4.74546 6.65543L4.22751 8.89987C4.20545 9.00866 4.20777 9.121 4.23431 9.22879C4.26085 9.33658 4.31094 9.43716 4.381 9.52327C4.45105 9.60939 4.53932 9.67891 4.63945 9.72684C4.73959 9.77476 4.8491 9.7999 4.96011 9.80044C5.01748 9.80676 5.07538 9.80676 5.13275 9.80044L7.39585 9.30116L13.7792 2.85716C13.8492 2.78737 13.9047 2.70446 13.9426 2.61318C13.9805 2.5219 14 2.42404 14 2.32521C14 2.22639 13.9805 2.12853 13.9426 2.03725C13.9047 1.94597 13.8492 1.86306 13.7792 1.79327ZM6.91057 8.43792L5.20275 8.81588L5.59937 7.12205L10.4149 2.27389L11.7307 3.58975L6.91057 8.43792ZM12.258 3.06247L10.9422 1.74661L11.6654 1.00935L12.9906 2.33455L12.258 3.06247Z' fill={color} />
    </svg>

  )
}

export default Edit