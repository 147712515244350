import { useState } from 'react'
import useRest from 'hooks/useRest'

export type TConsultFilesResponse = TCommonResponse & {
  content: {
    fileList: TConsultedFile[]
    pageable: TPageableResponse
  }
}

export type TConsultedFile = {
  company: number
  createdDate: string
  updatedDate: string
  fileName: string
  originalFileName: string
  fileSize: string
  fileType: number
  id: number
  isSuccessfullyProcessed: boolean
}

export type TConsultFilesParams = {
  size: number
  page: number
  successfullyProcessed?: boolean
}

const useConsultFiles = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getFiles = async (params:TConsultFilesParams, triggerLoading = true): Promise<TConsultFilesResponse> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/time-check/consult-files'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as TConsultFilesResponse
  }

  const deleteFile = async (id:number, triggerLoading = true): Promise<TConsultFilesResponse> => {
    if (triggerLoading) setIsLoading(true)

    const url = `/time-check/consult-files/${id}`
    const response = await rest.deleteReq(url)
    if (triggerLoading) setIsLoading(false)
    return response as TConsultFilesResponse
  }

  const viewFile = async (id: string, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = `/time-check/consult-files/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  const getErrorLog = async (id: number, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = `/time-check/error-log/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response
  }

  return {
    isLoading,
    getFiles,
    deleteFile,
    viewFile,
    getErrorLog
  }
}

export default useConsultFiles
