import React from 'react'
import { Select, Form } from 'antd'
import { Rule } from 'antd/lib/form'
import { FormLabelAlign } from 'antd/lib/form/interface'
import { Label } from 'components'

type TProps = {
  required?: boolean
  name: string
  label: string
  labelAlign?: FormLabelAlign | undefined
  rules?: Rule[]
  defaultValue: string[] | number[] | string | number
  placeholder: string
  disabled?: boolean
  id: string
}

const TagsField = (props: TProps): JSX.Element => {
  const {
    required = false,
    name,
    label,
    labelAlign = 'left',
    rules,
    defaultValue,
    placeholder,
    disabled,
    id,
  } = props

  const _label = <Label description={label} required={required} />

  return (
    <Form.Item
      required={false}
      labelAlign={labelAlign}
      label={_label}
      name={name}
      rules={rules}
    >
      <Select
        mode='tags'
        placeholder={placeholder}
        id={id}
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        disabled={disabled}
        allowClear
      />
    </Form.Item>
  )
}

export default TagsField