import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const FileIn = (props: TProps): JSX.Element => {
  const {
    size = '15',
    color = '#868686'
  } = props

  return (
    <svg width={size} height={size} viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 9.33353H6.31754L7.82254 7.91157L7.00004 7.13745L4.08337 9.88255L7.00004 12.6276L7.82254 11.8535L6.31754 10.4316H14V9.33353Z' fill={color} />
      <path d='M11.6667 6.58824V4.39217C11.6671 4.31991 11.6524 4.24829 11.6234 4.18139C11.5943 4.1145 11.5516 4.05366 11.4975 4.00236L7.41417 0.159225C7.35966 0.108341 7.29502 0.068084 7.22395 0.0407622C7.15287 0.0134405 7.07677 -0.000408569 7 9.17647e-06H1.16667C0.857247 9.17647e-06 0.560501 0.115695 0.341709 0.321617C0.122916 0.52754 0 0.80683 0 1.09805V14.2745C0 14.5657 0.122916 14.845 0.341709 15.051C0.560501 15.2569 0.857247 15.3726 1.16667 15.3726H10.5C10.8094 15.3726 11.1062 15.2569 11.325 15.051C11.5437 14.845 11.6667 14.5657 11.6667 14.2745V13.1765H10.5V14.2745H1.16667V1.09805H5.83333V4.39217C5.83333 4.68338 5.95625 4.96268 6.17504 5.1686C6.39383 5.37452 6.69058 5.49021 7 5.49021H10.5V6.58824H11.6667ZM7 4.39217V1.32315L10.2608 4.39217H7Z' fill={color} />
    </svg>
  )
}

export default FileIn