import { useState } from 'react'
import useRest, {
  DeveloperOptions, ErrorWrapper
} from 'hooks/useRest'

export type TEmployeesResponse = TCommonResponse & {
  content: {
    collaborators: TCollaborators[]
    pageable: TPageableResponse
  }
}

export type TEmployeesParams = {
  size: number
  page: number
  name?: string
  orderDir?: 'ASC' | 'DESC' | null
  orderField?:
  | 'id'
  | 'cpf'
  | 'name'
  | 'EMAIL'
  | 'enrollment'
  | 'admissionDate'
  | 'dismissalDate'
  | null
}

export type TCollaborators = {
  id: number
  key?: string
  cpf: string
  name: string
  email: string
  enrollment: string
  admissionDate: string
  dismissalDate: string
  companyId: number
  jiraNameList: TRelationsJiraName[]
  createdDate: string
  updatedDate: string
}

export type TRelationsResponse = TCommonResponse & {
  content: TRelations
}

export type TRelations = {
  collaboratorId: number
  collaboratorName: string
  relationJiraName: TRelationsJiraName[]
}

export type TRelationBody = {
  collaboratorId: number
  relationJiraName: string
  jiraCompanyId: number
  idArray?: number
  email?: string
}

export type TRelationsResponseEmail = TCommonResponse & {
  content: TEmailEmployee
}
export type TEmployeeRegister = {
  admissionDate: string,
  collaboratorId: number | null,
  email: string,
  name: string,
}

export type TEmailEmployee = {
  email?: string
}

type TEmailBody = {
  id: number
  email: string
}

export type TRelationsJiraName = {
  id: number
  key?: number
  idArray?: number
  name: string
  jiraCompanyId: number
  jiraCompanyName: string
}

export type TCompaniesResponse = TCommonResponse & {
  content: TCompanies[]
}

export type TCompanies = {
  id: number
  name: string
  clients: []
}

export type TDeleteResponse = TCommonResponse

const useEmployees = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getCancelToken = () => {
    return rest.generateCancelToken()
  }

  const isRequestLibraryError = (error: unknown): error is ErrorWrapper => {
    return rest.isRequestLibraryError(error)
  }

  const getEmployees = async (
    params: TEmployeesParams,
    triggerLoading = true
  ): Promise<TEmployeesResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/collaborator'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as TEmployeesResponse
  }

  const getPjEmployees = async (triggerLoading = true): Promise<TEmployeesResponse> => {
    try {
      if (triggerLoading) setIsLoading(true)
      const url = '/collaborator?orderDir=DESC&orderField=ADMISSION_DATE&type=PJ'
      const response = await rest.get(url)
      if (triggerLoading) setIsLoading(false)
      return response as TEmployeesResponse
    } catch (error) {
      return {} as TEmployeesResponse
    }
  }

  const getRelations = async (id: number, triggerLoading = true): Promise<TRelationsResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = `/jiraname/relation-name-collaborator/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)

    return response as TRelationsResponse
  }

  const getCompanies = async (): Promise<TCompaniesResponse> => {
    const url = '/client/jira-company/all'
    const response = await rest.get(url)

    return response as TCompaniesResponse
  }

  const postSaveRelation = async (
    data: TRelationBody,
    triggerLoading = true
  ): Promise<TRelationsResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/jiraname/save'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)

    return response as TRelationsResponse
  }

  const deleteRelation = async (id: number, triggerLoading = true): Promise<TCommonResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = `/jiraname/delete/${id}`
    const response = await rest.deleteReq(url)
    if (triggerLoading) setIsLoading(false)

    return response as TDeleteResponse
  }

  const updateEmailEmployee = async (
    data: TEmailBody,
    triggerLoading = true
  ): Promise<TRelationsResponseEmail> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/collaborator/save-email'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response as TRelationsResponseEmail
  }

  const createPjEmployee = async ({
    data,
    developerOptions
  }: {
    data: TEmployeeRegister,
    developerOptions?: DeveloperOptions
  }): Promise<any> => {
    const response = await rest.post2({
      url: '/collaborator/save-or-update-collaborator-cnpj',
      bodyData: data,
    })

    if (developerOptions?.returnResponseRef) {
      return response
    }

    return response.data
  }

  return {
    isLoading,
    getEmployees,
    getRelations,
    getCompanies,
    postSaveRelation,
    deleteRelation,
    updateEmailEmployee,
    createPjEmployee,
    getPjEmployees,
    getCancelToken,
    isRequestLibraryError,
  }
}

export default useEmployees
