import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding-top: 30%;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  span {
    position: absolute;
    background: ${({ theme }): any => theme.colors.WHITE};
    width: 85px;
    height: 85px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
  }
`

export const Spinner = styled.div`
  background: ${({ theme }): string =>
    `linear-gradient(to left,${theme.colors.BLUE} , ${theme.colors.GREEN}, ${theme.colors.WHITE} )`};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
