import React from 'react'
import { Span } from './styles'

type TProps = {
  description: string
  required: boolean
}

const Label = (props: TProps): JSX.Element => {
  const {
    description,
    required = false,
  } = props

  return (
    <Span required={required}>
      {description}
    </Span>
  )
}

export default Label