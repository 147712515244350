import styled from 'styled-components'
import { InfoCircleFilled, ReloadOutlined } from '@ant-design/icons'

export const Content = styled.div`
  padding: 40px 40px 0px 40px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
`

export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 40px;
`

export const SubTitleRed = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 40px;
  color: ${({ theme }): any => theme.colors.RED};
`

export const Info = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28, 74px;
  padding-bottom: 40px;
`

export const IconInfo = styled(InfoCircleFilled)`
  margin-right: 5px;
  svg {
    fill: ${({ theme }): any => theme.colors.YELLOW};
  }
`

export const Footer = styled.div`
 display: grid;
 justify-content: flex-end;
`

export const IconActions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80px;
`

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 500px;
  button {
    display: flex;
    width: 250px;
    height: 30px;
    align-items: center;
  }
`
export const SuccessfulSpan = styled.span`
  display: block;
  color: green;
  font-weight: bolder;
  text-align: center;
  width: fit-content;
`
export const InSuccessfulSpan = styled.span`
  display: block; 
  color: red;
  font-weight: bolder;
  text-align: center;
  width: fit-content;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
`
export const RefreshIcon = styled(ReloadOutlined)`
  font-size: 20px;
  text-align: center;
  svg {
    fill: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
  }
`

export const RefreshDiv = styled.div`
  padding-right: 50px;
`