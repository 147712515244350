import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useImport, { TFileResponse, TFileComplete } from 'repositories/useImport'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { namespaces } from 'i18n/i18n.constants'
import { DialogContext } from 'hooks/dialog/context'
import { formatDateToView, formatFileSize, formatFileType } from 'utils/helpers'
import moment from 'moment'
import filter from 'lodash/filter'
import map from 'lodash/map'

import UploadButton from 'components/UploadButton'
import DataTable from 'components/DataTable'
import Button from 'components/Button'

import { ModalRemoveFile, ModalImportingFiles } from '../Modal'
import { Content, Header, Title, SubTitle, Footer, IconRow, Row, InfoSpan } from './styles'
import { TFile } from '../type'
import { isEmpty } from 'lodash'
import { TemplateModal } from './Modal/TemplateModal'
import { Tooltip, notification } from 'antd'
import useSocket from 'hooks/useSocket'

const ImportFiles = (): JSX.Element => {

  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const files = useImport()
  const [currentFile, setCurrentFile] = useState<TFileComplete[]>([])
  const [templateModalIsVisible, setTemplateModalIsVisible] = useState<boolean>(false)
  const socket = useSocket()

  const removeFile = (file: TFileResponse): void => {
    createDialog({
      id: 'remove-file-modal',
      open: false,
      Component: ModalRemoveFile,
      props: {
        title: t('title', { ns: namespaces.pages.importFile }),
        subTitle: t('import files and stay up to date.', { ns: namespaces.pages.importFile }),
        text: t('are you sure you want to delete the document?', { ns: namespaces.common }),
        confirmRemoveFile: () => setCurrentFile((old) => filter(old, ({ key }) => key !== file.key))
      }
    })
  }

  const removeDuplicates = (filesList: TFileComplete[]): TFileComplete[] => {
    const keyName = 'unique'
    return [...Array.from(new Map(filesList.map((item) =>
      [item[keyName], item])).values())]
  }

  const columns = [
    {
      title: `${t('name file', { ns: namespaces.common })}:`,
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: `${t('added to', { ns: namespaces.common })}:`,
      dataIndex: 'addedTo',
    },
    {
      title: `${t('type', { ns: namespaces.common })}:`,
      dataIndex: 'type',
      ellipsis: true,
    },
    {
      title: `${t('size', { ns: namespaces.common })}:`,
      dataIndex: 'size',
      render: (text: number): string => formatFileSize(text)
    },
    {
      title: `${t('action', { ns: namespaces.common })}:`,
      dataIndex: 'actions',
      render: (text: number, record: TFileResponse): JSX.Element => (
        <IconRow>
          {/* <Button
            disabled={!record.isError}
            label=''
            id=''
            shape='circle'
            type='ghost'
            icon={(
              <Tooltip placement='bottom' title={t('view log', { ns: namespaces.pages.importFile })}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
            onClick={() => showErrorLogs(record)}
          />
          <Button
            disabled={!record.logDownloadId}
            label=''
            id=''
            shape='circle'
            type='ghost'
            icon={(
              <Tooltip placement='bottom' title={t('download log', { ns: namespaces.pages.importFile })}>

                <DownloadOutlined />
              </Tooltip>
            )}
            onClick={() => getErrorFile(record)}
          /> */}
          <Button
            label=''
            id=''
            shape='circle'
            type='ghost'
            icon={(
              <Tooltip placement='bottom' title={t('remove', { ns: namespaces.common })}>

                <DeleteOutlined />
              </Tooltip>
            )}
            onClick={(): void => removeFile(record)}
          />
        </IconRow>
      ),
    },
  ]

  const sendFile = useCallback(
    (file: TFile) => {
      const mapped = map(file, (item) => ({
        ...item,
        unique: `${item.name}_${item.size}`,
        key: item.uid,
        name: item.name,
        addedTo: formatDateToView(moment().format('L')),
        type: formatFileType(item.type, t),
      }))

      const noDuplicateFiles = removeDuplicates(mapped)
      if (!(noDuplicateFiles.length === mapped.length)) {
        notification.destroy()
        notification.warning({
          message: t('duplicated file', { ns: namespaces.pages.analysisHours }),
          placement: 'topRight',
        })
      }
      setCurrentFile(noDuplicateFiles)
    },
    [],
  )

  const triggerToasts = (status: number, fileName: string): void => {
    switch (status) {
      case 406:
        notification.error({
          message: t('error importing the file', { ns: namespaces.pages.importFile }).replace('{fileName}', fileName),
          placement: 'topRight',
        })
        break
      case 500:
        notification.error({
          message: t('error importing the file', { ns: namespaces.pages.importFile }).replace('{fileName}', fileName),
          placement: 'topRight',
        })
        break
      case 200:
        notification.success({
          message: t('the file was successfully imported', { ns: namespaces.pages.importFile }).replace('{fileName}', fileName),
          placement: 'topRight',
        })
        break
      case 206:
        notification.info({
          message: t('The file has been imported, but some of its data has already been consolidated previously.', { ns: namespaces.pages.importFile }).replace('{fileName}', fileName),
          placement: 'topRight',
        })
        break
      default:
        alert(t('default', { ns: namespaces.errors }))
    }
  }


  const uploadFiles = (): void => {
    const stompClient = socket.socketConnect()
    stompClient.activate()

    stompClient.onConnect = async (frame): Promise<void> => {
      console.log('connect frame', frame)
      const formData = new FormData()

      currentFile.forEach((item) => {
        if (item.originFileObj) formData.append('files', item.originFileObj)
      })

      const response = await files.importFiles(formData)
      console.log('import resonse', response)

      if (!response) {
        notification.error({
          message: t('error importing data', { ns: namespaces.pages.importFile }),
          placement: 'topRight',
        })
        stompClient.deactivate()
        return
      }

      response.data.content.forEach((fileResponse, fileIndex) => {
        if (!fileResponse?.success?.requestId) {
          console.log('error importing')
          notification.error({
            message: t('error importing data', { ns: namespaces.pages.importFile }),
            placement: 'topRight',
          })

          if (fileIndex === response.data.content.length - 1) {
            stompClient.deactivate()
          }

        } else {
          const subscription = stompClient.subscribe(`/topic/messages/${fileResponse.success.requestId}`, (messageOutput) => {
            console.log('subscribe websocket response', messageOutput)
            const jsonParse = JSON.parse(messageOutput.body)
            triggerToasts(jsonParse.body.status, jsonParse.body.content.fileName)
            subscription.unsubscribe()

            if (fileIndex === response.data.content.length - 1) {
              stompClient.deactivate()
            }
          })
        }
      })

      createDialog({
        id: 'importing-files-modal',
        open: true,
        Component: ModalImportingFiles,
        props: {
          title: 'Arquivos em processamento!'
        }
      })
      setCurrentFile([])
    }

    stompClient.onStompError = (frame) => {
      console.log('websocketError', frame)
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('websocketError', { ns: namespaces.common })
      })
      stompClient.deactivate()
    }
  }

  return (
    <>
      <Content>
        <Header>
          <Title>
            {t('title', { ns: namespaces.pages.importFile })}
          </Title>
          <Row>
            <SubTitle>
              {t('sub title', { ns: namespaces.pages.importFile })}
            </SubTitle>
            <InfoSpan onClick={(): void => setTemplateModalIsVisible(true)}>
              <QuestionCircleOutlined />
              {t('questions about file template', { ns: namespaces.pages.importFile })}
            </InfoSpan>
          </Row>
        </Header>
        <UploadButton
          fileList={currentFile}
          disable={false}
          onchangeUploadFile={sendFile}
          maxFile={100}
          isMultipleFile
        />
        <DataTable data={currentFile} columns={columns} pagination={false} />
        <Footer>
          <Button
            shape='default'
            id='btn-employees-update'
            type='primary'
            label={t('title', { ns: namespaces.pages.importFile })}
            onClick={uploadFiles}
            disabled={isEmpty(currentFile)}
            loading={files.isLoading}
          />
        </Footer>
      </Content>
      <TemplateModal isOpen={templateModalIsVisible} handleClose={setTemplateModalIsVisible} />
    </>
  )
}

export default ImportFiles