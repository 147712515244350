import React from 'react'

import { Container, PrimaryButton } from './styles'

type TProps = {
  type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text'
  icon?: React.ReactNode
  label: any
  id: string
  onClick?: () => void
  shape?: 'default' | 'circle' | 'round' | undefined
  disabled?: boolean
  loading?: boolean
}

const Button = (props: TProps): JSX.Element => {
  const {
    type = 'default',
    icon,
    label,
    id,
    onClick,
    shape,
    disabled = false,
    loading = false
  } = props

  return (
    <Container>
      <PrimaryButton
        size='middle'
        type={type}
        icon={icon}
        id={id}
        onClick={onClick}
        htmlType='submit'
        shape={shape}
        disabled={disabled}
        loading={loading}
      >{label}
      </PrimaryButton>
    </Container>
  )
}
export default Button