import React, { useEffect, useContext } from 'react'
import {
  initialValues,
  validation
} from './validations'
import useConfig from 'repositories/useConfig'
import { DialogContext } from 'hooks/dialog/context'
import { Form, Input, InputNumber } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import {
  TextField,
  CheckField,
  Button
} from 'components'
import ModalEmailConfiguration from './ModalEmailConfiguration'

import {
  Title,
  SubTitle,
  Wrapper
} from '../styles'
import {
  FormBox,
  FormBackground,
  FormSubTitle,
} from './styles'

export type TEmailConfig = {
  id: number | null
  host: string
  port: number
  fromName: string
  fromEmail: string
  password: string
  enableStartTls: boolean
  isAuthenticatedEmail: boolean
  intervalBulkEmailMs: number
}

const EmailConfig = (): JSX.Element => {
  const config = useConfig()
  const [form] = Form.useForm<TEmailConfig>()
  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)


  const onFinish = async (values: TEmailConfig): Promise<void> => {
    const response = await config.postEmailConfig(values)
    if (!response) return

    form.setFieldsValue({ id: response })
    createDialog({
      id: 'modalSuccess',
      open: false,
      Component: ModalEmailConfiguration,
      props: {
        title: t('notification email', { ns: namespaces.pages.settings }),
        subTitle: t('set up and send automated emails easier.', { ns: namespaces.pages.settings }),
        isLoading: false,
      }
    })
  }

  useEffect(() => {
    const requestConfig = async (): Promise<void> => {
      const response = await config.getEmailConfig()
      if (!response?.content) return

      populateForm(response.content)
    }

    const populateForm = (values: TEmailConfig): void => {
      form.setFieldsValue(values)
    }

    requestConfig()
  }, [])

  return (
    <Wrapper>
      <Title>{t('notification email', { ns: namespaces.pages.settings })}</Title>
      <SubTitle> {t('set up and send automated emails easier.', { ns: namespaces.pages.settings })}</SubTitle>
      <FormBackground>
        <FormBox>
          <Form
            name='basic'
            initialValues={initialValues}
            onFinish={onFinish}
            form={form}
            labelCol={{ span: 10 }}
            labelAlign='left'
            autoComplete='off'
          >
            <TextField
              name='id'
              label='Id'
              hidden
              required
              disabled={true}
              id='input-id'
            />
            <Form.Item
              label={t('smtp server', { ns: namespaces.pages.settings })}
              rules={validation('host', t)}
              required
              name='host'
              id='input-host'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('smtp connection port', { ns: namespaces.pages.settings })}
              rules={validation('port', t)}
              name='port'
              id='input-port'
              required
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={t('smtp user', { ns: namespaces.pages.settings })}
              rules={validation('fromEmail', t)}
              required
              name='fromEmail'
              id='input-fromEmail'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('smtp password', { ns: namespaces.pages.settings })}
              rules={validation('password', t)}
              required
              name='password'
              id='input-password'
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t('default sender name', { ns: namespaces.pages.settings })}
              rules={validation('fromName', t)}
              required
              name='fromName'
              id='input-fromName'
            >
              <Input />
            </Form.Item>
            <FormSubTitle>{t('bulk email', { ns: namespaces.pages.settings })}</FormSubTitle>

            <Form.Item
              label={t('bulk email interval', { ns: namespaces.pages.settings })}
              rules={validation('intervalBulkEmailMs', t)}
              required
              name='intervalBulkEmailMs'
              id='input-intervalBulkEmailMs'
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              id='input-enable-start-tls'
              name='enableStartTls'
            >
              <CheckField
                id='input-enable-start-tls'
                name='enableStartTls'
                label={t('use ssl/tl connection', { ns: namespaces.pages.settings })}
                rules={validation('enableStartTls', t)}
              />
            </Form.Item>
            <Form.Item
              id='input-is-authenticated-email'
              name='isAuthenticatedEmail'
            >
              <CheckField
                id='input-is-authenticated-email'
                name='isAuthenticatedEmail'
                label={t('authenticated email', { ns: namespaces.pages.settings })}
                rules={validation('isAuthenticatedEmail', t)}
              />
            </Form.Item>
            <Button
              shape='default'
              id='btn-email-settings'
              type='primary'
              label={t('save', { ns: namespaces.common })}
              loading={config.isLoading}
            />
          </Form>
        </FormBox>
      </FormBackground>
    </Wrapper>)
}

export default EmailConfig