import { useState } from 'react'
import useRest from 'hooks/useRest'

type TBodyClosePeriod = {
  startDate: string | null;
  endDate: string | null;
};

type TBodyGetClosedPeriod = {
  startDate?: string;
  endDate?: string;
  page: number;
  pageSize: number;
}

const usePeriod = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getClosePeriod = async (
    params: TBodyClosePeriod,
    triggerLoading = true
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/time-check/close-period'
    const response = await rest.get(url, params)

    if (triggerLoading) setIsLoading(false)
    if (!response) return {}

    return response
  }

  const getClosedPeriods = async (
    params: TBodyGetClosedPeriod,
    triggerLoading = true,
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/time-check/list-all-period'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {}

    return response
  }

  const getReopenPeriod = async (
    id: number,
    triggerLoading = true,
  ): Promise<any> => {
    const url = `/time-check/reopen-period/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {}

    return response
  }

  return {
    isLoading,
    getClosePeriod,
    getClosedPeriods,
    getReopenPeriod,
  }
}

export default usePeriod
