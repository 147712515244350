import styled from 'styled-components'
import {
  DataTable,
} from 'components'

export const ModalDataTable = styled(DataTable)`
  border-spacing: 0px;

  th.DetailsDivergence__TableColumn {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
  td.DetailsDivergence__TableColumn {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
`

export const Header = styled.div`
  margin-bottom: 50px;
  text-align: center;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    width: 330px;
  }
`

export const LogBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  li, p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    width: 100%;
    padding-bottom: 5px;
  }

  p {
    text-align: center;
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`

export const BoxInfo = styled.div`
  width: 100%;
  text-align: center;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    width: 100%;
  }

  span.title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    width: 100%;
  }
`

export const BoxLine = styled.div`
  background-color: ${({ theme }): any => theme.colors.WEAK_WHITE};
  color: #000;
  margin: .4rem 0;

  .row-details {
    justify-content: center;
  }
`
