import styled from 'styled-components'

type TProps = {
  required: boolean
}

export const Span = styled.span<TProps>`
  ${({ required, theme }): string | false => required && `
      :before {
        content: '* ';
        color: ${theme.colors.RED};
        font-size: 16px;
      }
  `}
`