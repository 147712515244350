import React, { useEffect, useState, } from 'react'
import { Col, Row, Table } from 'antd'
import { Button, Edit, } from 'components'
import { Content, Header, Title } from './styles'
import useEmployees, {
  TCollaborators,
} from 'repositories/useEmployees'
import EmployeesRegister from '../Register'
import { TPj } from 'repositories/useTimeAnalysis'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import moment from 'moment'


const EmployeesPJ = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const employees = useEmployees()
  const [isLoadingFilter, setIsLoadingFilter] = useState(false)
  const [pjEmployee, setPjEmployee] = useState({ employeeEmail: null, employeeId: null, employeeName: null } as TPj)
  const [pjList, setPjList] = useState<TCollaborators[]>()
  const { t } = useTranslation('namespaces')

  const getPj = async (): Promise<void> => {
    setIsLoadingFilter(true)
    try {
      const resp = await employees.getPjEmployees()
      setPjList(resp.content.collaborators)
    } catch (error) {
      setIsLoadingFilter(false)
    }
    setIsLoadingFilter(false)
  }

  useEffect(() => {
    getPj()
  }, [])

  return (
    <Content>
      <Header>
        <Row justify='space-between'>
          <Col>
            <Title>{t('registerTitle', { ns: namespaces.pages.employeesRegister })}</Title>
          </Col>
          <Col>
            <Button
              shape='default'
              id='button-filter'
              type='primary'
              label={t('include', { ns: namespaces.pages.employeesRegister })}
              disabled={false}
              onClick={(): void => {
                setPjEmployee({ employeeAdmissionDate: null, employeeEmail: null, employeeId: null, employeeName: null, })
                setIsOpen(true)
              }}
            />
          </Col>
        </Row>
        <Table
          rowKey={(record: any): any => record.employeeName}
          columns={[
            {
              title: t('name', { ns: namespaces.pages.employeesRegister }),
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: t('admissionDate', { ns: namespaces.pages.employeesRegister }),
              dataIndex: 'admissionDate',
              key: 'admissionDate',
              render: (value, record, index): string => {
                return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
              },
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email'
            },
            {
              title: t('edit', { ns: namespaces.pages.employeesRegister }),
              dataIndex: 'action',
              key: 'action',
              align: 'center',
              render: (text, record): JSX.Element => (
                <a
                  title={'edit'}
                  onClick={(): void => {
                    const _r = {
                      employeeId: record.id,
                      employeeName: record.name,
                      employeeEmail: record.email,
                      employeeAdmissionDate: record.admissionDate,
                    }
                    setPjEmployee(_r)
                    setIsOpen(true)
                  }}
                >
                  <Edit />
                </a>
              ),
            }
          ]}
          loading={isLoadingFilter}
          dataSource={pjList}
        />
      </Header>
      <EmployeesRegister
        open={isOpen}
        handleGetClose={(): void => {
          setIsOpen(false)
          getPj()
        }}
        handleClose={(): void => {
          setIsOpen(false)
        }}
        keyModal={0}
        employee={pjEmployee}
      />
    </Content>
  )
}

export default EmployeesPJ
