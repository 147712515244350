import styled from 'styled-components'

export const Container = styled.aside`
    position: absolute;
    width: 250px;
    height: calc(100vh - 60px);
    border-right: 1px solid ${({ theme }): any => theme.colors.LIGHT_GRAY};
    padding-top: 20px;
    color: ${({ theme }): any => theme.colors.MEDIUM_GRAY};

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
        width: 82px;
    }
`