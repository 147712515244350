import React, { useState, useEffect, useContext } from 'react'
import Moment from 'moment'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { RangeValue } from 'rc-picker/lib/interface'
import { SearchOutlined } from '@ant-design/icons'
import { Tooltip, notification } from 'antd'
import usePeriod from 'repositories/usePeriod'
import usePagination from 'hooks/usePagination'
import { DataTable, Button, DateRangePickerField } from 'components'
import {
  Content,
  Header,
  Title,
  SubTitle,
  SubRow,
  Container,
  FilterAnalysis,
  DatePickerBox,
  SizeButton,
  ApproveIcon,
  FilterContainer,
} from './styles'
import { formatDateToServer } from 'utils/helpers'
import { ClosePeriodModal, ReopenPeriodModal } from './Modal'
import { DialogContext } from 'hooks/dialog/context'

type TColumn = {
  title: string
  dataIndex: string
  colSpan?: number
  render?: (text: string, record: TRecord) => JSX.Element
  align?: string
  key?: string
  ellipsis?: boolean
  sorter?: (a: any, b: any) => void
  filterDropdown?: () => JSX.Element
}

type TRecord = {
  id: number
  startPeriodDate: string
  endPeriodDate: string
  closingDate: string
}

const Period = (): JSX.Element => {
  const { t } = useTranslation('namespaces')
  const pagination = usePagination()
  const period = usePeriod()
  const [intervalDate, setIntervalDate] = useState<RangeValue<moment.Moment>>()
  const [periodFilterIntervalDate, setPeriodFilterIntervalDate] =
    useState<RangeValue<moment.Moment>>()
  const [closingDatedFilterIntervalDate, setClosingDateFilterIntervalDate] =
    useState<RangeValue<moment.Moment>>()
  const [loading, setLoading] = useState<boolean>(true)
  const [isLoadingFilter, setIsLoadingFilter] = useState(false)
  const [data, setData] = useState<object[]>([])

  const { createDialog } = useContext(DialogContext)

  useEffect(() => {
    getClosedPeriodsList(true)
    setColumns(defaultColumnsHead)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (pagination.data.current) getClosedPeriodsList(false)
  }, [pagination.data.current, pagination.data.pageSize])

  const reopenPeriod = (record: TRecord): void => {
    const reopenPeriodById = async (): Promise<void> => {
      const response = await period.getReopenPeriod(record.id)
      if (response.status === 200) {
        notification.success({
          message: t('reopen_period_success', {
            ns: namespaces.pages.period,
          }).replace(
            '{period}',
            `${
              record.startPeriodDate &&
              Moment(record.startPeriodDate).format('DD/MM/YYYY')
            } - ${
              record.endPeriodDate &&
              Moment(record.endPeriodDate).format('DD/MM/YYYY')
            }`
          ),
          placement: 'topRight',
        })
        getClosedPeriodsList(true)
      }
    }
    createDialog({
      id: 'reopen-period-confirmation-modal',
      open: false,
      Component: ReopenPeriodModal,
      props: {
        text: t('reopen_period', { ns: namespaces.pages.period }).replace(
          '{period}',
          `${
            record.startPeriodDate &&
            Moment(record.startPeriodDate).format('DD/MM/YYYY')
          } - ${
            record.endPeriodDate &&
            Moment(record.endPeriodDate).format('DD/MM/YYYY')
          }`
        ),
        reopenPeriod: () => reopenPeriodById(),
      },
    })
  }

  /**
   * @name getClosedPeriodsList
   * @returns retorna todos os períodos fechados
   */

  const getClosedPeriodsList = (isResetingPagination: boolean) => {
    const currentPage = isResetingPagination ? 0 : pagination?.data.current ?? 0
    const params: any = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      pageSize: pagination?.data.pageSize ?? 1,
    }
    let cancel = false
    const getClosedPeriodsList = async (): Promise<void> => {
      if (cancel) return
      const response = await period.getClosedPeriods(params)
      const { content } = response
      pagination.changeTotalPages(content.pageable.totalPages)
      setData(content.periodList)
    }
    getClosedPeriodsList()
    return (): void => {
      cancel = true
    }
  }

  /**
   * @name handleFilter
   * @returns retorna o filtro do periodo de acordo com os valores do intervalo de datas e tipo de filtro
   */

  const handleFilter = async (searchType: string) => {
    let cancel = false
    if (searchType === 'periodDate') {
      if (!periodFilterIntervalDate) return
      const [from, to] = periodFilterIntervalDate
      const currentPage = pagination.data.current ?? 0
      const params = {
        startDate: formatDateToServer(from),
        endDate: formatDateToServer(to),
        page: currentPage === 0 ? currentPage : currentPage - 1,
        pageSize: pagination.data.pageSize ?? 1,
      }
      if (cancel) return
      const response = await period.getClosedPeriods(params)
      setIsLoadingFilter(false)
      if (!response) return
      const { content } = response

      pagination.changeTotalPages(content?.pageable.totalPages)
      setData(content.periodList)
    }

    if (searchType === 'closingDate') {
      if (!closingDatedFilterIntervalDate) return
      if (cancel) return
      setIsLoadingFilter(true)
      const [from, to] = closingDatedFilterIntervalDate
      const currentPage = pagination.data.current ?? 0
      const params = {
        closingDateStart: formatDateToServer(from),
        closingDateEnd: formatDateToServer(to),
        page: currentPage === 0 ? currentPage : currentPage - 1,
        pageSize: pagination.data.pageSize ?? 1,
      }

      const response = await period.getClosedPeriods(params)
      setIsLoadingFilter(false)
      if (!response) return
      const { content } = response

      pagination.changeTotalPages(content?.pageable.totalPages)
      setData(content.periodList)
    }
    return (): void => {
      cancel = true
    }
  }

  /**
   * @name handleCheckTimeClosePeriod
   * @returns retorna o fechamento de um período x à y que foi informado
   */
  const handleCheckTimeClosePeriod = (): void => {
    if (!intervalDate) return
    const [from, to] = intervalDate
    const getClosePeriod = async (): Promise<void> => {
      setIsLoadingFilter(true)
      const params = {
        startDate: formatDateToServer(from),
        endDate: formatDateToServer(to),
      }
      const response = await period.getClosePeriod(params)
      setIsLoadingFilter(false)
      if (!response) return
      if (response.status === 200) {
        notification.success({
          message: t('close_period_success', {
            ns: namespaces.pages.period,
          }).replace(
            '{period}',
            `${Moment(from).format('DD/MM/YYYY')} - ${Moment(to).format(
              'DD/MM/YYYY'
            )}`
          ),
          placement: 'topRight',
        })
      }
      getClosedPeriodsList(true)
    }
    createDialog({
      id: 'close-period-confirmation-modal',
      open: false,
      Component: ClosePeriodModal,
      props: {
        text: t('close_period', { ns: namespaces.pages.period }).replace(
          '{period}',
          `${Moment(from).format('DD/MM/YYYY')} - ${Moment(to).format(
            'DD/MM/YYYY'
          )}`
        ),
        closePeriod: () => getClosePeriod(),
      },
    })
  }

  useEffect(() => {
    handleFilter('periodDate')
  }, [periodFilterIntervalDate])

  useEffect(() => {
    handleFilter('closingDate')
  }, [closingDatedFilterIntervalDate])

  const handleCleanFilter = (): void => {
    setPeriodFilterIntervalDate(null)
    setClosingDateFilterIntervalDate(null)
    getClosedPeriodsList(true)
  }

  const getColumnPeriodDateSearchProps = () => ({
    filterDropdown: (): JSX.Element => (
      <>
        <FilterContainer>
          <DatePickerBox>
            {t('period', { ns: namespaces.common })}
            <DateRangePickerField onChangeValue={setPeriodFilterIntervalDate} />
          </DatePickerBox>
        </FilterContainer>
        <Button
          label={t('clean_filter', { ns: namespaces.common })}
          id='clean-filter-period-date'
          shape='default'
          type='dashed'
          onClick={handleCleanFilter}
        />
      </>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })
  const getColumnClosingDateSearchProps = () => ({
    filterDropdown: (): JSX.Element => (
      <>
        <FilterContainer>
          <DatePickerBox>
            {t('closing_date', { ns: namespaces.pages.period })}
            <DateRangePickerField
              onChangeValue={setClosingDateFilterIntervalDate}
            />
          </DatePickerBox>
        </FilterContainer>
        <Button
          label={t('clean_filter', { ns: namespaces.common })}
          id='clean-filter-closing-date'
          shape='default'
          type='dashed'
          onClick={handleCleanFilter}
        />
      </>
    ),
    filterIcon: (filtered: boolean): JSX.Element => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })
  const defaultColumnsHead: TColumn[] = [
    {
      title: t('title_period', { ns: namespaces.pages.consolidated }),
      dataIndex: 'periodDate',
      key: 'periodDate',
      ...getColumnPeriodDateSearchProps(),
      sorter: (a, b): number =>
        Moment(a.startPeriodDate).unix() - Moment(b.startPeriodDate).unix(),
      render: (text: string, record: TRecord): JSX.Element => (
        <span>{`${
          record.startPeriodDate &&
          Moment(record.startPeriodDate).format('DD/MM/YYYY')
        } - ${
          record.endPeriodDate &&
          Moment(record.endPeriodDate).format('DD/MM/YYYY')
        }`}</span>
      ),
    },
    {
      title: t('closing_date', { ns: namespaces.pages.period }),
      dataIndex: 'closingDate',
      key: 'closingDate',
      ...getColumnClosingDateSearchProps(),
      sorter: (a, b): number =>
        Moment(a.closingDate).unix() - Moment(b.closingDate).unix(),
      render: (text: string, record: TRecord): JSX.Element => (
        <span>
          {record.closingDate &&
            Moment(record.closingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: t('action', { ns: namespaces.common }),
      dataIndex: 'icon',
      key: 'action',
      align: 'center',
      render: (text: string, record: TRecord): JSX.Element => (
        <Tooltip
          placement='bottom'
          title={t('reopen', { ns: namespaces.pages.period })}
        >
          <a onClick={(): void => reopenPeriod(record)}>
            <ApproveIcon />
          </a>
        </Tooltip>
      ),
    },
  ]

  const [columns, setColumns] = useState<TColumn[]>(
    data !== null && data.length > 0 ? defaultColumnsHead : []
  )

  return (
    <Content>
      <Header>
        <Title>{t('title', { ns: namespaces.pages.period })}</Title>
        <SubRow>
          <SubTitle>{t('subtitle', { ns: namespaces.pages.period })}</SubTitle>
        </SubRow>
      </Header>

      <Container>
        <FilterAnalysis>
          <DatePickerBox>
            {t('period', { ns: namespaces.common })}
            <DateRangePickerField onChangeValue={setIntervalDate} />
          </DatePickerBox>

          <SizeButton>
            <Button
              id='submit-close-period'
              shape='default'
              type='primary'
              label={t('title', { ns: namespaces.pages.period })}
              disabled={!intervalDate}
              onClick={handleCheckTimeClosePeriod}
            />
          </SizeButton>
        </FilterAnalysis>
      </Container>

      <>
        <DataTable
          pagination={pagination.data}
          data={data}
          isFiltered= {true}
          columns={columns}
          loading={loading}
          onChange={pagination.onChangeTable}
          selectableRow={false}
          emptyMessage={t('empty_period', { ns: namespaces.pages.period })}
        />
      </>
    </Content>
  )
}

export default Period
