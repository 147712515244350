import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { DialogContext } from 'hooks/dialog/context'
import isEmpty from 'lodash/isEmpty'
import { namespaces } from 'i18n/i18n.constants'
import DataTable from 'components/DataTable'
import Button from 'components/Button'
import { ModalRemoveFile } from '../Modal'
import {
  Content,
  Header,
  Title,
  SubTitle,
  Info,
  IconInfo,
  IconActions,
  Tabs,
  SuccessfulSpan,
  InSuccessfulSpan,
  Container,
  RefreshIcon,
  RefreshDiv,
  SubTitleRed
} from './styles'
import useConsultFiles, { TConsultedFile, TConsultFilesParams } from 'repositories/useConsultFiles'
import usePagination from 'hooks/usePagination'
import { formatDateTimeToView, formatDateToView, downloadBase64File } from 'utils/helpers'
import { Tooltip, notification } from 'antd'



const ConsultFiles = (): JSX.Element => {

  const { t } = useTranslation('namespaces')
  const { createDialog } = useContext(DialogContext)
  const consultFiles = useConsultFiles()
  const pagination = usePagination()
  const [files, setFiles] = useState<TConsultedFile[]>([])
  const [checkSuccessful, setCheckSuccessful] = useState<boolean>(false)
  const [checkInSuccessful, setCheckInSuccessful] = useState<boolean>(false)


  useEffect(() => {
    getFiles()
  }, [pagination.data.current, pagination.data.pageSize])

  useEffect(() => {
    pagination.setDefaultPagination()
    getFiles()
  }, [checkSuccessful, checkInSuccessful])

  const getFileType = (type: number): string => {
    switch (type) {
      case 1: return 'Ponto'
      case 2: return 'Jira'
      case 3: return 'Ponto'
      default: return 'Desconhecido'
    }
  }

  const getCheckFilter = (): boolean | undefined => {
    if (checkSuccessful)
      return true
    if (checkInSuccessful)
      return false
    return undefined
  }

  const getFiles = async (): Promise<void> => {
    const currentPage = pagination.data.current ?? 0
    const params: TConsultFilesParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      successfullyProcessed: getCheckFilter()
    }

    const response = await consultFiles.getFiles(params)
    if (!response) return
    const { content } = response
    pagination.changeTotalPages(content?.pageable.totalPages)
    populateTable(content.fileList)
  }

  const getErrorFile = async (fileResponse: TConsultedFile): Promise<void> => {
    if (!fileResponse.id) return
    const response = await consultFiles.getErrorLog(fileResponse.id)

    if (!response) return

    const formattedFileName = fileResponse.originalFileName?.replace('.xlsx', '')
    const updateDate = formatDateToView(fileResponse.updatedDate)
    const importedIn = 'Adicionado_em'

    const fileName = `${formattedFileName}_${importedIn}_${updateDate}_log.txt`

    downloadBase64File(fileName, response.content.logsBase64, 'text/plain')
  }

  const populateTable = (filesList: TConsultedFile[]): void => {
    const formattedList: TConsultedFile[] = filesList.map((file) => {
      return {
        ...file,
        updatedDate: formatDateTimeToView(file.updatedDate),
        type: getFileType(file.fileType)
      }
    })

    setFiles(formattedList)
  }

  const removeFile = (file: TConsultedFile): void => {

    createDialog({
      id: 'remove-file-modal',
      open: false,
      Component: ModalRemoveFile,
      props: {
        title: t('title', { ns: namespaces.pages.consultFiles }),
        subTitle: t('see the imported files.', { ns: namespaces.pages.consultFiles }),
        text: t('are you sure you want to delete the document?', { ns: namespaces.common }),
        confirmRemoveFile: () => removeFileAndRefresh(file)
      }
    })
  }

  const removeFileAndRefresh = async (deletedFile: TConsultedFile): Promise<void> => {
    const response = await consultFiles.deleteFile(deletedFile.id)
    if (!response) return

    if (files.length === 1) {
      const { data, data: { current } } = pagination

      pagination.setPagination({
        ...data,
        current: Number(current) - 1
      })
      return
    }
    const filteredFiles = files.filter((file) => file.id !== deletedFile.id)
    notification.success({
      message: t('Arquivo excluído com sucesso!', { ns: namespaces.pages.analysisHours }),
      placement: 'topRight',
    })
    setFiles(filteredFiles)
  }

  const isSucessfulText = (isSucessful: boolean): JSX.Element => {
    if (isSucessful) return (<SuccessfulSpan>{t('success', { ns: namespaces.common })}</SuccessfulSpan>)
    return (<InSuccessfulSpan>{t('error', { ns: namespaces.common })}</InSuccessfulSpan>)
  }

  const handleSuccesful = (): void => {
    if (checkInSuccessful) {
      setCheckInSuccessful(false)
      setCheckSuccessful(true)
      return
    }
    setCheckSuccessful(!checkSuccessful)
  }

  const handleInSuccesful = (): void => {
    if (checkSuccessful) {
      setCheckSuccessful(false)
      setCheckInSuccessful(true)
      return
    }
    setCheckInSuccessful(!checkInSuccessful)
  }

  const handleRefresh = (): void => {
    pagination.setDefaultPagination()
    getFiles()
  }

  const columns = [
    {
      title: `${t('name file', { ns: namespaces.common })}:`,
      dataIndex: 'originalFileName',
      ellipsis: true,
    },
    {
      title: `${t('added to', { ns: namespaces.common })}:`,
      dataIndex: 'updatedDate',
    },
    {
      title: `${t('type', { ns: namespaces.common })}:`,
      dataIndex: 'type',
      ellipsis: true,
    },
    {
      title: `${t('size', { ns: namespaces.common })}:`,
      dataIndex: 'fileSize',
    },
    {
      title: 'Status:',
      dataIndex: 'isSuccessfullyProcessed',
      render: (text: any, record: TConsultedFile): JSX.Element => (
        isSucessfulText(record.isSuccessfullyProcessed)
      )
    },
    {
      title: `${t('actions', { ns: namespaces.common })}:`,
      dataIndex: 'actions',
      render: (text: any, record: TConsultedFile): JSX.Element => (
        <IconActions>
          <Button
            label=''
            id=''
            shape='circle'
            type='ghost'
            disabled={record.isSuccessfullyProcessed}
            onClick={(): Promise<void> => getErrorFile(record)}
            icon={(
              <Tooltip placement='bottom' title={t('download log', { ns: namespaces.pages.importFile })}>
                <DownloadOutlined />
              </Tooltip>
            )}
          />
          <Button
            label=''
            id=''
            shape='circle'
            type='ghost'
            onClick={(): void => removeFile(record)}
            icon={(
              <Tooltip placement='bottom' title={t('remove', { ns: namespaces.common })}>
                <DeleteOutlined />
              </Tooltip>
            )}
          />
        </IconActions>
      ),
    },
  ]

  return (
    <Content>
      <Header>
        <Title>
          {t('title', { ns: namespaces.pages.consultFiles })}
        </Title>
        <SubTitle>
          {t('sub title', { ns: namespaces.pages.consultFiles })}
        </SubTitle>
        <SubTitleRed>
          {t('warning subtitle', { ns: namespaces.pages.consultFiles })}
        </SubTitleRed>
      </Header>
      <Container>
        <Tabs>
          <Button
            id='button-successful-files'
            type={checkSuccessful ? 'primary' : 'ghost'}
            label={t('success files', { ns: namespaces.pages.consultFiles })}
            onClick={handleSuccesful}
            shape='round'
          />
          <Button
            id='button-successful-files'
            type={checkInSuccessful ? 'primary' : 'ghost'}
            label={t('error files', { ns: namespaces.pages.consultFiles })}
            onClick={handleInSuccesful}
            shape='round'
          />
        </Tabs>
        <RefreshDiv>
          <Tooltip placement='bottom' title={t('refresh', { ns: namespaces.pages.consultFiles })}>
            <span onClick={(): void => handleRefresh()}><RefreshIcon /></span>
          </Tooltip>
        </RefreshDiv>
      </Container>
      {(isEmpty(files)) ? (
        <Info>
          <IconInfo />
          {t('info description', { ns: namespaces.pages.consultFiles })}
        </Info>
      ) : (
        <DataTable
          data={files}
          columns={columns}
          pagination={pagination.data}
          onChange={pagination.onChangeTable}
          loading={consultFiles.isLoading}
        />
      )
      }
    </Content>
  )
}

export default ConsultFiles
