import React, { useState, useEffect } from 'react'
import { SaveModal, } from 'components'
import {
  Form, Row, Col, Select,
  Table,
  Tooltip,
  Button as AntDButton,
  notification,
  Input,
} from 'antd'
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import useEmployees, {
  TRelationBody,
  TCompanies,
  TRelationsJiraName,
} from 'repositories/useEmployees'
import { initialValues, validation } from './validations'
import { ColumnsType } from 'antd/lib/table'
import {
  ModalBody,
  CheckIcon,
  Box,
  FormItemForEmail,
  ButtonRefreshEmail,
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  keyModal: number
  clockName: string
  email: string
  companiesList: TCompanies[]
  companiesOptions: TSelectOption[]
  updateEmployees: () => void
}

export type TForm = {
  clockName: string
  email: string
  nameJira: string
  selectCompany: number
}

type TFormEmail = {
  id: number
  email: string
}

type TDeleteRelationsIds = {
  id: number
}

const ModalEmployeeForm = (props: TProps): JSX.Element => {
  const {
    open,
    handleClose,
    keyModal,
    clockName,
    email,
    updateEmployees,
    companiesList,
    companiesOptions,
  } = props

  const [formInstance] = Form.useForm()
  const { t } = useTranslation('namespaces')
  const employees = useEmployees()

  const [isEdited, setIsEdited] = useState(true)
  const [relationsVisibleArray, setRelationsVisibleArray] = useState<TRelationsJiraName[]>([])
  const [relationsDeleteArray, setRelationsDeleteArray] = useState<TDeleteRelationsIds[]>([])
  const [relationsPostArray, setRelationsPostArray] = useState<TRelationBody[]>([])

  const ChangeModal = (): void => {
    updateEmployees()
    setIsEdited(!isEdited)
    onSave()
  }

  const closeModal = (): void => {
    handleClose()
    updateEmployees()
  }

  const getRelationsList = async (id: number): Promise<void> => {
    const response = await employees.getRelations(id, true)
    if (!response) return

    setRelationsVisibleArray(response.content.relationJiraName)
  }

  const onClickAssociate = (values: TRelationBody): void => {
    const jiraAlreadyRelated = [
      ...relationsVisibleArray,
      ...relationsPostArray,
    ].filter((relation) => relation.jiraCompanyId === values.jiraCompanyId)

    if (jiraAlreadyRelated.length) {
      notification.warning({
        message: t('employee already linked to this company', {
          ns: namespaces.pages.analysisHours,
        }),
        placement: 'topRight',
      })
      return
    }

    let newId = 0
    for (let i = 0; i < relationsVisibleArray.length; i++) {
      newId = newId + i
    }

    const newRelation = {
      collaboratorId: keyModal,
      relationJiraName: values.relationJiraName,
      jiraCompanyId: values.jiraCompanyId,
      idArray: newId,
      key: Math.random(),
    }

    const newVisibleRelation: TRelationsJiraName = {
      id: 0,
      key: Math.random(),
      idArray: newId,
      name: values.relationJiraName,
      jiraCompanyId: values.jiraCompanyId,
      jiraCompanyName: companiesList[values.jiraCompanyId - 1].name,
    }

    setRelationsPostArray([])
    setRelationsVisibleArray([...relationsVisibleArray, newVisibleRelation])
    setRelationsPostArray([...relationsPostArray, newRelation])
    formInstance.resetFields(['relationJiraName', 'jiraCompanyId'])
  }

  const onClickDelete = (deleteId: number): void => {
    const newRelationsDeleteArray = {
      id: deleteId,
    }

    const newRelationsVisibleArray = relationsVisibleArray.filter(
      (relation) => relation.id !== deleteId
    )

    setRelationsVisibleArray(newRelationsVisibleArray)
    setRelationsDeleteArray([...relationsDeleteArray, newRelationsDeleteArray])
  }

  const onClickDeleteArray = (deleteId: number): void => {
    const newRelationsVisibleArray = relationsVisibleArray.filter(
      (relation) => relation.idArray !== deleteId
    )

    const newRelation = relationsPostArray.filter(
      (relation) => relation.idArray !== deleteId
    )

    setRelationsVisibleArray(newRelationsVisibleArray)
    setRelationsPostArray(newRelation)
  }

  const onSave = (): void => {
    relationsPostArray.map((relation) => {
      const data: TRelationBody = {
        collaboratorId: relation.collaboratorId,
        relationJiraName: relation.relationJiraName,
        jiraCompanyId: relation.jiraCompanyId,
      }
      const response = employees.postSaveRelation(data)
      if (!response) return
      return response
    })

    relationsDeleteArray.map((relation) => {
      const response = employees.deleteRelation(relation.id, true)
      return response
    })

    updateEmployees()
  }

  const handleUpdateEmail = async (): Promise<void> => {
    let employeeEmail = formInstance.getFieldValue('email')

    if (
      employeeEmail !== '' ||
      employeeEmail !== null ||
      employeeEmail !== undefined
    ) {
      const fieldsErros = formInstance.getFieldsError()
      let mailError = false
      fieldsErros.map((i) => {
        if (i.name.includes('email')) {
          if (i.errors.length > 0) {
            mailError = true
          }
        }
      })

      if (mailError) return
    }

    if (
      employeeEmail === '' ||
      employeeEmail === null ||
      employeeEmail === undefined
    ) {
      employeeEmail = ''
    }

    const data: TFormEmail = {
      id: keyModal,
      email: employeeEmail,
    }

    const response:
    | {
      detailMessage?: string | null
      error: string
      message?: string | null
      requestId?: number | string | null
      status: number
      timestamp?: Date | string | null
    }
    | undefined = await employees.updateEmailEmployee(data)

    if (response && response.status === 201) {
      notification.success({
        message: t('notify_update_email_success', {
          ns: namespaces.pages.employeesRelate,
        }),
        placement: 'topRight',
      })
    }
  }

  useEffect(() => {
    getRelationsList(keyModal)
    formInstance.setFieldsValue({ email })
  }, [])

  const columns: ColumnsType<TRelationsJiraName> = [
    {
      title: `${t('nameClock', { ns: namespaces.common })}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t('company', { ns: namespaces.pages.employeesRelate })}`,
      dataIndex: 'jiraCompanyName',
      key: 'jiraCompanyName',
    },
    {
      title: `${t('action', { ns: namespaces.common })}`,
      dataIndex: 'Icone',
      key: 'action',
      render: (text, record): JSX.Element => (
        <>
          {record.id > 0 ? (
            <Tooltip
              placement='bottom'
              title={t('remove', { ns: namespaces.common })}
            >
              <AntDButton
                type='link'
                onClick={(): void => onClickDelete(record.id)}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          ) : (
            <Tooltip
              placement='bottom'
              title={t('remove', { ns: namespaces.common })}
            >
              <AntDButton
                type='link'
                onClick={(): void => onClickDeleteArray(Number(record.idArray))}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ]

  const isEditedRelation =
    relationsDeleteArray.length > 0 || relationsPostArray.length > 0
  const isCancelLabel = isEditedRelation
    ? t('cancel', { ns: namespaces.common })
    : t('close', { ns: namespaces.common })

  return (
    <SaveModal
      id='modal-editar'
      title={t('title_modal', { ns: namespaces.pages.employeesRelate })}
      isModalVisible={open}
      key={Math.random()}
      handleClose={closeModal}
      handleConfirmation={(): void => ChangeModal()}
    >
      {isEdited ? (
        <>
          <ModalBody>
            <Row gutter={[24, 24]}>
              <Col
                xs={24}
              >
                <Form
                  form={formInstance}
                  layout='horizontal'
                  initialValues={initialValues}
                  autoComplete='off'
                  labelCol={{
                    xs: 4
                  }}
                  wrapperCol={{
                    xs: 20
                  }}
                  labelWrap
                  labelAlign='left'
                  onFinish={onClickAssociate}
                  onFinishFailed={
                    (errorInfo): void => {
                      console.log('form error', errorInfo)
                    }
                  }
                >
                  <Row
                    gutter={[12, 12]}
                  >
                    <Col xs={24}>
                      <Form.Item
                        label={`${t('nameClock', { ns: namespaces.common })}`}
                        name='clockName'
                        required
                      >
                        <Input
                          disabled
                          placeholder={clockName}
                          id='input-clockName'
                          style={{
                            width: '100%'
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={[12, 12]}
                  >
                    <Col
                      xs={19}
                      sm={21}
                      md={22}
                    >
                      <FormItemForEmail
                        name='email'
                        label={t('email', { ns: namespaces.common })}
                        rules={validation('email', t)}
                        className='EmployeesRelate__FormItemEmail'
                      >
                        <Input
                          id='inputEmailEmployee'
                          placeholder={`${t('placeholderEmployeeEmail', {
                            ns: namespaces.pages.employeesRelate,
                          })}`}
                          style={{
                            width: '100%'
                          }}
                        />
                      </FormItemForEmail>
                    </Col>
                    <Col
                      xs={5}
                      sm={3}
                      md={2}
                    >
                      <Tooltip
                        placement='top'
                        title={t('updateEmail', { ns: namespaces.pages.employeesRegister })}
                      >
                        <ButtonRefreshEmail
                          className='button-refresh-mail'
                          id='refresh-email'
                          type='primary'
                          loading={employees.isLoading}
                          onClick={handleUpdateEmail}
                          icon={(<SyncOutlined />)}
                          block
                        />
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row
                    gutter={[12, 12]}
                  >
                    <Col
                      xs={24}
                    >
                      <Form.Item
                        name='relationJiraName'
                        label={t('nameJira', { ns: namespaces.common })}
                        rules={validation('nameJira', t)}
                      >
                        <Input
                          id='input-relationJiraName'
                          style={{
                            width: '100%'
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    gutter={[12, 12]}
                  >
                    <Col
                      xs={24}
                    >
                      <Form.Item
                        name='jiraCompanyId'
                        rules={validation('selectCompany', t)}
                        label={t('company', { ns: namespaces.pages.employeesRelate })}
                      >
                        <Select
                          options={companiesOptions}
                          id='select-jiraCompanyId'
                          placeholder={t('companyPlaceholder', { ns: namespaces.pages.employeesRelate })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
              >
                <AntDButton
                  shape='default'
                  id='button-save'
                  type='primary'
                  loading={employees.isLoading}
                  block
                  size='large'
                  onClick={
                    (): void => {
                      formInstance.submit()
                    }
                  }
                  style={{
                    backgroundColor: '#0D5FD9',
                    border: '1px solid #0D5FD9'
                  }}
                >
                  {t('associate', { ns: namespaces.common })}
                </AntDButton>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
              >
                <Table
                  size='middle'
                  dataSource={relationsVisibleArray}
                  columns={columns}
                  pagination={false}
                />
              </Col>
            </Row>
            <Row
              gutter={[24, 24]}
            >
              <Col
                xs={12}
              >
                <AntDButton
                  shape='default'
                  id='button-cancel'
                  type='ghost'
                  onClick={closeModal}
                  block
                >
                  {isCancelLabel}
                </AntDButton>
              </Col>
              <Col
                xs={12}
              >
                <AntDButton
                  block
                  disabled={!isEditedRelation}
                  id='button-confirm'
                  type='primary'
                  onClick={ChangeModal}
                  loading={employees.isLoading}
                >
                  {t('save', { ns: namespaces.common })}
                </AntDButton>
              </Col>
            </Row>
          </ModalBody>
        </>
      ) : (
        <Box>
          <CheckIcon />
          <p>
            {t('employee updated successfully!', {
              ns: namespaces.pages.employeesRelate,
            })}
          </p>
        </Box>
      )}
    </SaveModal>
  )
}

export default ModalEmployeeForm
