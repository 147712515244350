import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Row, Col, notification } from 'antd'
import moment, { Moment } from 'moment'
import { Modal } from 'components'
import { Content, Title, SubTitle, BoxInfo, BoxLine, ModalDataTable } from './styles'
import useTimeAnalysis, {
  TAnalysisDetailsResponse, TDivergenceDTO
} from 'repositories/useTimeAnalysis'

type TProps = {
  open: boolean
  handleClose: () => void
  id: number,
  startDate: Moment
  endDate: Moment
}

const DetailsDivergence = (props: TProps): JSX.Element => {
  const { open = true, handleClose, id, startDate: startD, endDate: endD } = props
  const { t } = useTranslation('namespaces')
  const timeAnalysis = useTimeAnalysis()

  const [tableDataSource, setTableDataSource] = useState<Array<TDivergenceDTO> | undefined>([])
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false)

  const columns = [
    {
      title: t('data column title', { ns: namespaces.pages.analysisHours }),
      className: 'DetailsDivergence__TableColumn',
      dataIndex: 'periodDate',
      key: 'periodDate',
      render: (text: string): string => moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: t('clock in column title', { ns: namespaces.pages.analysisHours }),
      className: 'DetailsDivergence__TableColumn',
      dataIndex: 'workedPoint',
      key: 'workedPoint',
    },
    {
      title: t('jira column title', { ns: namespaces.pages.analysisHours }),
      className: 'DetailsDivergence__TableColumn',
      dataIndex: 'workedJira',
      key: 'workedJira',
    },
    {
      title: t('divergence column title', { ns: namespaces.pages.analysisHours }),
      className: 'DetailsDivergence__TableColumn',
      dataIndex: 'divergence',
      key: 'divergence',
    }
  ]

  useEffect(() => {
    const loadDivergences = async (): Promise<void> => {
      let responseError
      let responseSuccess: TAnalysisDetailsResponse | undefined

      const params = {
        employeeID: id,
        startDate: startD?.toISOString(),
        endDate: endD?.toISOString(),
      }

      setIsTableLoading(true)

      try {
        responseSuccess = await timeAnalysis.timeAnalysisDetails(params)
      } catch (error: unknown) {
        responseError = error
      }

      setIsTableLoading(false)

      if (responseError || responseSuccess?.error) {
        notification.error({
          message: t('details request general error', { ns: namespaces.pages.analysisHours }),
          placement: 'topRight',
        })
        return
      }

      setTableDataSource(responseSuccess?.content.divergenceList)
    }

    loadDivergences()
  }, [])

  return (
    <Modal id='modal-details-divergence' isModalVisible={open} handleClose={handleClose} width={1000}>
      <Content>
        <Content>
          <Title> {t('title_details_divergence', { ns: namespaces.pages.analysisHours })} </Title>
          <SubTitle> {t('subtitle_details_divergence', { ns: namespaces.pages.analysisHours })} </SubTitle>
        </Content>
        <BoxInfo>
          <BoxLine>
            <Row gutter={[8, 24]} className='row-details'>
              <Col span={24}>
                <ModalDataTable
                  rowKey={(record: any): any => record.periodDate}
                  columns={columns}
                  data={tableDataSource || []}
                  scroll={{ y: 500 }}
                  loading={isTableLoading}
                />
              </Col>
            </Row>
          </BoxLine>
        </BoxInfo>
      </Content>
    </Modal>
  )
}

export default DetailsDivergence
