import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Input } from 'antd'



import Modal from 'components/Modal'
import Button from 'components/Button'
import { RangeValue } from 'rc-picker/lib/interface'

import { Content, Header, Title, SubTitle, Box, ContainerBtn } from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  approveEmployee: (id: number, observation: string) => Promise<void>
  id: number
  intervalDate: RangeValue<moment.Moment>
}

const ApproveEmployee = (props: TProps): JSX.Element => {

  const { open = true, handleClose, approveEmployee, id } = props
  const [textInput, setTextInput] = useState<string>('')

  const { TextArea } = Input

  const { t } = useTranslation('namespaces')

  const handleConfirm = (): void => {
    approveEmployee(id, textInput)
    handleClose()
  }

  return (
    <Modal id='modal-confirmacao' isModalVisible={open} handleClose={handleClose}>
      <Content>
        <Header>
          <Title> {t('approve an employee', { ns: namespaces.pages.analysisHours })} </Title>
          <SubTitle> {t('approve an employee with divergence', { ns: namespaces.pages.analysisHours })} </SubTitle>
        </Header>
        <Box>
          <TextArea rows={4} showCount maxLength={150} value={textInput} onChange={(event): void => setTextInput(event.target.value)} />
          <ContainerBtn>
            <Button
              shape='default'
              id='button-cancel'
              type='ghost'
              label={t('cancel', { ns: namespaces.common })}
              onClick={handleClose}
            />
            <Button
              shape='default'
              id='button-confirm'
              type='primary'
              label={t('approve', { ns: namespaces.pages.analysisHours })}
              onClick={handleConfirm}
            />
          </ContainerBtn>
        </Box>
      </Content>
    </Modal>
  )
}
export default ApproveEmployee
