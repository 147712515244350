import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const HourAnalysis = (props: TProps): JSX.Element => {
  const {
    size = '15',
    color = '#868686'
  } = props

  return (
    <svg width={size} height={size} viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.5714 7.47619V3C13.5714 1.89543 12.676 1 11.5714 1H3C1.89543 1 1 1.89543 1 3V11.5714C1 12.676 1.89543 13.5714 3 13.5714H7.09524' stroke={color} strokeWidth='1.3' />
      <path d='M6.83334 11.6668C6.83334 8.99832 8.99414 6.8335 11.6613 6.8335C14.3349 6.8335 16.5 8.9993 16.5 11.6668C16.5 14.3344 14.3349 16.5002 11.6613 16.5002C8.99414 16.5002 6.83334 14.3353 6.83334 11.6668ZM6.90001 11.6668C6.90001 14.3003 9.0332 16.4335 11.6667 16.4335C14.3002 16.4335 16.4333 14.3003 16.4333 11.6668C16.4333 9.03335 14.3002 6.90016 11.6667 6.90016C9.0332 6.90016 6.90001 9.03335 6.90001 11.6668Z' fill='black' stroke={color} />
      <mask id='path-3-inside-1_147_4061' fill='white'>
        <path d='M11.9334 9H11.1334V12.2L13.9334 13.88L14.3334 13.224L11.9334 11.8V9Z' />
      </mask>
      <path d='M11.9334 9H11.1334V12.2L13.9334 13.88L14.3334 13.224L11.9334 11.8V9Z' fill='black' />
      <path d='M11.9334 9H12.9334V8H11.9334V9ZM11.1334 9V8H10.1334V9H11.1334ZM11.1334 12.2H10.1334V12.7662L10.6189 13.0575L11.1334 12.2ZM13.9334 13.88L13.4189 14.7375L14.2703 15.2483L14.7872 14.4006L13.9334 13.88ZM14.3334 13.224L15.1872 13.7446L15.7141 12.8805L14.8436 12.364L14.3334 13.224ZM11.9334 11.8H10.9334V12.3694L11.4231 12.66L11.9334 11.8ZM11.9334 8H11.1334V10H11.9334V8ZM10.1334 9V12.2H12.1334V9H10.1334ZM10.6189 13.0575L13.4189 14.7375L14.4479 13.0225L11.6479 11.3425L10.6189 13.0575ZM14.7872 14.4006L15.1872 13.7446L13.4796 12.7034L13.0796 13.3594L14.7872 14.4006ZM14.8436 12.364L12.4436 10.94L11.4231 12.66L13.8231 14.084L14.8436 12.364ZM12.9334 11.8V9H10.9334V11.8H12.9334Z' fill={color} mask='url(#path-3-inside-1_147_4061)' />
      <rect x='2.94284' y='3.70514' width='3.27619' height='0.533333' fill='black' stroke={color} strokeWidth='0.533333' />
      <rect x='8.0857' y='2.79059' width='3.27619' height='0.533333' fill='black' stroke={color} strokeWidth='0.533333' />
      <rect x='8.0857' y='4.77155' width='3.27619' height='0.533333' fill='black' stroke={color} strokeWidth='0.533333' />
      <rect x='2.79047' y='8.80964' width='3.58095' height='0.533333' fill='black' stroke={color} strokeWidth='0.533333' />
      <rect x='4.31427' y='10.9433' width='3.65714' height='0.533333' transform='rotate(-90 4.31427 10.9433)' fill='black' stroke={color} strokeWidth='0.533333' />
    </svg>
  )
}

export default HourAnalysis