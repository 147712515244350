import React, { useMemo } from 'react'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { CustomList, GridList, ListItem } from './styles'

type TProps = {
  projects: any[]
}

type TRelationItem = {
  name: string
  costCenter: TCostCenter[]
}

type TCostCenter = {
  id: number
  code: string
}

const RelateContent = (props: TProps): JSX.Element => {
  const { projects } = props
  const { t } = useTranslation('namespaces')
  const data = useMemo(() => {
    const dataValues = [] as any[]
    projects.map((project) => {
      project.costCenters.map((costCenter: TCostCenter) => {
        dataValues.push({
          name: project.name,
          costCenter: costCenter.code,
        })
      })
    })
    return dataValues
  }, [projects])

  const header = (
    <GridList>
      <span>{t('project', { ns: namespaces.pages.employeesRelate })}</span>
      <span>{t('title_select_cost_center', { ns: namespaces.pages.consolidated })}</span>
    </GridList>
  )

  return (
    <>
      {data.length ? (
        <CustomList
          bordered
          size='small'
          header={header}
          dataSource={data}
          renderItem={(item): JSX.Element => {
            const _item = item as TRelationItem
            return (
              <ListItem key={Math.random()}>
                <span>{_item.name}</span>
                <span>{_item.costCenter}</span>
              </ListItem>
            )
          }}
        />
      ) : (
        <Empty
          description={t('not related yet', {
            ns: namespaces.pages.employeesRelate,
          })}
        />
      )}
    </>
  )
}

export default RelateContent
