import React from 'react'
import GlobalStyles from './style'
import Routes from 'routes'
import Providers from 'providers'
import { ConfigProvider } from 'antd'
import { DialogProvider } from './hooks/dialog/context'
import './i18n'
import './App.css'
import { getDetectedLocaleToAntdFormat } from 'utils/languageHelper'

const App = (): JSX.Element => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1890ff',
          borderRadius: 2
        }
      }}
      locale={getDetectedLocaleToAntdFormat()}
    >
      <Providers>
        <DialogProvider>
          <Routes />
        </DialogProvider>
        <GlobalStyles />
      </Providers>
    </ConfigProvider>
  )
}


export default App
