import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const FileView = (props: TProps): JSX.Element => {
  const {
    size = '16',
    color = '#868686'
  } = props

  return (
    <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.6667 6.85715V4.57144C11.6671 4.49623 11.6524 4.42168 11.6234 4.35206C11.5943 4.28244 11.5516 4.21912 11.4975 4.16572L7.41417 0.165724C7.35966 0.112763 7.29502 0.0708629 7.22395 0.042426C7.15287 0.0139891 7.07677 -0.000425245 7 9.55102e-06H1.16667C0.857247 9.55102e-06 0.560501 0.120417 0.341709 0.334744C0.122916 0.549072 0 0.839762 0 1.14287V14.8571C0 15.1602 0.122916 15.4509 0.341709 15.6653C0.560501 15.8796 0.857247 16 1.16667 16H10.5C10.8094 16 11.1062 15.8796 11.325 15.6653C11.5437 15.4509 11.6667 15.1602 11.6667 14.8571V13.7143H10.5V14.8571H1.16667V1.14287H5.83333V4.57144C5.83333 4.87454 5.95625 5.16523 6.17504 5.37956C6.39383 5.59388 6.69058 5.71429 7 5.71429H10.5V6.85715H11.6667ZM7 4.57144V1.37715L10.2608 4.57144H7Z' fill={color} />
      <path d='M11.9935 13.2115C12.6577 13.2114 13.3028 12.9936 13.8259 12.5928L15.4709 14.2041L16 13.6858L14.3551 12.0745C14.7644 11.562 14.9869 10.93 14.9871 10.2792C14.9871 8.66242 13.6441 7.34692 11.9935 7.34692C10.343 7.34692 9 8.66242 9 10.2792C9 11.896 10.343 13.2115 11.9935 13.2115ZM11.9935 8.07999C13.2317 8.07999 14.2387 9.06634 14.2387 10.2792C14.2387 11.4921 13.2317 12.4784 11.9935 12.4784C10.7553 12.4784 9.74838 11.4921 9.74838 10.2792C9.74838 9.06634 10.7553 8.07999 11.9935 8.07999Z' fill={color} />
    </svg>
  )
}

export default FileView