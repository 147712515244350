import React, { useState } from 'react'
import useRest, { DeveloperOptions, ResponseWrapper, ErrorWrapper } from 'hooks/useRest'

export type TTimeAnalysisResponse = TCommonResponse & {
  content: {
    timeAnalysis: TIndividualTimeAnalysis[]
    jiras: TJira[]
    pageable: TPageableResponse
  }
}

export type TIndividualTimeAnalysis = {
  key: React.Key
  employeeId: number
  employeeName: string
  employeeEmail: string
  hoursAtPoint: string
  hoursAtWorkedJira: THoursAtWorkedJira[]
  timeDifference: string
  status: string
  observation: string | null
}

export type THoursAtWorkedJira = {
  companyJiraId: number
  companyJiraName: string
  hoursAtWorked: string
}

export type TJira = {
  id: number
  name: string
  clients: never[]
}

export type TDescription = {
  employeeId: number
  employeeName: string
}

export type TPj = {
  employeeId: number | null
  employeeName: string | null
  employeeEmail: string | null
  employeeAdmissionDate: string | null
}
export type TDescriptionData = {
  employeeID: null | undefined | number,
  employeeName: null | undefined | string,
  employeeEmail: null | undefined | number,
  date: null | undefined | string,
  jiraCompanyId: null | undefined | number,
  jiraCompany: null | undefined | string,
  clientId: null | undefined | number,
  client: null | undefined | string,
  projectId: null | undefined | number,
  project: null | undefined | string,
  costCenterId: null | undefined | number,
  costCenter: null | undefined | string,
  task: null | undefined | string,
  worklogDescription: null | undefined | string,
  taskTotalDisplay: null | undefined | string,
  taskTotal: null | undefined | number,
}

export type THourAnalysisParams = {
  startDate?: string
  endDate?: string
  onlyDivergence?: boolean
  pageSize: number
  page: number
  name?: string
  approved?: boolean
  lessThan?: number
  biggerThan?: number
}

export type TDescriptionAnalysisParams = {
  startDate?: string
  endDate?: string
  pageSize: number
  page: number
  collaboratorName?: string
}

export type TDescriptionAnalysisDetailsParams = {
  startDate?: string
  endDate?: string
  employeeID?: string
}

export type THourAnalysisWithoutPaginationParams = Omit<THourAnalysisParams, 'pageSize' | 'page'>

export type TCollaboratorsWithStatus = {
  key?: number
  id: number
  name: string
  email: string | undefined
  status: string
}
export type TCollaboratorsDescription = {
  employeeId: number,
  key?: number
  name: string
  email: string | undefined
  status: string
}

export type TNotificationTimeAnalysisParams = {
  startDate: string
  endDate: string
  correctionLimit: string | undefined
  responsibleList: string[]
  collaboratorList: TCollaboratorsWithStatus[]
}
export type TNotificationDescriptionAnalysisParams = {
  startDate: string
  endDate: string
  correctionLimit: string | undefined
  responsibleList: string[]
  collaboratorSelectList: TCollaboratorsDescription[] | null
  collaboratorList: number[] | null
}

export type TNotificationTimeAnalysisParamsNoUsers = {
  startDate: string
  endDate: string
  correctionLimit: string | undefined
  responsibleList: string[]
}

export type TApproveEmployeesParams = {
  approvalList: ApprovedEmployee[]
  endPeriod: string
  startPeriod: string
}

type ApprovedEmployee = {
  employeeId: number
  note: string
}

type TNotificationTimeAnalysisQueueResponse = TCommonResponse & {
  content: {
    requestId: string
  }
}

type TTimeAnalysisExportResponse = TCommonResponse & {
  content: {
    timeAnalysisInBase64File: string
  }
}

export type TAnalysisDetailsParams = {
  startDate: string
  endDate: string
  employeeID: number
}

export type TDivergenceDTO = {
  divergence: string
  employeeId: number
  periodDate: string
  workedJira: string
  workedPoint: string
}

export type TAnalysisDetailsResponse = {
  content: {
    divergenceList: Array<TDivergenceDTO>
  },
  detailMessage: string
  error: boolean | null
  message: string
  requestId: string | null
  status: number
  timestamp: string
}

export type TJobDescriptionAnalysisRecord = {
  employeeId: number
  employeeName: string
}

export type TJobDescriptionAnalysisDetailRecord = {
  employeeID: number,
  employeeName: string,
  employeeEmail: string,
  date: string,
  jiraCompanyId: number,
  jiraCompany: string,
  clientId: number,
  client: string,
  projectId: number,
  project: string,
  costCenterId: number,
  costCenter: string,
  task: string,
  taskTotalDisplay: string,
  taskTotal: number,
  worklogDescription: string
}

export type TDescriptionAnalysisResponse = TCommonResponse & {
  content: {
    jobDescriptionAnalysis: Array<TJobDescriptionAnalysisRecord>
    pageable: TPageableResponse
  }
}

export type TDescriptionAnalysisDetailResponse = TCommonResponse & {
  content: {
    worklogAnalysis: Array<TJobDescriptionAnalysisDetailRecord>
    pageable: TPageableResponse
  }
}


const useTimeAnalysis = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()


  const requestAnalysis = async (
    params: THourAnalysisParams,
    triggerLoading = true
  ): Promise<TTimeAnalysisResponse> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/time-check/time-analysis'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TTimeAnalysisResponse
    return response as TTimeAnalysisResponse
  }

  const requestDescriptionAnalysisOld = async (
    params: TDescriptionAnalysisParams,
  ): Promise<TDescriptionAnalysisResponse> => {
    const url = '/job-description-analysis/description-analysis'
    try {
      const response = await rest.get(url, params)
      if (!response) return {} as TDescriptionAnalysisResponse
      return response
    } catch (error) {
      console.log(error)
      return {} as TDescriptionAnalysisResponse
    }
  }

  const requestDescriptionAnalysis = async ({
    params,
    developerOptions,
  }: {
    params: TDescriptionAnalysisParams,
    developerOptions: DeveloperOptions
  }): Promise<ResponseWrapper<TDescriptionAnalysisResponse>> => {
    const response = await rest.get2({
      url: '/job-description-analysis/description-analysis',
      queryStringData: params
    })

    if (developerOptions?.returnResponseRef) {
      return response as ResponseWrapper<TDescriptionAnalysisResponse>
    }

    return response.data
  }

  const requestDescriptionDetails = async ({
    params,
    developerOptions,
  }: {
    params: TDescriptionAnalysisDetailsParams,
    developerOptions: DeveloperOptions
  }): Promise<ResponseWrapper<TDescriptionAnalysisDetailResponse>> => {
    if (!('employeeID' in params)) {
      throw new Error('missing employeeID')
    }

    const response = await rest.get2({
      url: `/job-description-analysis/description-analysis/${params.employeeID}/details`,
      queryStringData: {
        startDate: params.startDate,
        endDate: params.endDate,
      }
    })

    if (developerOptions?.returnResponseRef) {
      return response as ResponseWrapper<TDescriptionAnalysisDetailResponse>
    }

    return response.data
  }


  const requestDescriptionDetailsOld = async (
    params: any,
    triggerLoading = true,
    employeeID: string
  ): Promise<TDescriptionData[]> => {
    if (triggerLoading) setIsLoading(true)
    const url = `/job-description-analysis/description-analysis/${employeeID}/details`
    try {
      const response = await rest.get(url, params)
      if (triggerLoading) setIsLoading(false)
      if (!response) return {} as TDescriptionData[]
      return response.content.worklogAnalysis as TDescriptionData[]
    } catch (error) {
      console.log(error)
      return {} as TDescriptionData[]
    }
  }
  const postNotificationDescriptionAnalysisEmail = async (
    params: TNotificationDescriptionAnalysisParams,
    triggerLoading = true
  ): Promise<TNotificationTimeAnalysisQueueResponse> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/notification/mail/worklog-analysis'
    const response = await rest.post(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TNotificationTimeAnalysisQueueResponse
    return response as TNotificationTimeAnalysisQueueResponse
  }

  const postNotificationTimeAnalysisEmail = async (
    params: TNotificationTimeAnalysisParams,
    triggerLoading = true
  ): Promise<TNotificationTimeAnalysisQueueResponse> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/notification/mail/time-analysis'
    const response = await rest.post(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TNotificationTimeAnalysisQueueResponse
    return response as TNotificationTimeAnalysisQueueResponse
  }

  const postNotificationTimeAnalysisEmailAll = async (
    params: TNotificationTimeAnalysisParamsNoUsers,
    triggerLoading = true
  ): Promise<TNotificationTimeAnalysisQueueResponse> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/notification/mail/time-analysis-all'
    const response = await rest.post(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TNotificationTimeAnalysisQueueResponse
    return response as TNotificationTimeAnalysisQueueResponse
  }

  const requestAnalysisToExport = async (
    params: THourAnalysisWithoutPaginationParams,
    triggerLoading = true
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/time-check/time-analysis/export'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TTimeAnalysisExportResponse
    // return response as TTimeAnalysisExportResponse
    return response
  }

  const approveEmployees = async (
    params: TApproveEmployeesParams,
    triggerLoading = true
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/time-check/approve'
    const response = await rest.post(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TTimeAnalysisExportResponse
    return response
  }

  const disapproveEmployees = async (
    params: TApproveEmployeesParams,
    triggerLoading = true
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/time-check/disapprove'
    const response = await rest.post(url, params)
    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TTimeAnalysisExportResponse
    return response
  }

  const timeAnalysisDetails = async (
    params: TAnalysisDetailsParams,
    triggerLoading: boolean = true
  ): Promise<any> => {
    if (!params.employeeID) throw new Error('employeeID is mandatory')

    const url = `/time-check/time-analysis/${params.employeeID}/details`

    if (triggerLoading) setIsLoading(true)

    const response = await rest.get(url, params)

    if (triggerLoading) setIsLoading(false)
    if (!response) return {} as TAnalysisDetailsResponse
    return response
  }

  return {
    isLoading,
    requestAnalysis,
    postNotificationTimeAnalysisEmail,
    postNotificationTimeAnalysisEmailAll,
    requestAnalysisToExport,
    approveEmployees,
    disapproveEmployees,
    timeAnalysisDetails,
    requestDescriptionAnalysis,
    requestDescriptionAnalysisOld,
    requestDescriptionDetails,
    requestDescriptionDetailsOld,
    postNotificationDescriptionAnalysisEmail,
  }
}

export type { ResponseWrapper, ErrorWrapper } from 'hooks/useRest'
export { isRequestLibraryError } from 'hooks/useRest'
export default useTimeAnalysis