import React from 'react'
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import RouteWrapper from './Route'
import Home from 'pages/Home'
import EmployeesRelate from 'pages/Employees/Relate'
import EmailConfig from 'pages/Config/EmailConfig'
import Login from 'pages/Login'
import AnalysisHours from 'pages/AnalysisHours'
import AnalysisDescription from 'pages/DescriptionAnalysis/index'
import ImportFiles from 'pages/Files/ImportFiles'
import ConsultFiles from 'pages/Files/ConsultFiles'
import Consolidated from 'pages/Consolidated'
import Period from 'pages/Config/Period'
import CostCenterRelate from 'pages/CostCenter/Relate'
import EmployeesListPj from 'pages/Employees/listPj'

const Routes = (): JSX.Element => {
  return (
    <ReactRouterRoutes>
      <Route
        path='/'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Home />
          </RouteWrapper>
        )}
      />

      <Route
        path='/login'
        element={(
          <RouteWrapper>
            <Login />
          </RouteWrapper>
        )}
      />

      {/* <Route
        path='/employees'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Employees />
          </RouteWrapper>
        )}
      /> */}
      {/* <Route
        path='/employees/update'
        element={(
          <RouteWrapper
            isPrivate
          >
            <EmployeesUpdate />
          </RouteWrapper>
        )}
      /> */}

      <Route
        path='/employees/relate'
        element={(
          <RouteWrapper
            isPrivate
          >
            <EmployeesRelate />
          </RouteWrapper>
        )}
      />

      <Route
        path='/costcenter/relate'
        element={(
          <RouteWrapper
            isPrivate
          >
            <CostCenterRelate />
          </RouteWrapper>
        )}
      />

      <Route
        path='/employees/listPj'
        element={(
          <RouteWrapper
            isPrivate
          >
            <EmployeesListPj />
          </RouteWrapper>
        )}
      />

      {/* <Route
        path='/settings'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Config />
          </RouteWrapper>
        )}
      /> */}


      <Route
        path='/settings/email'
        element={(
          <RouteWrapper
            isPrivate
          >
            <EmailConfig />
          </RouteWrapper>
        )}
      />

      <Route
        path='/settings/period'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Period />
          </RouteWrapper>
        )}
      />

      <Route
        path='/analysis-hours'
        element={(
          <RouteWrapper
            isPrivate
          >
            <AnalysisHours />
          </RouteWrapper>
        )}
      />

      <Route
        path='/analysis-description'
        element={(
          <RouteWrapper
            isPrivate
          >
            <AnalysisDescription />
          </RouteWrapper>
        )}
      />

      <Route
        path='/consolidated'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Consolidated />
          </RouteWrapper>
        )}
      />

      <Route
        path='/import-file'
        element={(
          <RouteWrapper
            isPrivate
          >
            <ImportFiles />
          </RouteWrapper>
        )}
      />

      <Route
        path='/view-list-files'
        element={(
          <RouteWrapper
            isPrivate
          >
            <ConsultFiles />
          </RouteWrapper>
        )}
      />

      {/* <Route
        path='/view-file'
        element={(
          <RouteWrapper
            isPrivate
          >
            <ViewFile />
          </RouteWrapper>
        )}
      /> */}
    </ReactRouterRoutes>
  )
}

export default Routes
