import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Main = styled.div`
  width: 100%;
  background-color: ${({ theme }): any => theme.colors.WHITE};
  padding-top: 60px;
`

export const Content = styled.article`
  padding-left: 250px;
  height: calc(100vh - 60px);

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
    padding-left: 82px;
  }
`