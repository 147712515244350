import styled from 'styled-components'
import { Form } from 'antd'

export const FormBackground = styled.div`
    width: 100%;
    height: auto;
    background-color: #F7F8FA;
    display: flex;
    justify-content: space-between;
`

export const FormBox = styled.div`
    margin: 20px;
    width: 550px;
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 4px;
`
export const FormSubTitle = styled.div`
    color: ${({ theme }): any => theme.colors.BLACK};
    font-weight: 400;
    font-size: 14px;
`

export const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    label {
        color: ${({ theme }): any => theme.colors.BLACK};
        font-weight: 400;
        font-size: 14px;
    }

    div {
        width: 50%;
        text-align: left;
        div {
            width: 100%;
        }
    }
`

export const InputStyle = styled.div`
  .teste {
    width: 150%
  }
  .ant-form-item-control-input {
    margin-left: 50px;
    width: 150%
  }

  .ant-form-item-explain-error {
    padding-left: 12%;
  }
`