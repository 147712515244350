import { Form, FormInstance, Input, Spin, Row, Col, Button, notification } from 'antd'
import { DateRangePickerField } from 'components'
import moment, { Moment } from 'moment'
import React, { useState, useContext, useEffect, } from 'react'
import * as XLSX from 'xlsx'
import useConsolidatedReport from 'repositories/useConsolidatedReport'
import { RangeValue } from 'rc-picker/lib/interface'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { downloadBase64File, convertFileExcelToJson, convertBase64ToFile } from 'utils/helpers'
import { DialogContext } from 'hooks/dialog/context'
import ModalDownloadingReport from '../Modal/ModalDownloadingReport'
import useSocket from 'hooks/useSocket'
import { DownloadIconWhite, SessionBodyFormInputs } from '../styles'
import { DownloadOutlined } from '@ant-design/icons'

type TProps = {
  employeeOrCustomer: string;
  form: FormInstance;
  handleWaitingRequest: (flagRequest: boolean) => void;
}

export const ByEmployee: React.FC<TProps> = ({ form, handleWaitingRequest }) => {
  const reportRepository = useConsolidatedReport()
  const [hasDate, setHasDate] = useState<boolean>(false)
  const [hasValidName, setHasValidName] = useState<boolean>(false)
  const { t } = useTranslation()
  const { createDialog } = useContext(DialogContext)
  const socket = useSocket()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    handleWaitingRequest(true)
    setLoading(false)
    handleWaitingRequest(false)
  }, [])

  const handleSelectDate: React.Dispatch<RangeValue<moment.Moment>> = (dateArray: RangeValue<moment.Moment>) => {
    if (!dateArray) {
      setHasDate(false)
      return
    }
    const values = form.getFieldsValue()
    values['period'] = dateArray
    form.setFieldsValue(values)
    setHasDate(true)

  }

  const handleExport = (): void => {
    setLoading(true)
    handleWaitingRequest(true)

    const stompClient = socket.socketConnect()
    stompClient.activate()

    stompClient.onConnect = async (frame): Promise<void> => {
      form.validateFields().then(async (value) => {
        const [startDate, endDate] = value.period.map((date: Moment) => moment(date).format('YYYY-MM-DD'))
        const params = {
          startDate,
          endDate,
          employeeName: value.employee,
        }

        const response = await reportRepository.getApprovedTime(params)

        if (!response) {
          stompClient.deactivate()
          return
        }

        createDialog({
          id: 'importing-files-modal',
          open: true,
          Component: ModalDownloadingReport,
          props: {
            title: 'Relatório em processamento!'
          }
        })

        const subscription = stompClient.subscribe(`/topic/messages/${response.content.requestId}`, (messageOutput: any) => {
          const jsonParse = JSON.parse(messageOutput.body)

          const resultConvertFile = convertBase64ToFile(
            `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${jsonParse.body.content.reportBase64File}`,
            'nameTargetFile'
          )

          // convert file to json
          const fileReader = new FileReader()
          const resultConvertJson = new Promise((resolve, reject) => {
            fileReader.onload = (evt): void => {
              const arrayBufferString = evt?.target?.result
              const workbookTarget = XLSX.read(arrayBufferString, { type: 'binary' })
              const workSheetName = workbookTarget.SheetNames[0]
              const workSheetTarget = workbookTarget.Sheets[workSheetName]
              const dataJson = XLSX.utils.sheet_to_csv(workSheetTarget, {})
              resolve(convertFileExcelToJson(dataJson))
            }
            fileReader.onerror = (error): void => reject(error)
          })
          fileReader.readAsBinaryString(resultConvertFile)

          resultConvertJson.then((convertJson: any): void => {
            if (convertJson && convertJson.length <= 1) {
              notification.warning({
                message: t('no results were found for generating the file', { ns: namespaces.pages.analysisHours }),
                placement: 'topRight',
              })
              subscription.unsubscribe()
              setLoading(false)
              handleWaitingRequest(false)
              stompClient.deactivate()
              return
            }

            downloadBase64File(
              'relatorio_de_consolidação.xlsx',
              jsonParse.body.content.reportBase64File,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
            notification.success({
              message: t('the file was downloaded successfully', { ns: namespaces.pages.analysisHours }),
              placement: 'topRight',
            })
            subscription.unsubscribe()
            setLoading(false)
            handleWaitingRequest(false)
            stompClient.deactivate()
          })
        })
      }).catch((err) => {
        setLoading(false)
        handleWaitingRequest(false)
        console.error(err)
        stompClient.deactivate()
      })
    }

    stompClient.onStompError = (frame) => {
      console.log('websocketError', frame)
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('websocketError', { ns: namespaces.common })
      })
      stompClient.deactivate()
    }
  }

  const handleNameChange = (name: string): void => {
    if (!name) {
      setHasValidName(false)
      return
    }
    else if (name.length < 3) {
      setHasValidName(false)
      return
    }
    setHasValidName(true)
  }

  return (
    <Spin spinning={loading}>
      <SessionBodyFormInputs>
        <Row className='prop-inputs' gutter={[8, 24]}>
          <Col span={7}>
            <span className='span-input'>
              {t('title_period', { ns: namespaces.pages.consolidated })}
            </span>
          </Col>
          <Col span={17}>
            <Form.Item
              name='period'
            >
              <DateRangePickerField onChangeValue={handleSelectDate} />
            </Form.Item>
          </Col>
        </Row>

        <Row className='prop-inputs' gutter={[8, 24]}>
          <Col span={7}>
            <span className='span-input'>
              {t('title_search_employee', { ns: namespaces.pages.consolidated })}
            </span>
          </Col>
          <Col span={17}>
            <Form.Item
              name='employee'
            >
              <Input
                placeholder={t('search employee', { ns: namespaces.pages.consolidated })}
                onChange={(event): void => handleNameChange(event.target.value)}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        {
          !hasDate ? (
            <Row gutter={[8, 24]}>
              <Col span={7} />
              <Col span={17}>
                <Button
                  size='large'
                  id='disabled-export-button'
                  className='export-icon border-buttons-default'
                  disabled
                >
                  <DownloadOutlined size={36} />
                  {t('export_file', { ns: namespaces.pages.consolidated })}
                </Button>
              </Col>
            </Row>
          )
            : (
              <Row gutter={[8, 24]}>
                <Col span={7} />
                <Col span={17}>
                  <Button
                    size='large'
                    id='report-export-button'
                    className='export-icon button-export-consolidated border-buttons-default'
                    onClick={handleExport}
                    disabled={loading}
                  >
                    <DownloadIconWhite size={36} />
                    {t('export_file', { ns: namespaces.pages.consolidated })}
                  </Button>
                </Col>
              </Row>
            )
        }
      </SessionBodyFormInputs>
    </Spin>
  )
}
