enum Colors {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  WEAK_WHITE ='#f7f8fa',
  GRAY='#cecece',
  BLACK_PEARL='#001529',
  YELLOW = '#ffc90a',
  BLUE_DARK ='#001529',
  PRIMARY_BLUE='#0D5FD9',
  RED = '#FB0000',
  BLUE = '#0CACDA',
  GREEN = '#A5CD39',
  DARK_GREEN = '#4BB543',
  THEME = 'linear-gradient(90deg, #A5CD39 0%, #0CACDA 100%)',
  LIGHT_GRAY = '#E8E8E8;',
  MEDIUM_GRAY = '#868686',
  DARK_BLUE = '#001529',
}

enum BreakPoints {
  SMALL_SCREEN = '500px',
  MEDIUM_SCREEN = '1187px',
  BS_MD = '767px',
  BS_LG = '992px'
}

type TTheme = {
  colors: typeof Colors
  breakPoints: typeof BreakPoints
}

export const theme: TTheme = {
  colors: Colors,
  breakPoints: BreakPoints
}
