import React from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import Modal from 'components/Modal'
import Button from 'components/Button'

import { Content, HeaderFile, Title, BoxFile, ContainerModal, ContainerModalBtns } from './styles'

type TProps = {
  open: boolean
  handleClose: () => void
  reopenPeriod: () => void
  text: string
}

const ReopenPeriodModal = ({ open, handleClose, reopenPeriod, text }: TProps): JSX.Element => {

  const { t } = useTranslation('namespaces')

  const handleConfirm = (): void => {
    reopenPeriod()
    handleClose()
  }

  return (
    <Modal id='reopen-period-confirmation-modal' isModalVisible={open} handleClose={handleClose}>
      <Content>
        <HeaderFile>
          <Title> {text} </Title>
        </HeaderFile>
        <BoxFile>
          <ContainerModal>
            <ContainerModalBtns>
              <Button
                shape='default'
                id='button-cancel'
                type='primary'
                label={t('cancel', { ns: namespaces.common })}
                onClick={handleClose}

              />
            </ContainerModalBtns>
            <ContainerModalBtns>
              <Button
                shape='default'
                id='button-confirm'
                type='dashed'
                label={t('confirm', { ns: namespaces.common })}
                onClick={handleConfirm}
              />
            </ContainerModalBtns>
          </ContainerModal>
        </BoxFile>
      </Content>
    </Modal>
  )
}
export default ReopenPeriodModal

