import React from 'react'
import DatePicker from 'components/DatePicker'
import localePTBR from 'antd/es/date-picker/locale/pt_BR'
import localeENUS from 'antd/es/date-picker/locale/en_US'
import { RangeValue } from 'rc-picker/lib/interface'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { Content } from './styles'

type TProps = {
  onChangeValue: React.Dispatch<RangeValue<moment.Moment>>
}

const DateRangePickerField = (props: TProps): JSX.Element => {
  const { onChangeValue } = props
  const { RangePicker } = DatePicker
  const { t } = useTranslation('namespaces')

  const getLanguage = (language: string) => {
    switch(language){
      case 'pt_BR': return localePTBR
      case 'en_US': return localeENUS
      default: return localePTBR
    }
  }

  const getFormat = (language: string): string => {
    switch(language){
      case 'pt_BR': return 'DD/MM/YYYY'
      case 'en_US': return 'MM/DD/YYYY'
      default: return 'DD/MM/YYYY'
    }
  }

  return (
    <Content>
      <RangePicker
        locale={getLanguage(t('datePicker', { ns: namespaces.common }))}
        format={getFormat(t('datePicker', { ns: namespaces.common }))}
        onChange={onChangeValue}
        placeholder={[
          t('start date', { ns: namespaces.common }),
          t('end date', { ns: namespaces.common })
        ]}
      />
    </Content>
  )
}

export default DateRangePickerField
