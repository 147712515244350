import styled from 'styled-components'
import { Checkbox, Form } from 'antd'

export const FormInput = styled(Checkbox)`
    span{
        border-color: ${({ theme }): any => theme.colors.BLACK};
        border-style: solid;
        border-radius: 3px;
        border-width: 1.3px;
    }
`

export const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    label {
        color: ${({ theme }): any => theme.colors.BLACK};
        font-weight: 400;
        font-size: 14px;
    }
`