import React from 'react'
import { TriggerEventHandler } from 'rc-table/lib/interface'

import { Icon } from './styles'

type TProps = {
  expanded: string
  onExpand: TriggerEventHandler<object>
  record: object
}

const ExpandIcon = (props: TProps): JSX.Element => {
  const {
    expanded = 'false',
    onExpand,
    record
  } = props

  const onClickExpand = () => (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    onExpand(record, e)
  }

  return (
    <Icon
      expanded={expanded}
      onClick={onClickExpand()}
    />
  )
}

export default ExpandIcon