import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 30px;
`

export const Title = styled.h1`
    color: ${({ theme }): any => theme.colors.DARK_BLUE};
    font-size: 32px;
    font-weight: 400;
`

export const Text = styled.span`
    color: ${({ theme }): any => theme.colors.DARK_BLUE};
    font-size: 21px;
    font-weight: 300;
    margin-top: 30px;
`