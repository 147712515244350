import styled from 'styled-components'
import { CheckOutlined, CloseOutlined, FileExclamationOutlined } from '@ant-design/icons'


export const Content = styled.div`
  padding: 30px 25px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
`

export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 20px;
`

export const Footer = styled.div`
  display: grid;
  justify-content: flex-end;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
    flex-direction: column;
  }
`

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;

  button {
    width: auto;
    height: 30px;
  }
`

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
`
export const FilterAnalysis = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .form-position-inputs {
    display: flex;
    width: 35%;
  }
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
`

export const ButtonBox = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  margin-left: 10px;
  button, div {
    padding-top: 0;
  }
`

export const ApproveIcon = styled(CheckOutlined)`
  color: ${({ theme }): any => theme.colors.DARK_GREEN};
`

export const DisapproveIcon = styled(CloseOutlined)`
  color: ${({ theme }): any => theme.colors.RED};
`

export const SubRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FilterButtonBox = styled.div`
  width: 96px;
  button {
    margin-top: 2px;
    height: 33px;
  }

  div {
    padding: 0;
  }
`

export const DatePickerBox = styled.div`
  padding-bottom: 21px;
  padding-right: 5px;
  font-size: 14px;
  color: #868686;
`

export const NameInputBox = styled.div`
  width: 40%;
  height: 32px;
  padding-bottom: 75px;
  padding-right: 5px;
  font-size: 14px;
  color: #868686;

  @media screen and (max-width: 1366px) {
    width: 26%;
  }
`

export const NameInputBoxOnly = styled.div`
  width: 79%;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 75px;
  font-size: 14px;
  color: #868686;

  @media screen and (max-width: 1366px) {
    width: 65%;
  }
`

export const DiferenceInputBox = styled.div`
  width: 100%;
  height: 60px;
  padding-bottom: 75px;
  padding-right: 7.5px;
  font-size: 14px;
  color: #868686;

  .ant-input-number {
    width: 100%;
  }
`
export const DiferenceSmallerInputBox = styled.div`
  width: 30%;
  height: 32px;
  padding-right: 10px;
`
export const DiferenceLabelBox = styled.div`
width: 50%;
height: 32px;
`

export const CellDescription = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ActionsTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

export const DetailsIcon = styled(FileExclamationOutlined)`
  color: ${({ theme }): any => theme.colors.YELLOW};
`