import React, { useMemo } from 'react'
import { Dropdown, Button, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'

type TMenuItem = {
  key: number
  icon?: JSX.Element
  title: string
  disabled?: boolean
  action(): void
}

type TProps = {
  title: string
  disabled?: boolean
  items: TMenuItem[]
}

const ButtonList = (props: TProps): JSX.Element => {
  const {
    title,
    disabled = false,
    items
  } = props

  const menuItems = useMemo(() => {
    return (
      <Menu
        key={Math.random()}
        items={
          items.map((item) => {
            return ({
              key: item.key,
              icon: item.icon,
              disabled: item.disabled,
              label: item.title,
              title: item.title,
              onClick: item.action,
            })
          })
        }
      />
    )
  }, [items])

  return (
    <Dropdown disabled={disabled} overlay={menuItems}>
      <Button>
        {title}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default ButtonList