import styled from 'styled-components'

import { Input, Form } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

export const IconInfo = styled(InfoCircleOutlined)`
    color: ${({ theme }): any => theme.colors.BLACK};
`
export const FormInput = styled(Input)`
    border-color: ${({ theme }): any => theme.colors.BLACK};
    border-style: solid;
    border-radius: 3px;
    border-width: 1.3px;
    padding-right: 0.2rem;
`

export const FormInputPassword = styled(Input.Password)`
    border-color: ${({ theme }): any => theme.colors.BLACK};
    border-style: solid;
    border-radius: 3px;
    border-width: 1.3px;
`

type TFormItem = {
  hidden: boolean
}

export const FormItem = styled(Form.Item) <TFormItem>`
    display: flex;
    justify-content: space-between;

    ${({ hidden }) => hidden && `
        display: none;
    `}

    label {
        color: ${({ theme }): any => theme.colors.BLACK};
        font-weight: 400;
        font-size: 14px;
        margin-right: 10rem;
    }
`