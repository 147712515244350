import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import AuthLayout from 'components/Layout'

type TProps = {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: TProps): JSX.Element => <>{children}</>

type TPrivateRouteProps = {
  isPrivate?: boolean
  children: React.ReactNode
}

const Wrapper = (props: TPrivateRouteProps): JSX.Element => {
  const {
    children,
    isPrivate = false,
    ...remainingProps
  } = props

  const { isSignedIn, hasToken } = useAuth()
  if (!isSignedIn && !hasToken() && isPrivate) return <Navigate to='/login' />

  const Layout = isSignedIn && isPrivate ? AuthLayout : DefaultLayout

  return (
    <>
      <Layout>
        {children}
      </Layout>
    </>
  )
}

export default Wrapper
