import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const LogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
`

export const Header = styled.div`
  margin-bottom: 50px;
  text-align: center;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
`

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    width: 330px;
  }
`
export const LogBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  li, p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    width: 100%;
    padding-bottom: 5px;
  }

  p {
    text-align: center;
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`
