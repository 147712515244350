import { Form, Row, Col } from 'antd'
import { Button, } from 'components'
import { namespaces } from 'i18n/i18n.constants'

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Content, Header, Title, SubTitle, BodyForm, SessionBodyFormInputs } from './styles'

import { useForm } from 'antd/lib/form/Form'
import { ByEmployee } from './SubForms/ByEmployee'
import { ByClient } from './SubForms/ByClient'

const Consolidated = (): JSX.Element => {
  const { t } = useTranslation()
  const [form] = useForm()
  const [employeeOrCustomer, setEmployeeOrCustomer] = useState<'employee' | 'customer'>('customer')
  const [waitingRequest, setWaitingRequest] = useState<boolean>(false)
  const [opacityRequest, setOpacityRequest] = useState<number>(1)

  const handleChangeTab = (tab: 'employee' | 'customer'): void => {
    form.resetFields()
    setEmployeeOrCustomer(tab)
  }

  const handleWaitingRequest = (flagRequest: boolean): void => {
    setWaitingRequest(flagRequest)

    if (flagRequest)
      setOpacityRequest(0.3)
    else
      setOpacityRequest(1)
  }

  return (
    <Content>
      <Header>
        <Title>
          {t('title', { ns: namespaces.pages.consolidated })}
        </Title>
        <SubTitle>
          {t('subtitle', { ns: namespaces.pages.consolidated })}
        </SubTitle>

        <SessionBodyFormInputs>
          <Row gutter={[8, 24]} className='space-grid'>
            <Col span={7}>
              <span className='span-grid-name' style={{ opacity: opacityRequest }}>
                {t('type_export', { ns: namespaces.pages.consolidated })}
              </span>
            </Col>

            <Col span={17} className='space-button-grid'>
              <div>
                <Button
                  id='button-with-disagreements'
                  type={employeeOrCustomer === 'customer' ? 'primary' : 'ghost'}
                  label={t('customer', { ns: namespaces.pages.consolidated, count: 1 })}
                  onClick={(): void => handleChangeTab('customer')}
                  shape='round'
                  disabled={waitingRequest}
                />
              </div>
              <div>
                <Button
                  id='button-with-disagreements'
                  type={employeeOrCustomer === 'employee' ? 'primary' : 'ghost'}
                  label={t('employee', { ns: namespaces.pages.consolidated })}
                  onClick={(): void => handleChangeTab('employee')}
                  shape='round'
                  disabled={waitingRequest}
                />
              </div>
            </Col>
          </Row>
        </SessionBodyFormInputs>

        <BodyForm>
          <Form
            layout='horizontal'
            form={form}
          >
            {employeeOrCustomer === 'employee' ? (
              <ByEmployee form={form} employeeOrCustomer={employeeOrCustomer} handleWaitingRequest={handleWaitingRequest} />) : (

              <ByClient form={form} employeeOrCustomer={employeeOrCustomer} handleWaitingRequest={handleWaitingRequest} />
            )}
          </Form>
        </BodyForm>
      </Header>
    </Content>
  )
}

export default Consolidated
