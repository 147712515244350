import { useAuth } from 'hooks/useAuth'
import { getApiErroMessage } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { namespaces } from 'i18n/i18n.constants'
import { notification } from 'antd'

enum ApiErrorCodes {
  INVALID_TOKEN = 401
}

export enum ApiErrorMessages {
  INVALID_TOKEN = 'Auth token is not recognized',
  INVALID_CREDENTIALS = 'Invalid email or password',
  ZIP_CODE_NOT_FOUND = 'ZipCode not found',
  EXPIRED_TOKEN = 'Expired token',
  INVALID_USER_TOKEN = 'Invalid token',
  INACTIVE_USER = 'User not activated',
  COLLABORATOR_NOT_FOUND = 'Unable to find a Collaborator',
  APPROVED_FILE = 'Some data is already approved',
  EMAIL_ALREADY_REGISTERED = 'Email already registered',
}

const useErrorHandler = () => {
  const { removeUserData, isSignedIn } = useAuth()
  const { t } = useTranslation('namespaces')

  const handleApiError = (statusText: string): void => {
    const message = getApiErroMessage(statusText, t)
    if (message === '') return
    notification.error({
      message,
      placement: 'topRight',
    })
  }

  const handleGenericError = (): void => {
    const message = t('default', { ns: namespaces.errors })
    notification.error({
      message,
      placement: 'topRight',
    })
  }

  const handleInvalidTokenError = (): void => {
    const message = t('session closed', { ns: namespaces.errors })
    notification.error({
      message,
      placement: 'topRight',
    })
  }

  const handleApiErrorDesambiguation = (response: AxiosResponse): void => {
    if (response.status === ApiErrorCodes.INVALID_TOKEN && isSignedIn) {
      removeUserData()
      handleInvalidTokenError()
      return
    }
    if (response.data.detailMessage === ApiErrorMessages.COLLABORATOR_NOT_FOUND)
      return handleApiError(response.data.detailMessage)

    if (response.data.detailMessage === ApiErrorMessages.APPROVED_FILE)
      return handleApiError(response.data.detailMessage)

    if (response.data.detailMessage === ApiErrorMessages.EMAIL_ALREADY_REGISTERED)
      return handleApiError(response.data.detailMessage)

    handleApiError(response.data.error)
  }

  const handleBusinessLogicError = (message: string): void => {
    const getMessage = (message: ApiErrorMessages): string => {
      switch (message) {
        case ApiErrorMessages.INVALID_CREDENTIALS:
          return 'E-mail e/ou senha inválidos'
        case ApiErrorMessages.ZIP_CODE_NOT_FOUND:
          return 'CEP não encontrado'
        case ApiErrorMessages.EXPIRED_TOKEN:
          return 'Esse link expirou!'
        case ApiErrorMessages.INVALID_USER_TOKEN:
          return 'Esse token está inválido!'
        case ApiErrorMessages.INACTIVE_USER:
          return 'O usuário está desativado. Entre em contato com a Copérnico'
        default:
          return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
      }
    }

    const _message = getMessage(message as ApiErrorMessages)

    notification.error({
      message: _message,
      placement: 'topRight',
    })
  }

  return {
    handleApiError,
    handleGenericError,
    handleApiErrorDesambiguation,
    handleBusinessLogicError,
    handleInvalidTokenError
  }
}

export default useErrorHandler
