import { useState } from 'react'
import useRest from 'hooks/useRest'


export type TApprovedTimeParams = {
  clientId?: number
  costCenterId?: number
  endDate: string
  startDate: string
  projectId?: string
  employeeName?: string
}


const useConsolidatedReport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getApprovedTime = async (params: TApprovedTimeParams, triggerLoading = true,): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/report/approved-time'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if(!response) return

    return response
  }
  return {
    isLoading,
    getApprovedTime
  }
}

export default useConsolidatedReport