import { useState } from 'react'
import useRest from 'hooks/useRest'
import { TEmailConfig } from 'pages/Config/EmailConfig'

export type TEmailConfigResponse = {
  content: TEmailConfig | null
  timestamp: Date
  status: number
  error: string
  message: string
  detailMessage: string
}

const useConfig = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getEmailConfig = async (triggerLoading = true): Promise<TEmailConfigResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/configuration/email'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)

    return response as TEmailConfigResponse
  }

  const postEmailConfig = async (data: TEmailConfig, triggerLoading = true): Promise<number | undefined> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/configuration/email/save'
    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    if(!response) return

    return response.content.id as number
  }

  return {
    isLoading,
    getEmailConfig,
    postEmailConfig
  }
}

export default useConfig