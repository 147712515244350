import React, { useMemo } from 'react'
import { Empty } from 'antd'
import {
  ExpandableConfig,
  FilterValue,
  SorterResult,
  TablePaginationConfig
} from 'antd/lib/table/interface'

import { Table } from './styles'

type TProps = {
  data: Array<object>
  columns: Array<object>
  emptyMessage?: string
  selectableRow?: boolean
  setSelectedRows?: React.Dispatch<React.SetStateAction<object[]>>
  expandable?: ExpandableConfig<object> | undefined
  expandRowByClick?: boolean
  pagination?: false | TablePaginationConfig
  loading?: boolean,
  scroll?: any,
  rowKey?: any,
  onChange?: ((
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<object> | any
  ) => void) | undefined
  isFiltered?: boolean,
}

const DataTable = (props: TProps): JSX.Element => {
  const {
    data,
    columns,
    emptyMessage = '',
    pagination = false,
    onChange,
    loading = false,
    selectableRow = false,
    setSelectedRows,
    isFiltered = false,
    expandable,
    scroll,
    rowKey,
    ...remainingProps
  } = props

  const _rowSelection = useMemo(() => {
    if (!selectableRow) return undefined

    return {
      onChange: (keys: React.Key[], rows: object | object[]): void => {
        const _rows = Array.isArray(rows) ? rows : [rows]
        if (setSelectedRows) setSelectedRows(_rows)
      }
    }
  }, [selectableRow])

  return (
    <>
      {!loading && data !== null && !data?.length && !isFiltered ? (
        <Empty
          description={emptyMessage}
        />
      ) : (
        <Table
          scroll={scroll}
          expandable={expandable}
          rowSelection={_rowSelection}
          columns={columns}
          dataSource={data}
          size='small'
          pagination={pagination}
          onChange={onChange}
          loading={loading}
          rowKey={rowKey}
          {...remainingProps}
        />
      )}
    </>
  )
}

export default DataTable
