import React from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { Modal, Loading } from 'components'
import {
  Content,
  Header,
  Title,
  SubTitle,
  Box,
  CheckIcon
} from './styles'

type TProps = {
  open: boolean
  handleClose: () => void,
  title: string
  subTitle: string,
  isLoading?: boolean
}

const ModalEmailConfiguration = (props: TProps): JSX.Element => {
  const {
    open,
    handleClose,
    title,
    subTitle,
    isLoading
  } = props
  const { t } = useTranslation('namespaces')

  return (
    <Modal
      id='modal-email-configuration'
      isModalVisible={open}
      handleClose={handleClose}
    >
      <Content>
        <Header>
          <Title> {title} </Title>
          <SubTitle> {subTitle} </SubTitle>
        </Header>
        <Box>
          {isLoading ? (
            <Loading text={t('please wait', { ns: namespaces.pages.settings })} />
          ) : (<>
            <CheckIcon />
            <p>
              {t('email configuration was completed successfully', { ns: namespaces.pages.settings })}
            </p>
          </>
          )}
        </Box>
      </Content>
    </Modal>
  )

}
export default ModalEmailConfiguration