import React, { useEffect, useState, useRef } from 'react'
import { Table, Row, Col, Form, Select, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import useClients, {
  GetClientsByPageableFilterQueryParams, TClientFrom_Client_Pageable,
  TJiraCompanyFrom_GetJiraCompanyAll,
} from 'repositories/useClient'
import ModalCostCenterForm from './Modal/index'
import { namespaces } from 'i18n/i18n.constants'
import { Content, Header, Title, SubTitle } from './styles'
import { ClientRequestConfig } from 'hooks/useRest'
import { Edit, ExpandIcon } from 'components'
import RelateContent from './RelateContent'
import { ExpandableConfig } from 'antd/lib/table/interface'


const CostCenterRelate = (): JSX.Element => {
  const { t } = useTranslation('namespaces')
  const [formInstance] = Form.useForm()
  const clientRepository = useClients()
  const cancelTokenRef = useRef(clientRepository.getCancelToken())
  const [jiraCompaniesArray, setJiraCompaniesArray] = useState<Array<TJiraCompanyFrom_GetJiraCompanyAll>>([])
  const [clientsArray, setClientsArray] = useState<Array<TClientFrom_Client_Pageable>>([])
  const [isLoadingJiraCompanies, setIsLoadingJiraCompanies] = useState<boolean>(false)
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false)
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false)
  const [tableDataSource, setTableDataSource] = useState<Array<TClientFrom_Client_Pageable>>([])
  type recordType = {
    corporateName: string,
    jiraCompanyName: string,
    projects: [
      {
        id: number,
        isActive: boolean,
        name: string,
        costCenters: [{ code: string, id: number }]
      }
    ]
  }
  const [isOpen, setIsOpen] = useState(false)
  const [record, setRecord] = useState<recordType>({ corporateName: '', jiraCompanyName: '', projects: [{ id: 0, isActive: false, name: '', costCenters: [{ code: '', id: 0, }], }, ], },)
  const changeModal = async (_record?: recordType): Promise<void> => {
    setRecord(_record ?? { corporateName: '', jiraCompanyName: '', projects: [{ id: 0, isActive: false, name: '', costCenters: [{ code: '', id: 0, }], }, ], },)
    setIsOpen(!isOpen)
  }

  const loadClientsByJiraCompany = async (): Promise<void> => {
    let response

    setIsLoadingClients(true)

    const selectedJiraCompany = formInstance.getFieldValue('jiraCompany')

    let selectedJiraCompanyElement
    const queryParams: GetClientsByPageableFilterQueryParams = {}
    const reqConfigs: ClientRequestConfig = {}

    reqConfigs.cancelToken = cancelTokenRef.current.token

    if (selectedJiraCompany) {
      selectedJiraCompanyElement = jiraCompaniesArray.find((element) => {
        return element.id === selectedJiraCompany
      })
    }

    queryParams.company = selectedJiraCompanyElement?.name
    try {
      response = await clientRepository.getClientsByClientAndCompany(false, queryParams, reqConfigs)
    } catch (error) {
      console.log(error)
    }

    setIsLoadingClients(false)

    switch (true) {
      case (response?.status === 200):
        setClientsArray(response?.content.clients || [])
        break

      default:
        break
    }
  }

  const CleanTableData = (): void => {
    formInstance.setFieldValue('jiraCompany', '')
    setClientsArray([])
    setTableDataSource([])
  }

  const loadTableData = async (): Promise<void> => {
    let response

    setIsTableLoading(true)

    const selectedJiraCompany = formInstance.getFieldValue('jiraCompany')
    const selectedClient = formInstance.getFieldValue('client')

    let selectedJiraCompanyElement
    let selectedClientElement
    const queryParams: GetClientsByPageableFilterQueryParams = {}
    const reqConfigs: ClientRequestConfig = {}

    reqConfigs.cancelToken = cancelTokenRef.current.token

    if (selectedJiraCompany && selectedJiraCompany !== '') {
      selectedJiraCompanyElement = jiraCompaniesArray.find((element) => {
        return element.id === selectedJiraCompany
      })
    }

    if (selectedClient) {
      selectedClientElement = clientsArray.find((element) => {
        return element.id === selectedClient
      })
    }

    queryParams.company = selectedJiraCompanyElement?.name
    queryParams.client = selectedClientElement?.corporateName
    if (selectedJiraCompanyElement) {
      try {
        response = await clientRepository.getClientsByPageableFilter(false, queryParams, reqConfigs)
      } catch (error) {
        console.log(error)
      }

      setIsTableLoading(false)

      switch (true) {
        case (response?.status === 200):
          if (response?.content.clientList) {
            const _list = []
            for (const i in response?.content.clientList) {
              _list.push({
                id: response?.content.clientList[i].id,
                cnpj: response?.content.clientList[i].cnpj,
                corporateName: response?.content.clientList[i].corporateName,
                fantasyName: response?.content.clientList[i].fantasyName,
                phoneNumber: response?.content.clientList[i].phoneNumber,
                stateRegistration: response?.content.clientList[i].stateRegistration,
                municipalRegistration: response?.content.clientList[i].municipalRegistration,
                cnae: response?.content.clientList[i].cnae,
                email: response?.content.clientList[i].email,
                isActive: response?.content.clientList[i].isActive,
                jiraCompanyId: response?.content.clientList[i].jiraCompanyId,
                jiraCompanyName: response?.content.clientList[i].jiraCompanyName,
                projects: response?.content.clientList[i].projects,
                key: i,
              } as unknown as TClientFrom_Client_Pageable)
            }
            setTableDataSource(_list)
          } else {
            setTableDataSource([])
          }
          /* setTableDataSource(response?.content.clientList || []) */
          break

        default:
          break
      }
    } else {
      setIsTableLoading(false)
    }
  }

  useEffect(() => {
    const loadJiraCompanies = async (): Promise<void> => {
      let response

      setIsLoadingJiraCompanies(true)

      try {
        response = await clientRepository.getAllJiraCompany(false)
      } catch (error: unknown) {
        console.log(error)
      }

      setIsLoadingJiraCompanies(false)

      switch (true) {
        case (response?.status === 200):
          setJiraCompaniesArray(response?.content || [])
          break

        default:
          break
      }
    }

    loadJiraCompanies()

    return (): void => {
      cancelTokenRef.current.cancel('cancelled request')
    }
  }, [])

  const expandable = {
    expandedRowRender: (record) => {
      const _record = record as TClientFrom_Client_Pageable
      return (
        <RelateContent
          key={_record.key}
          projects={_record.projects}
        />
      )
    },
    expandIcon: ({ expanded, onExpand, record }) => (
      <ExpandIcon
        expanded={String(expanded)}
        onExpand={onExpand}
        record={record}
      />
    )
  } as ExpandableConfig<object>

  return (
    <Content>
      <Header>
        <Title>{t('page_title', { ns: namespaces.pages.costCenterRelate })}</Title>
        <SubTitle>
          {t('page_subTitle', { ns: namespaces.pages.costCenterRelate })}
        </SubTitle>
      </Header>
      <Row gutter={[24, 24]}>
        <Col
          xs={{ span: 24 }}
        >
          <Row>
            <Col
              xs={{ span: 24 }}
            >
              <Form
                form={formInstance}
                layout='vertical'
                onFinish={
                  (): void => {
                    loadTableData()
                  }
                }
                onFinishFailed={
                  (errorInfo): void => {
                    console.log('CostCenterRelate onFinishFailed', errorInfo)
                  }
                }
                onValuesChange={
                  (changedValues): void => {
                    if (changedValues?.jiraCompany) {
                      loadClientsByJiraCompany()
                    }
                  }
                }
              >
                <Row gutter={[24, 24]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    <Form.Item
                      label={t('jiraCompany_formItemLabel', { ns: namespaces.pages.costCenterRelate })}
                      name='jiraCompany'
                    >
                      <Select
                        loading={isLoadingJiraCompanies}
                        disabled={isLoadingJiraCompanies || jiraCompaniesArray.length === 0}
                        options={
                          jiraCompaniesArray.map((jiraCompany) => {
                            return ({
                              label: jiraCompany.name,
                              value: jiraCompany.id,
                            })
                          }) || []
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    xl={{ span: 6 }}
                  >
                    <Form.Item
                      label={t('client_formItemLabel', { ns: namespaces.pages.costCenterRelate })}
                      name='client'
                    >
                      <Select
                        loading={isLoadingClients}
                        disabled={isLoadingClients || clientsArray.length === 0}
                        options={
                          clientsArray.map((clientElem) => {
                            return ({
                              label: clientElem.corporateName,
                              value: clientElem.id,
                            })
                          }) || []
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 6, offset: 12 }}
                    xl={{ span: 4, offset: 16 }}
                  >
                    <Form.Item>
                      <Button
                        type='default'
                        htmlType='reset'
                        onClick={(): void => CleanTableData()}
                        block
                      >
                        {t('button_reset_text', { ns: namespaces.pages.costCenterRelate })}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                  >
                    <Form.Item>
                      <Button
                        type='primary'
                        htmlType='submit'
                        block
                      >
                        {t('button_filter_text', { ns: namespaces.pages.costCenterRelate })}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24 }}
            >
              <Table
                columns={[
                  {
                    title: t('table_column_id', { ns: namespaces.pages.costCenterRelate }),
                    dataIndex: 'id',
                    key: 'id',
                  },
                  {
                    title: 'Empresa',
                    dataIndex: 'jiraCompanyName',
                    key: 'jiraCompanyName',
                  },
                  {
                    title: t('table_column_client', { ns: namespaces.pages.costCenterRelate }),
                    dataIndex: 'corporateName',
                    key: 'corporateName',
                  },
                  {
                    title: `${t('action', { ns: namespaces.common })}`,
                    dataIndex: 'Icone',
                    key: 'action',
                    align: 'center',
                    render: (text, record): JSX.Element => (
                      <a
                        title={t('edit', { ns: namespaces.common })}
                        onClick={(): Promise<void> => changeModal(record as recordType)}
                      >
                        <Edit />
                      </a>
                    ),
                  }
                ]}
                loading={isTableLoading}
                dataSource={tableDataSource}
                expandable={expandable}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalCostCenterForm
        open={isOpen}
        handleClose={(): void => { setIsOpen(false) }}
        keyModal={0}
        client={record?.corporateName}
        projects={record.projects}
        companyName={record?.jiraCompanyName}
        record={record}
        id={'modal-editar'}
      />
    </Content>
  )
}

export default CostCenterRelate
