
import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const ConsolidatedIcon = (props: TProps): JSX.Element => {
  const {
    size = '16',
    color = '#868686'
  } = props

  const height = Number(size) * 1.25

  return (
    <svg width={size} height={height} viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 0C7.63261 0 7.75979 0.0526784 7.85355 0.146447C7.94732 0.240215 8 0.367392 8 0.5C8 0.632608 8.05268 0.759785 8.14645 0.853553C8.24021 0.947322 8.36739 1 8.5 1C8.63261 1 8.75979 1.05268 8.85355 1.14645C8.94732 1.24021 9 1.36739 9 1.5V2C9 2.13261 8.94732 2.25979 8.85355 2.35355C8.75979 2.44732 8.63261 2.5 8.5 2.5H3.5C3.36739 2.5 3.24021 2.44732 3.14645 2.35355C3.05268 2.25979 3 2.13261 3 2V1.5C3 1.36739 3.05268 1.24021 3.14645 1.14645C3.24021 1.05268 3.36739 1 3.5 1C3.63261 1 3.75979 0.947322 3.85355 0.853553C3.94732 0.759785 4 0.632608 4 0.5C4 0.367392 4.05268 0.240215 4.14645 0.146447C4.24021 0.0526784 4.36739 0 4.5 0L7.5 0Z' fill={color} />
      <path d='M1 2.5C1 2.36739 1.05268 2.24021 1.14645 2.14645C1.24021 2.05268 1.36739 2 1.5 2H2C2.13261 2 2.25979 1.94732 2.35355 1.85355C2.44732 1.75979 2.5 1.63261 2.5 1.5C2.5 1.36739 2.44732 1.24021 2.35355 1.14645C2.25979 1.05268 2.13261 1 2 1H1.5C1.10218 1 0.720644 1.15804 0.43934 1.43934C0.158035 1.72064 0 2.10218 0 2.5V14.5C0 14.8978 0.158035 15.2794 0.43934 15.5607C0.720644 15.842 1.10218 16 1.5 16H10.5C10.8978 16 11.2794 15.842 11.5607 15.5607C11.842 15.2794 12 14.8978 12 14.5V2.5C12 2.10218 11.842 1.72064 11.5607 1.43934C11.2794 1.15804 10.8978 1 10.5 1H10C9.86739 1 9.74021 1.05268 9.64645 1.14645C9.55268 1.24021 9.5 1.36739 9.5 1.5C9.5 1.63261 9.55268 1.75979 9.64645 1.85355C9.74021 1.94732 9.86739 2 10 2H10.5C10.6326 2 10.7598 2.05268 10.8536 2.14645C10.9473 2.24021 11 2.36739 11 2.5V14.5C11 14.6326 10.9473 14.7598 10.8536 14.8536C10.7598 14.9473 10.6326 15 10.5 15H1.5C1.36739 15 1.24021 14.9473 1.14645 14.8536C1.05268 14.7598 1 14.6326 1 14.5V2.5Z' fill={color} />
      <path d='M8.85463 7.85366C8.94852 7.75977 9.00126 7.63243 9.00126 7.49966C9.00126 7.36688 8.94852 7.23954 8.85463 7.14566C8.76075 7.05177 8.63341 6.99902 8.50063 6.99902C8.36786 6.99902 8.24052 7.05177 8.14663 7.14566L5.50063 9.79265L4.35463 8.64565C4.26075 8.55177 4.13341 8.49902 4.00063 8.49902C3.86786 8.49902 3.74052 8.55177 3.64663 8.64565C3.55275 8.73954 3.5 8.86688 3.5 8.99965C3.5 9.13243 3.55275 9.25977 3.64663 9.35365L5.14663 10.8537C5.19308 10.9002 5.24825 10.9372 5.309 10.9624C5.36974 10.9876 5.43486 11.0005 5.50063 11.0005C5.5664 11.0005 5.63152 10.9876 5.69227 10.9624C5.75301 10.9372 5.80819 10.9002 5.85463 10.8537L8.85463 7.85366V7.85366Z' fill={color} />
    </svg>


  )
}

export default ConsolidatedIcon