import Moment from 'moment'
import { namespaces } from 'i18n/i18n.constants'
import { TFunction } from 'i18next'
import { EventValue } from 'rc-picker/lib/interface'

export const formatDateTimeToView = (date?: string): string => {
  if (!date) return ''
  return Moment(date).format('DD/MM/YYYY - HH:mm')
}

export const formatDateToServer = (date: string | EventValue<moment.Moment>): string => {
  if (!date) return ''
  return Moment(date).format('YYYY-MM-DD')
}

export const removeTimeFromDateTime = (date: string): string => (
  date.substring(0, 10)
)

export const formatDecimalNumber = (number: string | number): string => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'decimal',
      maximumFractionDigits: 2
    }
  )

  return formatter.format(Number(number))
}
export const formatDecimalNumberToPercentage = (number: string | number): string => (
  `${formatDecimalNumber(number)} %`
)

export const formatCurrency = (number: string | number): string => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL'
    }
  )

  return formatter.format(Number(number))
}

export const formatDateToView = (date: string | undefined): string => {
  if (!date) return ''
  if (date.length > 10) {
    const dateArray = date.split('-')
    const dayMonthYear = dateArray[0].split('/')
    const Name = Moment(`${dayMonthYear[1]}/${dayMonthYear[0]}/${dayMonthYear[2]}`).format('DD-MM-YYYY')
    return Name
  }

  return Moment(date).format('DD/MM/YYYY')
}

export const getCurrentDate = (): string => Moment().format('YYYY-MM-DD')

export const getDiffDaysBetweenDates = (start: string, end: string): number => {
  const startDate = Moment(start, 'YYYY-MM-DD')
  const endDate = Moment(end, 'YYYY-MM-DD')
  return Moment.duration(endDate.diff(startDate)).asDays()
}

export const fileToBase64 = async (file: Blob): Promise<string> => {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (): void => resolve(reader.result)
    reader.onerror = (error): void => reject(error)
  })

  const [, data] = String(base64).split(',')

  return data
}

export const downloadBase64File = async (
  fileName: string,
  base64string: string,
  fileFormat = 'application/pdf'
): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    try {
      const linkSource = `data:${fileFormat};base64,${base64string}`

      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export const formatFileType = (type: string, t: TFunction<'namespaces', undefined>): string => {

  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': return t('sheet', { ns: namespaces.common })
    default: return t('sheet', { ns: namespaces.common })
  }
}

export const formatCurrencyToServer = (number: string): string => {
  if (!number) return '0'

  if (number.indexOf(','))
    return number.replace(/\./g, '').replace(',', '.')

  return number
}

export const getApiErroMessage = (statusText: string, t: TFunction<'namespaces', undefined>): string => {
  switch (statusText) {
    case '': return ''
    case 'invalid_grant': return t('invalid_grant', { ns: namespaces.errors })
    case 'Unauthorized': return t('unauthorized', { ns: namespaces.errors })
    case 'Internal Server Error': return t('internal server error', { ns: namespaces.errors })
    case 'The settings for sending the email were not found': return t('the email settings were not found', { ns: namespaces.errors })
    case 'Unable to find a Collaborator': return t('unable to find a collaborator', { ns: namespaces.errors })
    case 'Some data is already approved': return t('some data is already approved', { ns: namespaces.errors })
    case 'Email already registered': return t('email_already_registered', { ns: namespaces.errors })
    case 'There is no consolidated for the reported period.': return t('There is no consolidated for the reported period.', { ns: namespaces.errors })
    case 'There are one or more periods closed on the reported date.': return t('There are one or more periods closed on the reported date.', { ns: namespaces.errors })
    case 'It was not possible to close this period, as there are employees with divergence. Please check the time analysis.': return t('It was not possible to close this period, as there are employees with divergence. Please check the time analysis.', { ns: namespaces.errors })

    default: return t('default', { ns: namespaces.errors })
  }
}

export const formatDateEnUs = (date: Moment.MomentInput): string => (
  Moment(date).format('YYYY-MM-DD hh:mm')
)

export const getYesNoFromBoolean = (value: boolean): string => (
  value ? 'Sim' : 'Não'
)

export const removeMaskGuides = (value: string): string => (
  value
    .replace(/_/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/ /g, '')
    .replace(/[^\d]+/g, '')
)

export const formatCpfCnpj = (number?: string): string => {
  if (!number) return ''

  const [regext, replacement] = number.length === 11
    ? [/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4']
    : [/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5']

  return number.replace(regext, replacement)
}

export const isDateLessThanCurrent = (date: Moment.MomentInput): boolean => (
  Moment(date).isBefore(getCurrentDate())
)

export const isDateThanCurrent = (date: Moment.MomentInput): boolean => (
  Moment(date).isBefore(getCurrentDate())
)

export const addDaysInDate = (days: string): string => (
  Moment(new Date()).add(days, 'days').format('YYYY-MM-DD')
)

export const downloadUrl = (url: string, name: string): void => {
  const link = document.createElement('a')

  link.download = name
  link.href = url
  link.target = '_BLANK'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const sleep = (ms: number = 0): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, ms))

export const sortArray = (list: TSelectOption[]): TSelectOption[] => {
  if (!list.length) return []

  const ordenedArray = list.sort((old, after) => {
    if (old.label > after.label) return 1
    if (old.label < after.label) return -1

    return 0
  })

  return ordenedArray
}

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes'
  const k = 1000
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const utf8ToB64 = (str: string): string => {
  return window.btoa(unescape(encodeURIComponent(str)))
}

export const b64ToUtf8 = (str: string): string => {
  return decodeURIComponent(escape(window.atob(str)))
}

export const convertFileExcelToJson = (targetFile: string): any[] => {
  const lines = targetFile?.split('\n')
  const result = []
  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    const obj: any = {}
    const currentline = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }

    result.push(obj)
  }

  return result
}

export const convertBase64ToFile = (dataUrl: string, fileName: string): File => {
  const arr: any = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let sizeBstr = bstr.length
  const u8arr = new Uint8Array(sizeBstr)

  while (sizeBstr--) {
    u8arr[sizeBstr] = bstr.charCodeAt(sizeBstr)
  }

  return new File([u8arr], fileName, { type: mime })
}