import React, { useState, useRef } from 'react'
import { Button, Form, Modal, Table, notification } from 'antd'

import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { RangeValue } from 'rc-picker/lib/interface'
import { DatePickerField, TagsField } from 'components'
import { formatDateToServer, isDateLessThanCurrent } from 'utils/helpers'
import useTimeAnalysis, {
  TCollaboratorsWithStatus,
  TNotificationTimeAnalysisParams,
  TNotificationTimeAnalysisParamsNoUsers
} from 'repositories/useTimeAnalysis'
import { CardHistory, CustomModal } from './styles'
import { Rule } from 'antd/lib/form'
import useSocket from 'hooks/useSocket'
import { Client } from '@stomp/stompjs'

type TProps = {
  open: boolean
  intervalDate: RangeValue<moment.Moment>
  selectedRows: Array<object>
  handleClose: () => void
  shouldSendToAll: boolean
}

export type TForm = {
  correctionLimit: string
  responsibleList: string[]
}

const initialValues: TForm = {
  correctionLimit: '',
  responsibleList: []
}

const validation = (key: string, t: TFunction<'namespaces', undefined>) => {
  switch (key) {
    case 'correctionLimit':
      return [
        {
          required: true,
          message: t('correction limit is required', { ns: namespaces.pages.analysisHours })
        },
        ({
          validator: (_, value): Promise<any> => {
            if (isDateLessThanCurrent(value) && value) {
              return Promise.reject(t('the correction date cannot be less than the current one', { ns: namespaces.pages.analysisHours }))
            }

            return Promise.resolve('')
          }
        })
      ] as Rule[]

    default:
      [] as Rule[]
  }
}

const ModalNotificationDivergence = (props: TProps): JSX.Element => {
  const {
    open,
    intervalDate,
    selectedRows,
    handleClose,
    shouldSendToAll,
  } = props

  const [isLoadingSend, setIsLoadingSend] = useState(false)
  // const [isErrorSummaryModalOpen, setIsErrorSummaryModalOpen] = useState(false)
  const isErrorSummaryModalOpen = useRef<boolean>(false)

  const [form] = Form.useForm<TForm>()
  const { t } = useTranslation('namespaces')
  const timeAnalysis = useTimeAnalysis()
  const socket = useSocket()

  const closeModal = (): void => {
    handleClose()
  }

  const columns = [
    {
      title: t('shipping history', { ns: namespaces.pages.analysisHours }),
      dataIndex: 'description',
    }
  ]

  const onSend = (): void => form.submit()

  const translateFailedSend = (failed: string): string => {
    const [error, identificator, port] = failed.split(',')

    if (failed.match('Authentication unsuccessful'))
      return t('Authentication unsuccessful', { ns: namespaces.errors })

    if (failed.match('STARTTLS is required to send mail'))
      return t('STARTTLS is required to send mail', { ns: namespaces.errors })

    switch (error) {
      case 'Illegal address':
        return t('Illegal Address', { ns: namespaces.errors }).replace('{user}', identificator)
      case 'Invalid Addresses':
        return t('Invalid Addresses', { ns: namespaces.errors }).replace('{user}', identificator)
      case 'Couldn\'t connect to host':
        return `${t('Couldn\'t connect to host', { ns: namespaces.errors })}: ${identificator}, ${port}`
      default: return t('default', { ns: namespaces.errors })
    }
  }

  const handleEmailResultSubscription = (stompClient: Client, requestId: string): void => {
    const subscription = stompClient.subscribe(`/topic/messages/${requestId}`, (messageOutput) => {
      const jsonParse = JSON.parse(messageOutput.body)

      let _failedSendList: any[] = []

      switch (true) {
        case (jsonParse.body.detailCode === -18000):
          // "The settings for sending the email were not found, check the configuration microservice.", NO_ERROR, -18000L
          _failedSendList.push({
            key: 0,
            description: t('the email settings were not found', { ns: namespaces.errors }),
          })
          break

        case (jsonParse.body.detailCode === 6006):
        case (jsonParse.body.detailCode === 6007):
          // "The email(s) was(were) sent successfully.",NO_ERROR, 6006L
          // "There were failures to send.",NO_ERROR, 6007L
          if (jsonParse.body.content.success.failedMailingList.length) {
            const { failedMailingList } = jsonParse.body.content.success

            _failedSendList = failedMailingList.map((failed: string, index: any) => ({
              key: index,
              description: translateFailedSend(failed)
            }))
          }
          break

        case (jsonParse.body.detailCode === -6005):
          // "No users found with divergences.",HAS_ERROR,-6005L
          _failedSendList.push({
            key: 0,
            description: t('noUsersFoundWithDivergences', { ns: namespaces.pages.analysisHours }),
          })
          break

        case (jsonParse.body.detailCode === -6006):
          // "Error fetching Time Analysis by employees ids",HAS_ERROR,-6006L
          _failedSendList.push({
            key: 0,
            description: t('errorTimeAnalysisById', { ns: namespaces.pages.analysisHours }),
          })
          break

        case (jsonParse.body.detailCode === -6007):
          // "Error fetching Time Analysis by filter",HAS_ERROR,-6007L
          _failedSendList.push({
            key: 0,
            description: t('errorTimeAnalysisByFilter', { ns: namespaces.pages.analysisHours }),
          })
          break

        case (jsonParse.body.detailCode === -1000):
        default:
          // Something unexpected happened, HAS_ERROR, -1000L
          _failedSendList.push({
            key: 0,
            description: t('default', { ns: namespaces.errors }),
          })
          break
      }


      if (_failedSendList.length) {
        const errorTitle = t('there was an error sending the emails', { ns: namespaces.pages.analysisHours })
        notification.warning({
          message: errorTitle,
          duration: 0,
          btn: (
            <Button
              disabled={(isErrorSummaryModalOpen.current === true)}
              onClick={(): void => {
                if (isErrorSummaryModalOpen.current === false) {
                  isErrorSummaryModalOpen.current = true

                  Modal.info({
                    centered: true,
                    maskClosable: false,
                    title: t('details', { ns: namespaces.pages.analysisHours }),
                    onOk: () => { },
                    afterClose: () => {
                      isErrorSummaryModalOpen.current = false
                    },
                    okText: 'Ok',
                    style: { width: '1024px' },
                    content: (
                      <CardHistory>
                        <Table
                          size='small'
                          pagination={false}
                          columns={columns}
                          dataSource={_failedSendList}
                          scroll={{ y: 350 }}
                        />
                      </CardHistory>
                    )
                  })
                }
              }}
            >
              {t('details', { ns: namespaces.pages.analysisHours })}
            </Button>
          ),
          placement: 'topRight',
        })

        subscription.unsubscribe()
        stompClient.deactivate()
        return
      }
      notification.success({
        message: t('the emails were sent succesfully', { ns: namespaces.pages.analysisHours }),
        placement: 'topRight',
        duration: 0
      })

      subscription.unsubscribe()
      stompClient.deactivate()
    })
  }

  const sendToCollaboratorList = (): void => {
    if (!intervalDate) return

    const stompClient = socket.socketConnect()
    stompClient.activate()

    const [from, to] = intervalDate
    const { correctionLimit, responsibleList } = form.getFieldsValue()

    const collaboratorList = selectedRows.map((row) => {
      const _row = row as TCollaboratorsWithStatus
      return {
        id: Number(_row.key),
        name: _row.name,
        email: _row.email,
        status: _row.status
      }
    })

    const data: TNotificationTimeAnalysisParams = {
      startDate: formatDateToServer(from),
      endDate: formatDateToServer(to),
      correctionLimit: formatDateToServer(correctionLimit),
      responsibleList,
      collaboratorList
    }

    stompClient.onConnect = async (frame): Promise<void> => {
      const response = await timeAnalysis.postNotificationTimeAnalysisEmail(data)

      setIsLoadingSend(false)

      if (!response?.content?.requestId) {
        notification.error({
          message: t('send error', { ns: namespaces.pages.analysisHours }),
          placement: 'topRight',
        })
        stompClient.deactivate()
        return
      }

      closeModal()

      Modal.info({
        content: t('request sent', { ns: namespaces.pages.analysisHours }),
        onOk: () => { }
      })

      handleEmailResultSubscription(stompClient, response.content.requestId)
    }

    stompClient.onStompError = (frame) => {
      console.log('websocketError', frame)
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('websocketError', { ns: namespaces.common })
      })
      stompClient.deactivate()
    }
  }

  const sendToAll = (): void => {
    if (!intervalDate) return

    const stompClient = socket.socketConnect()
    stompClient.activate()

    const [from, to] = intervalDate
    const { correctionLimit, responsibleList } = form.getFieldsValue()

    const data: TNotificationTimeAnalysisParamsNoUsers = {
      startDate: formatDateToServer(from),
      endDate: formatDateToServer(to),
      correctionLimit: formatDateToServer(correctionLimit),
      responsibleList,
    }

    stompClient.onConnect = async (frame): Promise<void> => {
      const response = await timeAnalysis.postNotificationTimeAnalysisEmailAll(data)
      setIsLoadingSend(false)

      if (!response?.content?.requestId) {
        notification.error({
          message: t('send to all error', { ns: namespaces.pages.analysisHours }),
          placement: 'topRight',
        })

        stompClient.deactivate()
        return
      }

      closeModal()

      Modal.info({
        content: t('bulk request sent', { ns: namespaces.pages.analysisHours }),
        onOk: () => { }
      })

      handleEmailResultSubscription(stompClient, response.content.requestId)
    }

    stompClient.onStompError = (frame) => {
      console.log('websocketError', frame)
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('websocketError', { ns: namespaces.common })
      })
      stompClient.deactivate()
    }
  }

  const onSubmit = async (): Promise<void> => {
    setIsLoadingSend(true)

    if (shouldSendToAll) {
      return await sendToAll()
    }

    return await sendToCollaboratorList()
  }

  return (
    <CustomModal
      key={Math.random()}
      centered
      transitionName=''
      maskTransitionName=''
      maskClosable={false}
      title={t('send notification divergence', { ns: namespaces.pages.analysisHours })}
      open={open}
      onOk={onSend}
      onCancel={closeModal}
      okText={t('send', { ns: namespaces.common })}
      confirmLoading={isLoadingSend}
      cancelText={t('cancel', { ns: namespaces.common })}
      style={{ width: '1024px' }}
    >
      <Form
        initialValues={initialValues}
        name='basic'
        form={form}
        labelCol={{ span: 8 }}
        onFinish={onSubmit}
        autoComplete='off'
      >
        <DatePickerField
          name='correctionLimit'
          id='correctionLimit'
          required
          rules={validation('correctionLimit', t)}
          label={t('correction limit', { ns: namespaces.pages.analysisHours })}
          placeholder={t('date', { ns: namespaces.common })}
        />
        <TagsField
          name='responsibleList'
          label={t('responsible', { ns: namespaces.pages.analysisHours })}
          id='responsibleList'
          placeholder={t('responsible', { ns: namespaces.pages.analysisHours })}
          defaultValue={[]}
        />
      </Form>
    </CustomModal>
  )
}
export default ModalNotificationDivergence
