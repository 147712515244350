import React, { useState, useEffect } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

import {
  Container,
  Main,
  Content
} from './styles'

type TProps = {
  children: React.ReactNode
}

const Layout = ({ children }: TProps): JSX.Element => {
  const [isCollapsedSidebar, setIsCollapsedSidebar] = useState(false)

  useEffect(() => {
    const collapseSidebar = (): void => {
      if(window.innerWidth > 992) {
        setIsCollapsedSidebar(false)
        return
      }

      setIsCollapsedSidebar(true)
    }

    if(window.innerWidth < 992) setIsCollapsedSidebar(true)

    window.addEventListener('resize', collapseSidebar)
  }, [])

  return (
    <Container>
      <Header />
      <Main>
        <Sidebar isCollapsedSidebar={isCollapsedSidebar} />
        <Content>{children}</Content>
      </Main>
    </Container>
  )
}

export default Layout