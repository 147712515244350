import styled from 'styled-components'

import { Modal } from 'antd'

export const Card = styled(Modal)`
  width: auto;
  height: auto;
  
  .ant-modal-content {
    border-radius: 30px !important;
  }
  .ant-modal-body {
    padding: 5%;
  }
`
