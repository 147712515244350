import i18next, { i18n as i18nInstance } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languages } from './i18n.constants'
import { pt } from './locales/pt-br'
import { en } from './locales/en'

export const resources = {
  [languages.en]: en,
  [languages.pt]: pt,
} as const

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next)

  i18n.init({
    lng: language,
    fallbackLng: 'pt',
    resources,
  })

  return i18n
}

export const i18n = createI18n(navigator.language || languages.pt)

export default i18n