import React, { useState } from 'react'
import Modal from 'components/Modal'
import { Header, Title, LogBox, ClockIcon, BoldText, IconDiv } from './styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'


type TProps = {
  open: boolean
  handleClose: () => void
  title: string
}

const ModalImportingFiles = (props: TProps): JSX.Element => {
  const { open, handleClose, title } = props
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const { t } = useTranslation('namespaces')

  const navigate = useNavigate()

  const moveToListFilesAndClose = (): void => {
    setIsOpen(false)
    navigate('/view-list-files')
  }

  return (
    <Modal width={700} id='ant-modal-log' isModalVisible={isOpen} handleClose={handleClose}>
      <div>
        <Header>
          <Title> {title} </Title>
        </Header>
        <IconDiv>
          <ClockIcon />
        </IconDiv>
        <LogBox>
          <p>
            {t('textBody1', { ns: namespaces.template.modalImportingFiles })} <a onClick={(): void => moveToListFilesAndClose()}>{t('textBody2', { ns: namespaces.template.modalImportingFiles })}</a> {t('textBody3', { ns: namespaces.template.modalImportingFiles })}
          </p>
          <BoldText>
            {t('boldText1', { ns: namespaces.template.modalImportingFiles })}
          </BoldText>
          <BoldText>
            {t('boldText2', { ns: namespaces.template.modalImportingFiles })}
          </BoldText>
        </LogBox>
      </div>
    </Modal>
  )
}
export default ModalImportingFiles