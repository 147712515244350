import styled from 'styled-components'
import { Form } from 'antd'

export const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    label {
        color: ${({ theme }): any => theme.colors.BLACK};
        font-weight: 400;
        font-size: 14px;
        margin-right: 11rem;
    }
`