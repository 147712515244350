import styled from 'styled-components'

import { Modal } from 'antd'

export const Card = styled(Modal)`
  width: 1000px !important;
  height: 480px !important;
  padding-top: -200px;

  .ant-modal-body {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: 1000px !important;
    height: 480px !important;
  }

  .ant-modal-title {
    font-size: 23px;
  }
`
