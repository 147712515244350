import { namespaces } from 'i18n/i18n.constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldBox, FieldDesciption, FieldExample, FieldTitle } from './TemplateJiraOrAttendanceStyle'

const TemplateAttendanceSaturno = (): JSX.Element => {
  const { t } = useTranslation('namespaces')

  const dateExample = new Date().toLocaleDateString('pt-br')
  const timeExample = new Date().toLocaleTimeString('pt-br', { hour: '2-digit', minute: '2-digit' })
  return (
    <>
      <FieldBox>
        <FieldTitle>MATRICULA</FieldTitle>
        <FieldDesciption>{t('employee registration', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: 012595123</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>NOME</FieldTitle>
        <FieldDesciption>{t('employee description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  Maria Silva</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>DT_ADMISSÃO</FieldTitle>
        <FieldDesciption>{t('employee admission description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  {dateExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>DT_DESLIGAMENTO</FieldTitle>
        <FieldDesciption>{t('employee termination description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  {dateExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>SITUAÇÃO</FieldTitle>
        <FieldDesciption>{t('employee status description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  {t('employee status example', { ns: namespaces.template.importFile })}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>DATA</FieldTitle>
        <FieldDesciption>{t('attendance register description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {dateExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>TOTAL_TRABALHADO</FieldTitle>
        <FieldDesciption>
          {t('total work without appreciation description', { ns: namespaces.template.importFile })}
        </FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>
    </>
  )
}

export default TemplateAttendanceSaturno
