import styled from 'styled-components'
import {
  CaretRightOutlined
} from '@ant-design/icons'

type TIconProps = {
  expanded: string
}

export const Icon = styled(CaretRightOutlined)<TIconProps>`
    ${(props) => props.expanded === 'true' && `
        transform: rotate(90deg);
    `}
`