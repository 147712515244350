import styled, { css } from 'styled-components'


export const TemplateContent = styled.div`
width: 100%;
`

export const Title = styled.span`
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`

export const Divider = styled.hr`
width: 100%;
height: 1px;
background-color: ${({ theme }): any => theme.colors.LIGHT_GRAY};
border: none;
`

export const SubTitle = styled.span`
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

`

export const TemplatesType = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 32px;
`

interface ITemplateToggle {
  isActive: boolean,
}

export const TemplateToggle = styled.button<ITemplateToggle>`
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  height: 100%;
  cursor: pointer;
  color: #000;
  outline: none;
  border: none;
  background-color: #fff;
  ${({ isActive, theme }) => isActive && css`
      background-color: ${theme.colors.PRIMARY_BLUE} ;
      color: ${theme.colors.WHITE};
  `};
`