import { namespaces } from 'i18n/i18n.constants'

export const en = {
  [namespaces.layout]: {
    myInformations: 'My informations',
    administrative: 'Administrative',
    configurations: 'Configurations',
    myHours: 'My hours',
    myCertificates: 'My certificates',
    myMdus: 'My Mdus',
    employees: 'Employees',
    employeesUpdate: 'Employees update',
    employeesRelate: 'Employees relate',
    costCenterRelate: 'Cost center relate',
    analysisHours: 'Analysis hours',
    fileImport: 'File import',
    viewFile: 'View file',
    emailConfiguration: 'Email configuration',
    employeesRegister: 'Employee register',
    myProfile: 'My profile',
    consolidated: 'Consolidated',
    exit: 'Exit',
    close_period: 'Close period',
  },
  [namespaces.common]: {
    edit: 'Edit',
    date: 'Date',
    options: 'Options',
    search: 'Search',
    login: 'Enter',
    password: 'Password',
    cancel: 'Cancel',
    name: 'Name',
    nameClock: 'Name Clock',
    nameJira: 'Name Jira',
    action: 'Action',
    age: 'Age',
    address: 'Address',
    associate: 'Associate',
    neighborhoods: 'Neighborhoods',
    city: 'City',
    state: 'State',
    area: 'Area',
    leadership: 'Leadership',
    status: 'Status',
    email: 'Email',
    save: 'Save',
    'drag and drop files, or click here to add them.': 'Drag and drop files or click here to add them.',
    'please wait': 'Please wait...',
    'delete': 'Delete',
    'password is required': 'password is required',
    'it is not a valid email': 'It is not a valid email',
    actions: 'Actions',
    'name file': 'File Name',
    'added to': 'Added to',
    type: 'Type',
    size: 'Size',
    'are you sure you want to delete the document?': 'Are you sure you want to delete the document?',
    'to view': 'To view',
    close: 'Close',
    'jira hours': 'Jira hours {{number}}',
    filter: 'Filter',
    observation: 'Observation',
    'start date': 'Start Date',
    'end date': 'End Date',
    'sheet': 'Sheet',
    'unknown': 'Unknown',
    'export data': 'Export data',
    send: 'Send',
    'employeeName': 'Employee Name',
    'diference bigger': 'Bigger than (in hours):',
    'diference smaller': 'Smaller than (in hours):',
    'diference': 'Diference of hours',
    'period': 'Period',
    remove: 'Remove',
    approve: 'Approve',
    'error': 'Error',
    'success': 'success',
    details: 'Details',
    emailEmployee: 'Employee email',
    confirm: 'Confirm',
    clean_filter: 'Clean filter',
    ascending: 'Ascending',
    descending: 'Descending',
    datePicker: 'en_US',
    'websocketError': 'Websocket error',
    'view': 'View',
    'genericError': 'Sorry, there was an error performing this action.'
  },
  [namespaces.table]: {
    'items per page': 'per page',
    'total of': 'Total of',
    'jump to': 'Go to',
    'page': 'Page'
  },
  [namespaces.pages.login]: {
    welcome: 'Welcome',
    'user is required': 'User is required'
  },
  [namespaces.pages.home]: {
    welcome: 'Hello, welcome to Finance Tracker!',
    'sub title': 'Our software has as main goal to centralize actions and important informations, facilitating our employees day-to-day in every segment and role: Employees, Administrative, Human Resources, Management and Board.',
    'info description': 'Refined with user centered development, our plataform is dynamic and self explanatory, potentiating its use to the maximum to assist on recurrent activities, as worktime analisys, certifications file uploads, spreadsheets, MDUs and etc...',
    'explore and use to its maximum!': 'Explore and use to its maximum!',
    'team info': 'The new Finance Tracker version counted on the collaborators: Alan Victor, André Nogueira, Breno Anacleto, Caio Ferreira, Cedric de Abreu, Cesar Pierobom, Francisco Bandeira, Geraniel da Motta, Guilherme Leal, Heloisa Berniche, Igor Ferreira, Jhonatan Francisco, Jônatas Laet, José Wellyson Marques Dantas, Julio Cesar Chiuchi Araujo, Leonardo Tinassi, Luis Fernando Farnezi Brito, Matheus Quintino, Roberthy da Silva, Raul Donaire, Viviane Medeiros and Wallace Ferrante.',
  },
  [namespaces.pages.employees]: {
    title: 'Employees',
    'sub title': 'Have full data control of all your employees. Keep the list up to date for the best use of the data.',
    'info description': 'You don\'t have a list of Employees. Upload the employee list under ‘Update Employees’ in the side menu, and have your employee list updated.'
  },
  [namespaces.pages.employeesUpdate]: {
    title: 'Update Employees',
    'sub title': 'Upload the employee spreadsheet and get quick access to all employees in the company. Keep the list up to date for the best use of the data.',
    'upload file': 'Upload of spreadsheets',
    'wait for the employee list to load.': 'Wait for the employee list to load.',
    'employees updated successfully!': 'Employees updated successfully!',

  },
  [namespaces.pages.descriptionAnalysis]: {
    'send error': 'Error when requesting to send the notification of description',
    'send success': 'Notification sent successfully',
    'notify all': 'Notify all',
    'notificate selected': 'Notificate selected',
    'description analysis': 'Worklog analysis',
    'description info': 'Import files and analyze the workers that doesn\'t add worklog in the jira hours.',
    'select': 'Select a time frame to show the worklog analysis!',
    'queryOkEmptyResult': 'No inefficient and/or non-existant descriptions were found for the selected period and/or name.',
    'name': 'Name',
    'worklog detail': 'Worklog detail',
    'date': 'Date',
    'time': 'Time',
    'description': 'Description',
    'close': 'Close',
    'send notification': 'Send notification',
    'time limit': 'Time limit',
    'cancel': 'Cancel',
    'send': 'Send',
    'shipping history': 'Shipping history',
    'responsible': 'Responsibles',
    'the correction date cannot be less than the current one': 'The correction date cannot be less than the current one',
    'the emails were sent succesfully': 'The emails were sent succesfully.',
    'there was an error sending the emails': 'Emails partially sent. Click on details to find out which email(s) failed.',
    'correction limit is required': 'Correction limit field is mandatory',
    'bulk request sent': 'A bulk email request has been registered, you will receive the result through a notification.',
    'project': 'Project',
    'jira': 'Jira',
    'collaboratorError': 'Sorry, there was an error fetching the collaborators for the analysis',
    'analysisError': 'Sorry, there was an error with the analysis'
  },
  [namespaces.pages.employeesRelate]: {
    title: 'Employees relate',
    'sub title': 'Relate employees. Standardize the username across all time stamp locations, making the user have the same names in point and in Jira.',
    'company': 'Company',
    'companyPlaceholder': 'Select a Company',
    'project': 'Project',
    'start hour': 'Start Hour',
    'info description': 'You didn\'t import any spreadsheet yet. Upload the spreadsheets under \'Import Archive\' in the side menu, and then relate your employees.',
    'not related yet': 'Not related yet',
    'employee updated successfully!': 'Employee updated successfully!',
    'jira name is mandatory': 'Jira Name is mandatory!',
    'cannot be more than 50 characters': 'Cannot be more than 50 characters!',
    'company is mandatory': 'Company is mandatory!',
    'total of relationships': 'Total of relationships',
    'employee already linked to this company': 'Employee already linked to this company',
    title_modal: 'Edit employees',
    notify_update_email_success: 'The employee email has been updated successfully!',
    placeholderEmployeeEmail: 'Enter employee email',
    'sorting direction': 'Sorting direction'
  },
  [namespaces.pages.settings]: {
    title: 'Settings',
    'sub title': 'Here you will find system settings, notification email and other settings.',
    'notification email': 'Notification email',
    'set up and send automated emails easier.': 'Set up and send automated emails easier.',
    'email configuration was completed successfully': 'Email configuration was completed successfully!',
    'smtp server': 'SMTP Server',
    'smtp connection port': 'SMTP connection port',
    'smtp user': 'SMTP User',
    'smtp password': 'SMTP Password',
    'default sender name': 'Default sender name',
    'use ssl/tl connection': 'Use ssl/tl connection',
    'authenticated email': 'Authenticated email',
    'smtp server is mandatory': 'SMTP server is mandatory',
    'smtp port is mandatory': 'SMTP port is mandatory',
    'smtp port cannot exceed 99999': 'SMTP port cannot exceed 99999',
    'smtp user is mandatory': 'SMTP user is mandatory',
    'cannot be more than 100 characters': 'Cannot be more than 100 characters',
    'default sender name is mandatory': 'Default sender name is mandatory',
    'interval between emails is mandatory': 'intervalo entre emails é obrigatorio',
    'bulk email interval': 'Interval between emails',
    'bulk email': 'Bulk email',
    'bulk interval mandatory': 'The interval between emails is mandatory',
    'bulk interval characters': 'The interval between emails cannot be longer than 10 characteres',
    'bulk interval max integer exceeded': 'The interval between emails cannot exceed 2147483647 milliseconds',
    'bulk interval min': 'The interval between emails must be greater than or equal to 0 milliseconds',
    cannotBeMore70Characters: 'Cannot be more than 70 characters',
  },
  [namespaces.pages.employeesRegister]: {
    'registerTitle': 'Register of special Employee',
    'registerSubTitle': 'Register users that don\'t use the jira or the clock system',
    'name': 'Name',
    'admissionDate': 'Admission Date',
    'save': 'Save',
    'registerSuccess': 'Register success',
    'editSuccess': 'Edit Success',
    'registerError': 'An error has ocurred, please try again',
    'edit': 'Edit',
    'include': 'Include',
    'date': 'Admission Date',
    'validationError': 'Invalid informations, please verify and try again',
    'validationEmailMax50': 'Email cannot contain more than 50 characters',
    'validationNameReq': 'Name is mandatory',
    'validationNameMax100': 'Name cannot contain more than 50 characters',
    'validationAdmissionReq': 'Admission Date is mandatory',
    'modalTitleCreate': 'Add special Employee',
    'modalTitleEdit': 'Edit special Employee',
    'validationTypeEmail': 'Inform a valid email',
    'updateEmail': 'Update Email'
  },
  [namespaces.pages.analysisHours]: {
    title: 'Analysis Hours',
    'sub title': 'Import the files and analyze the attendance and Jira times. Have greater control of correctly scored hours, and divergent hours.',
    'info description': 'You haven\t imported any files yet. Upload the files under ‘Import file’ in the side menu, and then review the employees\' hours.',
    'employees without disagreements': 'Employees without disagreements',
    'employees with disagreements': 'Employees with disagreements',
    'difference between hours': 'Difference between hours',
    'successfully filtered file!': 'Successfully filtered file!',
    'analyze employees with and without divergence': 'Analyze employees with and without divergence',
    'time difference': 'Time difference',
    'status': 'Status',
    'observation': 'Observation',
    'name': 'Name',
    'punch clock hours': 'Punch clock hours',
    'select a time period to display the hours analysis!': 'Select a time period to display the hours analysis!',
    'No hours analysis found for the search criteria entered': 'No hours analysis found for the search criteria entered.',
    'send notification divergence': 'Send notification of divergence',
    'send notification divergence to all': 'Send notification of divergence to all',
    'remove all selections': 'Remover todos os selecionados',
    'send to all error': 'Error when requesting the sending of the notification of divergence to all',
    'send error': 'Error when requesting to send the notification of divergence',
    'correction limit': 'Correction Limit',
    'responsible': 'Responsibles',
    'shipping history': 'Shipping history',
    'the emails were sent succesfully': 'The emails were sent succesfully.',
    'there was an error sending the emails': 'Emails partially sent. Click on details to find out which email(s) failed.',
    'details': 'Details',
    'bulk request sent': 'A bulk email request has been registered, you will receive the result through a notification.',
    'request sent': 'A request to send an email has been registered, you will receive the result through a notification.',
    'correction limit is required': 'Correction limit field is mandatory',
    'the file was downloaded successfully': 'The file was downloaded successfully',
    'the correction date cannot be less than the current one': 'The correction date cannot be less than the current one',
    'approve an employee': 'Approve an Employee',
    'approve an employee with divergence': 'Approve an Employee with divergences. If necessary, post an observation.',
    'approve': 'Approve',
    'remove employees': 'Remove employees.',
    'remove approved employees with divergence': 'Remove approved employees with divergence.',
    'the employee was successfully approved': 'The employee was successfully approved!',
    'the employee was successfully disapproved': 'The employee was successfully disapproved!',
    'approved employees': 'Approved with divergence',
    'special employees': 'Specials',
    close: 'Close',
    title_details_divergence: 'Details of divergences',
    subtitle_details_divergence: 'View the detail of each divergent item',
    'no results were found for generating the file': 'No results were found for generating the file',
    'smaller canot be smaller than bigger': 'The value entered must not be less than the "Greater than" field',
    'please input valid info': 'Please input valid info',
    'noUsersFoundWithDivergences': 'No users with divergences were found.',
    'errorTimeAnalysisById': 'Error when performing a detailed analysis by user',
    'errorTimeAnalysisByFilter': 'Error when performing the analysis',
  },
  [namespaces.pages.importFile]: {
    title: 'Import files',
    'sub title': 'Upload employee time and Jira spreadsheets, have them all stored in one place.',
    'import files and stay up to date.': 'Import files and stay up to date.',
    'file imported successfully!': 'Data imported successfully!',
    'files were partially imported.': 'Data was partially imported. Please verify the error log.',
    'incorrect file format': 'Incorrect file formatting.',
    'error importing data': 'An error occurred while importing your data. Please verify the error log.',
    'duplicated file': 'There is already a imported file with the same name and size .',
    'questions about file template': 'Questions about file template',
    'view log': 'Show Log',
    'download log': 'Download Log',
    'files were partially consolidated.': 'The uploaded data was previously approved. Results may not change.',
    'error importing the file': 'Error importing the file {fileName}',
    'the file was successfully imported': 'The file {fileName} was successfully imported!',
    'The file has been imported, but some of its data has already been consolidated previously.': 'The file {fileName} has been imported, but some of its data has already been consolidated previously.',
  },
  [namespaces.pages.consultFiles]: {
    title: 'Consult Archives',
    'sub title': 'See the  imported files.',
    'see the imported files.': 'See the imported files.',
    'info description': 'You don\'t have a list of Files. Upload files from ‘Import Files’ in the side menu.',
    'start date': 'State date',
    customer: 'Customer',
    project: 'Project',
    'refresh': 'Refresh',
    key: 'Key',
    abstract: 'Abstract',
    'cost center': 'Cost center',
    'display name': 'Display name',
    'time spent': 'Time spent',
    'job description': 'Job description',
    'success files': 'Files Imported Successfully',
    'error files': 'Files Imported with Error',
    'warning subtitle': 'ATTENTION: Importing spreadsheets uses a lot of server resources. The system may present instability if this routine is being executed.',
  },
  [namespaces.pages.consolidated]: {
    title: 'Consolidated',
    subtitle: 'Visualize how many hours employees or customers have on each project.',
    employee: 'Employee',
    customer: 'Customers',
    'select customer': 'Select...',
    'select project': 'Select...',
    'select cost center': 'Select...',
    export: 'Export',
    'search employee': 'Search...',
    'error prefix': 'Please, select ',
    period: 'period',
    customer_one: 'Customer',
    project: 'project',
    title_period: 'Period',
    title_select_customer: 'Customer',
    title_select_project: 'Project',
    title_select_cost_center: 'Cost center',
    title_search_employee: 'Search employee',
    export_file: 'Export file',
    type_export: 'Type',
  },
  [namespaces.pages.period]: {
    title: 'Close period',
    subtitle: 'Perform period closing and reopening of consolidated points.',
    close_period: 'Do you want to close the period of {period}?',
    reopen_period: 'Do you want to reopen the period of {period}?',
    close_period_success: 'The period {period} was closed successfully!',
    reopen_period_success: 'The period {period} was reopened successfully!',
    closing_date: 'Closing date',
    empty_period: 'You do not have closed periods.',
    reopen: 'Reopen',
  },
  [namespaces.errors]: {
    'unauthorized': 'Unauthorized access.',
    'default': 'An unexpected error has occurred. Please try again later.',
    'session closed': 'Your session has ended. Please login again!',
    'internal server error': 'Internal server error',
    'the email settings were not found': 'The email settings were not found',
    'Illegal Address': 'Contributor "{user}" has illegal or empty email',
    'Invalid Addresses': 'The email "{user}" is invalid',
    'invalid_grant': 'username or password is invalid',
    'Couldn\'t connect to host': 'Couldn\'t connect to host',
    'Authentication unsuccessful': 'Unable to perform authentication, invalid email or password',
    'STARTTLS is required to send mail': 'STARTTLS is required to send mail',
    'unable to find a collaborator': 'Collaborator not found. Make sure the name is correct',
    'some data is already approved': 'The data of this file were already approved. It cannot be deleted.',
    email_already_registered: 'The email provided is already linked to a collaborator!',
    'There is no consolidated for the reported period.': 'There is no consolidated for the reported period.',
    'There are one or more periods closed on the reported date.': 'There are one or more periods closed on the reported date.',
    'It was not possible to close this period, as there are employees with divergence. Please check the time analysis.': 'It was not possible to close this period, as there are employees with divergence. Please check the time analysis.',
  },
  [namespaces.template.modalImportingFiles]: {
    textBody1: 'Access the',
    textBody2: 'View file',
    textBody3: 'menu to view the import status!',
    boldText1: 'Important: The importation time can change acording the file size.',
    boldText2: 'During the proccess the system can get instable! Please, wait until it ends.',
  },
  [namespaces.template.importFile]: {
    'template import': 'Template import',
    'required fields description': 'Check below the required fields for import timesheet in each type',
    'jira sheet import': 'Jira sheet import',
    'rm timesheet import': 'RM timesheet import',
    'saturno timesheet import': 'Saturno timesheet import',

    'employee required fields': 'Required fields of employee',
    'company required fields': 'Required field of company',

    'company description': 'Inform the name of company that the jira was imported',
    'customer description': 'Inform the name of customer',
    'project description': 'Inform the name of project',
    'jira description': 'Inform the title of the worked Jira card',
    'cost center description': 'Inform the cost center linked to Jira',
    'employee name description': 'Inform the name of the employee',
    'register activity date description': 'Inform the date of registration of the activity in the format day/month/year',
    'register worked time description': 'Inform the worked time in Jira card in decimal format',

    'employee description': 'Inform the name of the employee was registered in the attendance system',
    'employee admission description': 'Inform the date of the employee was admitted',
    'attendance register description': 'Inform the attendance register in format day/month/year',
    'delay time description': 'Inform the delay time of day in format hour:minutes',
    'absence description': 'Inform the time of absence of the day in format hour:minutes',
    'overtime description': 'Inform the overtime performed of the day in format day/month/year',
    'allowance time description': 'Inform the allowance time of the day in format hour:minutes',
    'base time description': 'Inform the base time of the day in format hour:minutes',

    'employee registration': 'Inform the employee´s registration in the attendance system',
    'employee termination description': 'Inform the employee´s termination date in day/month/year format',
    'employee status description': 'Inform the employee´s status in the system: ACTIVE(ATIVO) or OFF(DESLIGADO)',

    'total work with appreciation description': 'Inform the total of work with appreciation of the day in format hour:minute',
    'total work without appreciation description': 'Inform the total of work without appreciation of the day in format hour:minute',
    'example': 'Example'
  },
  [namespaces.pages.costCenterRelate]: {
    'page_title': 'Relate Cost Center',
    'page_subTitle': 'Relate the cost center to its project/client.',
    'jiraCompany_formItemLabel': 'Company',
    'client_formItemLabel': 'Client',
    'table_column_id': 'ID',
    'table_column_client': 'Client',
    'table_column_status': 'Status',
    'status_active': 'Active',
    'status_inactive': 'Inactive',
    'button_filter_text': 'Filter',
    'button_reset_text': 'Clear',
    'success': 'Cost center update with success',
    'error': 'Error while updating the cost center, try again',
    'attach': 'Attach cost center',
    'project': 'Project',
    'company': 'Company',
    'client': 'Client',
    'add': 'Add ->',
    'placeholder_input_cost_center': 'Type in the cost center',
    'filter': 'Filter',
    'clean': 'Clean',
    'remove': '<- Remove',
    'cancel': 'Cancel',
    'save': 'Salve',
  },
}