import styled from 'styled-components'
import { Table as Tb } from 'antd'

export const Table = styled(Tb)`
  padding: 0;
  border-spacing: 0 3px;

  thead {
    tr,
    th {
      background-color: ${({ theme }): string => `${theme.colors.WHITE} !important`};
      border: none;

      th::before {
        display: none;
      }
    }
    th {
      font-size: 17px;
    }
  }

  tr {
    background-color: ${({ theme }): any => theme.colors.WEAK_WHITE};
  }

  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }): string => `7px solid  ${theme.colors.WHITE}`};
  }

  .ant-table-thead > tr > th{
    border-bottom: ${({ theme }): string => `7px solid  ${theme.colors.WHITE}`};
  }
`
