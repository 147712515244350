import styled from 'styled-components'
import { PoweroffOutlined } from '@ant-design/icons'

export const DropdownLink = styled.a`
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${({ theme }): any => theme.colors.WHITE} !important;
    
    a:hover, a:active, a:focus {
        color: ${({ theme }): any => theme.colors.WHITE};
    }
`

export const ExitButton = styled.button`
  color: ${({ theme }): any => theme.colors.WHITE} !important;
  font-weight: bold;
  width: 100%;
  height: 44px;
  border-style: hidden;
  border-radius: '3px';
  align-self: right;
  background-color: rgba(255, 255, 255, 0.0);
  font-size: 17px;
`

export const ExitIcon = styled(PoweroffOutlined)`
  color: ${({ theme }): any => theme.colors.WHITE} !important;
`

export const ExitDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`