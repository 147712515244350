import styled from 'styled-components'
import { Form, Input, Col, Button, Row } from 'antd'
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons'

export const WidgetBox = styled.div`
  overflow: scroll;
  width: 380px;
  height: 280px;
  text-align: left;
`
export const ButtonPadded = styled(Button)`
  margin-top: 10px
`
export const RowPadded = styled(Row)`
  margin-top: 10px
`
export const WidgetBox2 = styled.div`
  overflow: scroll;
  width: 380px;
  height: 280px;
  text-align: left;
  margin-left: 50px;
`
export const PaddedTop = styled(Col)`
  margin-top: 10%
`
export const PaddedCol = styled(Col)`
  padding-left: 10px; 
`
export const PaddedCol2 = styled(Col)`
  padding-left: 5px;
`
export const PaddedCol3 = styled(Col)`
  padding-left: 210px;
`
export const WidgetButton = styled.div`
  font-size: 16px;
  overflow-wrap: break-word;
  text-align: left;
  width: 350px;
  height: 30px;
  margin-top: 5px;
`
export const AddRemoveButton = styled.div`
  font-size: 16px;
  overflow-wrap: break-word;
  margin-bottom: 0px;
  width: auto;
  height: auto;
  text-align: left;
`

export const FormBox = styled.div`
  margin: 10px 10px auto 10px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;
  label{
    font-size: 16px;
  }
  span {
    cursor: pointer;
  }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  margin-top: 20px;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
`

export const CheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }): any => theme.colors.GREEN};
  margin-bottom: 50px;

  svg {
    height: 8em;
    width: 8em;
  }
`

export const IconInfo = styled(InfoCircleOutlined)`
    color: ${({ theme }): any => theme.colors.BLACK};
`

export const FormInput = styled(Input)`
    border-color: ${({ theme }): any => theme.colors.BLACK};
    border-style: solid;
    border-radius: 3px;
    border-width: 1.3px;
`

export const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    label {
        color: ${({ theme }): any => theme.colors.BLACK};
        font-weight: 400;
        font-size: 14px;
    }

    div {
        width: 30%;
        text-align: left;
        div {
            width: 100%;
        }
    }
`

export const SessionRefreshEmail = styled.div`
  display: flex;

  .position-items-session-mail {
    width: 100%;
    margin-right: 10px;
  }
`
export const SelectProject = styled.div`
  span {
    width: 155px;
  }
  .ant-form-item-control-input {
    padding-left: 20%;
  }

  .ant-form-item-explain-error {
    padding-left: 12%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-form-item-control-input {
      padding-left: 0;
    }

    .ant-form-item-explain-error {
      padding-left: 0;
    }
  }
`

export const InputClockName = styled.div`
  .ant-form-item-control-input {
    padding-left: 12%;
  }

  .ant-form-item-explain-error {
    padding-left: 12%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-form-item-control-input {
      padding-left: 0;
    }

    .ant-form-item-explain-error {
      padding-left: 0;
    }
  }
`

export const InputButton = styled.div`
  .ant-form-item-label {
    margin-left: 1.5%;
  }

  .ant-form-item-control-input {
    padding-left: 24.5%;
  }

  .ant-form-item-explain-error {
    padding-left: 24.5%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-form-item-control-input {
      padding-left: 0;
    }

    .ant-form-item-explain-error {
      padding-left: 0;
    }
  }
`