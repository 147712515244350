import styled from 'styled-components'
import { InfoCircleFilled } from '@ant-design/icons'

export const Content = styled.div`
  padding: 40px 40px 0px 40px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
`

export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 40px;
`

export const Info = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28, 74px;
  padding-bottom: 40px;
`

export const IconInfo = styled(InfoCircleFilled)`
  margin-right: 5px;
  svg {
    fill: ${({ theme }): any => theme.colors.YELLOW};
  }
`

export const Footer = styled.div`
  display: grid;
  justify-content: flex-end;
`

export const IconRow = styled.div`
  display: flex;
  width: 40px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoSpan = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
  font-size: 12px;
  cursor: pointer;
  gap: 9.34px;
  border: none;
  outline: none;
  background: none;
  min-width: 250px;
`