import { Rule } from 'antd/lib/form'
import { TFunction } from 'i18next'
import { TEmailConfig } from '.'
import { namespaces } from 'i18n/i18n.constants'

export const initialValues: TEmailConfig = {
  id: null,
  host: '',
  port: 99999,
  fromName: '',
  fromEmail: '',
  password: '',
  enableStartTls: false,
  isAuthenticatedEmail: false,
  intervalBulkEmailMs: 2000,
}

export const validation = (key: string, t: TFunction<'namespaces', undefined>) => {
  switch (key) {
    case 'host':
      return [{
        required: true,
        message: t('smtp server is mandatory', { ns: namespaces.pages.settings })
      }, {
        max: 99,
        message: t('cannot be more than 100 characters', { ns: namespaces.pages.settings })
      }] as Rule[]

    case 'port':
      return [
        {
          validator(_, value): Promise<any> {
            if (!value)
              return Promise.reject(t('smtp port is mandatory', { ns: namespaces.pages.settings }))

            const regexMaxCharacters = /^[\d]{0,100}$/
            if (value && !regexMaxCharacters.test(value))
              return Promise.reject(t('cannot be more than 100 characters', { ns: namespaces.pages.settings }))

            if (value > 99999)
              return Promise.reject(t('smtp port cannot exceed 99999', { ns: namespaces.pages.settings }))

            return Promise.resolve('')
          },
        },

      ] as Rule[]

    case 'fromEmail':
      return [{
        required: true,
        message: t('smtp user is mandatory', { ns: namespaces.pages.settings })
      },
      // {
      //   type: 'email',
      //   message: t('it is not a valid email', { ns: namespaces.common })
      // },
      {
        max: 100,
        message: t('cannot be more than 100 characters', { ns: namespaces.pages.settings })
      }] as Rule[]

    case 'fromName':
      return [{
        required: true,
        message: t('default sender name is mandatory', { ns: namespaces.pages.settings })
      },
      {
        max: 100,
        message: t('cannot be more than 100 characters', { ns: namespaces.pages.settings })
      }] as Rule[]

    case 'password':
      return [{
        required: true,
        message: t('password is required', { ns: namespaces.common })
      },
      {
        max: 100,
        message: t('cannot be more than 100 characters', { ns: namespaces.pages.settings })
      }] as Rule[]

    case 'enableStartTls':
      return [{
        type: 'boolean'
      }] as Rule[]

    case 'isAuthenticatedEmail':
      return [{
        type: 'boolean'
      }] as Rule[]

    case 'intervalBulkEmailMs':
      return [
        {
          validator(_, value): Promise<any> {
            if (value == null || value?.length == 0) {
              return Promise.reject(t('bulk interval mandatory', { ns: namespaces.pages.settings }))
            }

            const regexMaxCharacters = /^[\d]{1,10}$/
            if (value && !regexMaxCharacters.test(value))
              return Promise.reject(t('bulk interval characters', { ns: namespaces.pages.settings }))

            if (value > 2147483647)
              return Promise.reject(t('bulk interval max integer exceeded', { ns: namespaces.pages.settings }))

            if (value < 0)
              return Promise.reject(t('bulk timeout min', { ns: namespaces.pages.settings }))

            return Promise.resolve('')
          },
        },

      ] as Rule[]

    default:
      [] as Rule[]
  }
}
