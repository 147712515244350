import styled from 'styled-components'
import { Form, Input, Button as ButtonAntDesign } from 'antd'
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons'

export const ModalBody = styled.div`
  width: 100%;
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  justify-content: center;
  margin: 20px 20px auto 20px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;
`

export const FormItemForEmail = styled(Form.Item)`
  @media (min-width: 576px) {
    > .ant-form-item-row > .ant-form-item-label {
      flex-basis: 19.111020996%;
      max-width: 19.111020996%;
    }
  }

  @media (min-width: 768px) {
    > .ant-form-item-row > .ant-form-item-label {
      flex-basis: 18.210343564%;
      max-width: 18.210343564%;
    }
  }
`

export const ButtonRefreshEmail = styled(ButtonAntDesign)`
  color: ${({ theme }): any => theme.colors.WHITE};
  background-color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
  border-style: hidden;
  border-radius: 3px;
  border: 1px solid ${({ theme }): any => theme.colors.PRIMARY_BLUE};
  align-self: right;
  min-width: 46px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (max-width: 576px) {
    margin-top: 2.5rem;
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
`

export const FormBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 20px auto 20px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px;

  label{
    font-size: 16px;
  }

  span {
    cursor: pointer;
  }

  .button-refresh-mail {
    color: ${({ theme }): any => theme.colors.WHITE};
    background-color: ${({ theme }): any => theme.colors.PRIMARY_BLUE};
    border-style: hidden;
    border-radius: 3px;
    border: 1px solid ${({ theme }): any => theme.colors.PRIMARY_BLUE};
    align-self: right;
    min-width: 46px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .button-refresh-mail {
      margin-top: 2.5rem;
    }
  }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  margin-top: 20px;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
`

export const CheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }): any => theme.colors.GREEN};
  margin-bottom: 50px;

  svg {
    height: 8em;
    width: 8em;
  }
`

export const IconInfo = styled(InfoCircleOutlined)`
    color: ${({ theme }): any => theme.colors.BLACK};
`

export const FormInput = styled(Input)`
    border-color: ${({ theme }): any => theme.colors.BLACK};
    border-style: solid;
    border-radius: 3px;
    border-width: 1.3px;
`

export const FormItem = styled(Form.Item)`
    display: flex;
    justify-content: space-between;

    label {
        color: ${({ theme }): any => theme.colors.BLACK};
        font-weight: 400;
        font-size: 14px;
    }

    div {
        width: 30%;
        text-align: left;
        div {
            width: 100%;
        }
    }
`

export const SessionRefreshEmail = styled.div`
  display: flex;

  .position-items-session-mail {
    width: 100%;
    margin-right: 10px;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
    .position-items-session-mail {
      width: 100%;
      margin-right: 10px;
    }
  }
`

export const InputClockName = styled.div`
  .emailInputClass {
    width: 150%;
  }
  .ant-form-item-control-input {
    margin-left: 65px;
    width: 135%;
  }

  .ant-form-item-explain-error {
    padding-left: 12%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
    .ant-form-item-control-input {
      padding-left: 0;
      margin-left: 0.9rem;
      width: 24.5rem;
    }
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-form-item-control-input {
      padding-left: 0;
      margin-left: 0.1rem;
      width: 24.5rem;
    }
  }
`

export const InputButton = styled.div`
  .ant-form-item-label {
    margin-left: 1.5%;
  }

  .ant-form-item-control-input {
    padding-left: 24.5%;
  }

  .ant-form-item-explain-error {
    padding-left: 24.5%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-form-item-control-input {
      padding-left: 0;
    }

    .ant-form-item-explain-error {
      padding-left: 0;
    }
  }
`
export const InputJiraName = styled.div`
  .jiraInputClass {
    width: 150%;
  }
  .ant-form-item-control-input {
    margin-left: 0.2rem;
    width: 33.2rem;
  }

  .ant-form-item-explain-error {
    padding-left: 12%;
  }
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
    .ant-form-item-control-input {
      padding-left: 0;
      margin-left: 0.2rem;
      width: 24.5rem;
    }

    .ant-form-item-explain-error {
      padding-left: 0;
    }
  }
`