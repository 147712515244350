import SockJS from 'sockjs-client'
import { Client, IStompSocket } from '@stomp/stompjs'

const useSocket = () => {
  const socketUrl = process.env.REACT_APP_SOCKET_URL as string
  const sockJsUrl = process.env.REACT_APP_SOCKJS_URL as string

  const socketConnect = (): Client => {
    const stompClient = new Client({
      brokerURL: socketUrl,
      debug(msg) {
        console.log('Stomp client debug: ', msg)
      },
      reconnectDelay: 0,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      connectionTimeout: 5000,
    })

    if (typeof WebSocket !== 'function') {
      console.log('using SOCKJS')
      stompClient.webSocketFactory = (): IStompSocket => {
        return new SockJS(sockJsUrl) as IStompSocket
      }
    }

    return stompClient
  }

  return {
    socketConnect
  }
}

export default useSocket