import React from 'react'
import { Rule } from 'antd/lib/form'
import { FormLabelAlign } from 'antd/lib/form/interface'

import { Label } from 'components'
import { FormItem, FormInput } from './styles'

type TProps = {
  hidden?: boolean
  required?: boolean
  name: string
  label: string
  labelAlign?: FormLabelAlign | undefined
  rules?: Rule[]
  disabled?: boolean
  id: string
}

const CheckField = (props: TProps): JSX.Element => {

  const {
    hidden = false,
    required = false,
    label,
    labelAlign = 'left',
    name,
    rules,
    disabled = false,
    id,
    ...rest
  } = props

  const _label = <Label description={label} required={required} />

  return (
    <FormItem
      hidden={hidden}
      required={false}
      label={_label}
      labelAlign={labelAlign}
      name={name}
      rules={rules}
      valuePropName='checked'
    >
      <FormInput
        name={name}
        disabled={disabled}
        id={id}
        {...rest}
      />
    </FormItem>
  )

}


export default CheckField