import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  margin-bottom: 50px;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
`

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    width: 400px;
  }
`

export const CheckIcon = styled(CheckCircleFilled)`
  color: ${({ theme }): any => theme.colors.GREEN};
  margin-bottom: 50px;

  svg {
    height: 8em;
    width: 8em;
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`
