import { useState } from 'react'
import useRest, { ClientRequestConfig } from 'hooks/useRest'

export type TClientsResponse = TCommonResponse & {
  content: {
    clients: any[]
    pageable: TPageableResponse
  }
}

export type TClientsParams = {
  size?: number
  page?: number
  name?: string
  startDate: string
  endDate: string
  corporateName?: string
  costCenter?: string | number
}

export type TCollaborators = {
  id: number
  key?: string
  cpf: string
  name: string
  email: string
  enrollment: string
  admissionDate: string
  dismissalDate: string
  companyId: number
  jiraNameList: TRelationsJiraName[]
  createdDate: string
  updatedDate: string
}

export type TRelationsResponse = TCommonResponse & {
  content: TRelations
}

export type TRelations = {
  collaboratorId: number,
  collaboratorName: string,
  relationJiraName: TRelationsJiraName[]
}

export type TRelationBody = {
  collaboratorId: number
  relationJiraName: string
  jiraCompanyId: number
  idArray?: number
}

export type TRelationsJiraName = {
  id: number
  key?: number
  idArray?: number
  name: string
  jiraCompanyId: number
  jiraCompanyName: string
}

export type TCompaniesResponse = TCommonResponse & {
  content: TCompanies[]
}

export type TCompanies = {
  id: number
  name: string
  clients: []
}

export type TClientFrom_GetJiraCompanyAll = {

}

export type TJiraCompanyFrom_GetJiraCompanyAll = {
  id: number,
  name: string,
  clients: Array<TClientFrom_GetJiraCompanyAll>,
}

export type TResponseGetClient_JiraCompany_All = TCommonResponse & {
  requestId: string
  content: Array<TJiraCompanyFrom_GetJiraCompanyAll>
}

export type TClientFrom_Client_Pageable = {
  id: number
  cnpj: string
  corporateName: string
  fantasyName: string
  phoneNumber: string
  stateRegistration: string,
  municipalRegistration: string
  cnae: string
  email: string
  isActive: boolean
  jiraCompanyId: number
  jiraCompanyName: string
  projects: []
  key: number
}

export type TResponseGet_Client_Pageable = TCommonResponse & {
  content: {
    clientList: Array<TClientFrom_Client_Pageable>
    pageable: {
      pageNumber: number
      offset: number
      totalPages: number
      recordsPerPage: number
      sort: {
        field: string
        direction: string
      }
    },
  }
}
export type TResponseGet_Client = TCommonResponse & {
  content: {
    clientList: Array<TClientFrom_Client_Pageable>
  }
}
export type TResponseGet_Client_By_Client = TCommonResponse & {
  content: {
    clients: Array<TClientFrom_Client_Pageable>
  }
}

export type GetClientsByPageableFilterQueryParams = {
  client?: string
  company?: string
  offset?: number
  orderDir?: 'ASC' | 'DESC'
  orderField?: 'client' | 'company'
  page?: number
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  sort?: {
    sorted?: boolean
    unsorted?: boolean
  }
  unpaged?: boolean
}

export type TDeleteResponse = TCommonResponse

const useClients = () => {

  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getCancelToken = () => {
    return rest.generateCancelToken()
  }

  const getClients = async (
    params?: TClientsParams,
    triggerLoading = true
  ): Promise<TClientsResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/client/all'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as TClientsResponse
  }

  const getClientsWithFilter = async (
    params?: TClientsParams,
    triggerLoading = true
  ): Promise<TClientsResponse> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/client/filter'
    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as TClientsResponse
  }

  const getClientById = async (
    id: string,
    triggerLoading = true
  ) => {
    if (triggerLoading) setIsLoading(true)
    const url = `/client/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response as TCommonResponse & { content: any }
  }

  const getAllJiraCompany = async (triggerLoading = true): Promise<TResponseGetClient_JiraCompany_All> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/client/jira-company/all'
    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response as TResponseGetClient_JiraCompany_All
  }

  const getClientsByPageableFilter = async (
    triggerLoading = true,
    queryParams?: GetClientsByPageableFilterQueryParams,
    clientConfigs?: ClientRequestConfig
  ): Promise<TResponseGet_Client_Pageable> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/client/pageable'
    const response = await rest.get(url, queryParams, undefined, clientConfigs)
    if (triggerLoading) setIsLoading(false)
    return response as TResponseGet_Client_Pageable
  }
  const getClientsByClientAndCompany = async (
    triggerLoading = true,
    queryParams?: GetClientsByPageableFilterQueryParams,
    clientConfigs?: ClientRequestConfig
  ): Promise<TResponseGet_Client_By_Client> => {
    if (triggerLoading) setIsLoading(true)
    const url = 'client/findClientByClientAndCompany'
    const response = await rest.get(url, queryParams, undefined, clientConfigs)
    if (triggerLoading) setIsLoading(false)
    return response as TResponseGet_Client_By_Client
  }

  const getAllCostCenter = async (
    triggerLoading = true,
    projectId: number,
    clientId: string
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = `client/find-all-cost-center-pk/${clientId}/${projectId}`
    const response = await rest.get(url, {})
    if (triggerLoading) setIsLoading(false)
    return response !== null ? response?.content : null
  }
  const updateCostCenter = async (
    triggerLoading = true,
    projectId: number,
    costCenter: string[]
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = 'client/update-cost-center/'
    const response = await rest.put(url, {
      'costCenterIds': costCenter,
      projectId,
    })
    return response
  }

  return {
    isLoading,
    getClients,
    getClientById,
    getClientsWithFilter,
    getAllJiraCompany,
    getClientsByPageableFilter,
    getClientsByClientAndCompany,
    getCancelToken,
    getAllCostCenter,
    updateCostCenter,
  }

}

export default useClients