import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'
import { namespaces } from 'i18n/i18n.constants'

import {
  Container,
} from './styles'

import {
  Calendar,
  HourAnalysis,
  CostCenter,
  FileIn,
  FileView,
  Mail,
  ContributorsRelate
} from 'components/Icons'
import ConsolidatedIcon from 'components/Icons/Consolidated'
import './sidebarstyles.scss'
import Description from 'components/Icons/Description'
import EmployeeRegister from 'components/Icons/EmployeeRegister'

type TItems = {
  key: string
  path: string
}

enum Uri {
  MY_HOURS = '/my-hours',
  MY_CERTIFICATES = '/my-certificates',
  MY_MDUS = '/my-mdus',
  EMPLOYEES = '/employees',
  EMPLOYEES_UPDATE = '/employees/update',
  EMPLOYEES_RELATE = '/employees/relate',
  COST_CENTER_RELATE = '/costcenter/relate',
  EMPLOYEES_REGISTER = '/employees/register',
  EMPLOYEES_LIST_PJ = '/employees/listPj',
  ANALYSIS_HOURS = '/analysis-hours',
  ANALYSIS_DESCRIPTION = '/analysis-description',
  FILE_IMPORTS = '/import-file',
  VIEW_LIST_FILE = '/view-list-files',
  CONFIGURATION_EMAIL = '/settings/email',
  PERIOD = '/settings/period',
  VIEW_FILE = '',
  CONSOLIDATED = '/consolidated',
}

const items: TItems[] = [
  {
    key: '1',
    path: Uri.MY_HOURS,
  },
  {
    key: '2',
    path: Uri.MY_CERTIFICATES,
  },
  {
    key: '3',
    path: Uri.MY_MDUS,
  },
  {
    key: '4',
    path: Uri.EMPLOYEES,
  },
  {
    key: '5',
    path: Uri.EMPLOYEES_UPDATE,
  },
  {
    key: '6',
    path: Uri.EMPLOYEES_RELATE,
  },
  {
    key: '7',
    path: Uri.ANALYSIS_HOURS,
  },
  {
    key: '8',
    path: Uri.FILE_IMPORTS,
  },
  {
    key: '9',
    path: Uri.VIEW_LIST_FILE,
  },
  {
    key: '10',
    path: Uri.CONFIGURATION_EMAIL
  },
  {
    key: '11',
    path: Uri.CONSOLIDATED,
  },
  {
    key: '12',
    path: Uri.PERIOD
  },
  {
    key: '13',
    path: Uri.COST_CENTER_RELATE,
  },
  {
    key: '14',
    path: Uri.ANALYSIS_DESCRIPTION
  }
]

type TProps = {
  isCollapsedSidebar: boolean
}

const Sidebar = ({ isCollapsedSidebar }: TProps): JSX.Element => {

  const { t } = useTranslation('namespaces')
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const mainPath = `/${pathname.split('/')[1]}`

  const selectedKey = useMemo(() => {
    const findedItem = items.find((item) => item.path === mainPath)
    if (!findedItem) return ''

    return findedItem.key
  }, [mainPath])

  const onClickGoToPage = (path: string) => (): void => navigate(path)

  return (
    <Container>
      <Menu
        className='TrackerSidebar'
        defaultSelectedKeys={[selectedKey]}
        mode='inline'
        inlineCollapsed={isCollapsedSidebar}
        items={[
          // {
          //   type: 'group',
          //   key: 'g1',
          //   label: t('myInformations', { ns: namespaces.layout }),
          //   className: 'TrackerSidebar__GroupItem',
          //   children: [
          //     {
          //       key: '1',
          //       icon: <Calendar />,
          //       onClick: onClickGoToPage(Uri.MY_HOURS),
          //       label: t('myHours', { ns: namespaces.layout }),
          //       className: 'TrackerSidebar__MenuItem',
          //       title: t('myHours', { ns: namespaces.layout })
          //     },
          //     {
          //       key: '2',
          //       icon: <Certificate />,
          //       onClick: onClickGoToPage(Uri.MY_CERTIFICATES),
          //       label: t('myCertificates', { ns: namespaces.layout }),
          //       className: 'TrackerSidebar__MenuItem',
          //       title: t('myCertificates', { ns: namespaces.layout })
          //     },
          //     {
          //       key: '3',
          //       icon: <Mdus />,
          //       className: 'TrackerSidebar__MenuItem',
          //       onClick: onClickGoToPage(Uri.MY_MDUS)
          //     }
          //   ]
          // },

          {
            type: 'group',
            key: 'g2',
            label: t('administrative', { ns: namespaces.layout }),
            className: 'TrackerSidebar__GroupItem',
            children: [
              // {
              //   key: '4',
              //   icon: <Contributors />,
              //   onClick: onClickGoToPage(Uri.EMPLOYEES),
              //   label: t('employees', { ns: namespaces.layout }),
              //   className: 'TrackerSidebar__MenuItem',
              //   title: t('employees', { ns: namespaces.layout }),
              // },
              // {
              //   key: '5',
              //   icon: <ContributorsRefresh />,
              //   onClick: onClickGoToPage(Uri.EMPLOYEES_UPDATE),
              //   label: t('employeesUpdate', { ns: namespaces.layout }),
              //   className: 'TrackerSidebar__MenuItem',
              //   title: t('employeesUpdate', { ns: namespaces.layout }),
              // },
              {
                key: '6',
                icon: <ContributorsRelate />,
                onClick: onClickGoToPage(Uri.EMPLOYEES_RELATE),
                label: t('employeesRelate', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('employeesRelate', { ns: namespaces.layout }),
              },
              {
                key: '13',
                icon: <CostCenter />,
                onClick: onClickGoToPage(Uri.COST_CENTER_RELATE),
                label: t('costCenterRelate', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('costCenterRelate', { ns: namespaces.layout }),
              },
              {
                key: '19',
                icon: <EmployeeRegister />,
                onClick: onClickGoToPage(Uri.EMPLOYEES_LIST_PJ),
                label: t('employeesRegister', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('employeesRegister', { ns: namespaces.layout }),
              },
            ]
          },

          {
            type: 'group',
            key: 'g3',
            label: t('analysisHours', { ns: namespaces.layout }),
            className: 'TrackerSidebar__GroupItem',
            children: [
              {
                key: '14',
                icon: <Description />,
                onClick: onClickGoToPage(Uri.ANALYSIS_DESCRIPTION),
                label: 'Análise de descrição',
                className: 'TrackerSidebar__MenuItem',
                title: 'Análise de descrição',
              },
              {
                key: '7',
                icon: <HourAnalysis />,
                onClick: onClickGoToPage(Uri.ANALYSIS_HOURS),
                label: t('analysisHours', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('analysisHours', { ns: namespaces.layout }),
              },
              {
                key: '8',
                icon: <FileIn />,
                onClick: onClickGoToPage(Uri.FILE_IMPORTS),
                label: t('fileImport', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('fileImport', { ns: namespaces.layout }),
              },
              {
                key: '9',
                icon: <FileView />,
                onClick: onClickGoToPage(Uri.VIEW_LIST_FILE),
                label: t('viewFile', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('viewFile', { ns: namespaces.layout }),
              },
              {
                key: '11',
                icon: <ConsolidatedIcon />,
                onClick: onClickGoToPage(Uri.CONSOLIDATED),
                label: t('consolidated', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('consolidated', { ns: namespaces.layout }),
              },
            ]
          },

          {
            type: 'group',
            key: 'g4',
            label: t('configurations', { ns: namespaces.layout }),
            className: 'TrackerSidebar__GroupItem',
            children: [
              {
                key: '10',
                icon: <Mail />,
                onClick: onClickGoToPage(Uri.CONFIGURATION_EMAIL),
                label: t('emailConfiguration', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('emailConfiguration', { ns: namespaces.layout }),
              },
              {
                key: '12',
                icon: <Calendar />,
                onClick: onClickGoToPage(Uri.PERIOD),
                label: t('close_period', { ns: namespaces.layout }),
                className: 'TrackerSidebar__MenuItem',
                title: t('close_period', { ns: namespaces.layout }),
              },
            ]
          },
        ]}
      />
    </Container>
  )
}

export default Sidebar