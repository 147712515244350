import React from 'react'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
// import { Avatar, Dropdown, Menu } from 'antd'
// import { DownOutlined } from '@ant-design/icons'
import {
  // DropdownLink,
  ExitButton,
  // ExitIcon,
  // ExitDiv
} from './styles'
// import UserImage from 'assets/user.png'

const Profile = (): JSX.Element => {
  const { t } = useTranslation('namespaces')
  const auth = useAuth()

  // const items = (
  //   <Menu
  //     items={[
  //       {
  //         key: '0',
  //         label: t('myProfile', { ns: namespaces.layout }),
  //         title: t('myProfile', { ns: namespaces.layout }),
  //       },
  //       {
  //         key: '1',
  //         onClick: auth.removeUserData,
  //         label: t('exit', { ns: namespaces.layout }),
  //         title: t('exit', { ns: namespaces.layout }),
  //       }
  //     ]}
  //   />
  // )
  return (
    // <ExitDiv >
    //   <ExitIcon />
    <ExitButton onClick={auth.removeUserData} id='exitButton'> {t('exit', { ns: namespaces.layout })} </ExitButton>
    // </ExitDiv>
  )

  // return (
  //   <Dropdown
  //     overlay={items}
  //     trigger={['click']}
  //     placement='bottomRight'
  //   >
  //     <DropdownLink>
  //       <Avatar src={UserImage} />
  //       <DownOutlined />
  //     </DropdownLink>
  //   </Dropdown>
  // )
}

export default Profile