import React, { useEffect, useState, } from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { Form, Input, notification } from 'antd'
import DatePicker from 'components/DatePicker'
import { Button, RelateModal } from 'components'
import { Content, FormBody } from './styles'
import useEmployees from 'repositories/useEmployees'
import { ResponseWrapper, isRequestLibraryError } from 'hooks/useRest'
import { useTranslation } from 'react-i18next'
import { TPj } from 'repositories/useTimeAnalysis'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/pt_BR'

type TProps = {
  open: boolean
  handleClose: () => void
  handleGetClose: () => void
  keyModal: number
  employee: TPj
}

const EmployeesRegister = (props: TProps): JSX.Element => {
  const {
    open,
    handleClose,
    handleGetClose,
    employee,
  } = props
  const { t } = useTranslation('namespaces')
  const [localDate, setLocalDate] = useState(moment())
  const dateFormat = 'DD/MM/YYYY'
  const [formPJ] = Form.useForm()
  const employees = useEmployees()

  useEffect(() => {
    setLocalDate(moment(employee.employeeAdmissionDate ?? moment()))
    formPJ.setFieldValue('admissionDate', moment(employee.employeeAdmissionDate ?? moment()))
    formPJ.setFieldValue('nameInput', employee.employeeName)
    formPJ.setFieldValue('emailInput', employee.employeeEmail)
  }, [employee])

  return (
    <RelateModal
      id={'id'}
      title={
        employee.employeeId
          ? t('modalTitleEdit', { ns: namespaces.pages.employeesRegister })
          : t('modalTitleCreate', { ns: namespaces.pages.employeesRegister })
      }
      isModalVisible={open}
      key={Math.random()}
      handleClose={handleClose}
      handleConfirmation={(): void => { }}
    >
      <Content>
        <FormBody>
          <Form
            form={formPJ}
            labelCol={{ span: 8 }}
            labelAlign='left'
            onFinish={
              async (values): Promise<void> => {
                let response: ResponseWrapper | undefined = undefined
                let responseError: ResponseWrapper | undefined = undefined

                try {
                  const date = moment(values.admissionDate).format('YYYY-MM-DD')
                  response = await employees.createPjEmployee(
                    {
                      data: {
                        admissionDate: date,
                        collaboratorId: employee.employeeId ?? null,
                        email: values.emailInput,
                        name: values.nameInput,
                      },
                      developerOptions: {
                        returnResponseRef: true,
                      }
                    }
                  )
                } catch (error: any) {
                  if (isRequestLibraryError(error)) {
                    responseError = error?.response
                  }
                }

                switch (true) {
                  case (response?.status === 201):
                    handleGetClose()
                    notification.success({
                      message: employee.employeeId == null
                        ? t('registerSuccess', { ns: namespaces.pages.employeesRegister })
                        : t('editSuccess', { ns: namespaces.pages.employeesRegister }),
                      placement: 'topRight',
                    })
                    break

                  case (
                    responseError?.status === 400
                    && responseError?.data.message == 'Field validation error'
                  ):
                    notification.error({
                      message: t('validationError', { ns: namespaces.pages.employeesRegister }),
                      placement: 'topRight',
                    })
                    break

                  default:
                    notification.error({
                      message: t('registerError', { ns: namespaces.pages.employeesRegister }),
                      placement: 'topRight',
                    })
                    break
                }
              }
            }
            onFinishFailed={
              (errorInfo): void => {
                console.log('EmployeesRegister form error', errorInfo)

                notification.error({
                  message: t('validationError', { ns: namespaces.pages.employeesRegister }),
                  placement: 'topRight',
                })
              }
            }
          >
            <Form.Item
              name='nameInput'
              id='nameInput'
              label={t('name', { ns: namespaces.pages.employeesRegister })}
              rules={[
                {
                  max: 100,
                  message: t('validationNameMax100', { ns: namespaces.pages.employeesRegister })
                },
                {
                  required: true,
                  message: t('validationNameReq', { ns: namespaces.pages.employeesRegister })
                }
              ]}
              initialValue={employee.employeeName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='admissionDate'
              id='admissionDate'
              rules={[
                {
                  required: true,
                  message: t('validationAdmissionReq', { ns: namespaces.pages.employeesRegister })
                }
              ]}
              initialValue={localDate}
              label={t('admissionDate', { ns: namespaces.pages.employeesRegister })}
            >
              <DatePicker
                placeholder={employee.employeeAdmissionDate ?? t('date', { ns: namespaces.common })}
                format={dateFormat}
                locale={locale}
              />
            </Form.Item>
            <Form.Item
              label='Email'
              name='emailInput'
              rules={[
                {
                  max: 50,
                  message: t('validationEmailMax50', { ns: namespaces.pages.employeesRegister })
                },
                {
                  type: 'email',
                  message: t('validationTypeEmail', { ns: namespaces.pages.employeesRegister })
                }
              ]}
              id='emailInput'
              initialValue={employee.employeeEmail}
            >
              <Input />
            </Form.Item>
          </Form>
          <Button
            shape='default'
            id='button-filter'
            type='primary'
            label={t('save', { ns: namespaces.pages.employeesRegister })}
            disabled={false}
            onClick={
              (): void => {
                formPJ.submit()
              }
            }
          />
        </FormBody>
      </Content>
    </RelateModal>
  )
}

export default EmployeesRegister
