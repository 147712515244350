
import React from 'react'

import { Spinner, Content } from './styles'

type TProps ={
  text: string
}

const Loading = ({ text }: TProps): any => {

  return (
    <Content>
      <Spinner />
      <span>Loading ...</span>
      <p>
        {text}
      </p>
    </Content>
  )
}

export default Loading
