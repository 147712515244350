
import React, { useState, useEffect, useContext } from 'react'
import Moment from 'moment'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { RangeValue } from 'rc-picker/lib/interface'
import {
  MailOutlined,
  CloudDownloadOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons'
import { Form, Tooltip, Input, InputNumber, notification } from 'antd'
import useTimeAnalysis, {
  THourAnalysisParams,
  TJira,
  TIndividualTimeAnalysis,
  THourAnalysisWithoutPaginationParams,
  TApproveEmployeesParams
} from 'repositories/useTimeAnalysis'
import usePagination from 'hooks/usePagination'
import {
  DataTable,
  Button,
  DateRangePickerField,
  ButtonList
} from 'components'
import { downloadBase64File, formatDateToServer } from 'utils/helpers'
import {
  Content,
  Header,
  Title,
  SubTitle,
  Container,
  Tabs,
  FilterAnalysis,
  ApproveIcon,
  DisapproveIcon,
  DatePickerBox,
  SubRow,
  FilterButtonBox,
  NameInputBox,
  CellDescription,
  ActionsTable,
  DetailsIcon,
  NameInputBoxOnly,
  DiferenceInputBox,
} from './styles'
import { DialogContext } from 'hooks/dialog/context'
import ModalNotificationDivergence from './Modal/NotificationDivergence'
import ApproveEmployee from './Modal/ApproveEmployee'
import useSocket from 'hooks/useSocket'
import ModalExportReport from './Modal/ExportFile/ModalExportReport'
import DetailsDivergence from './Modal/DetailsDivergence'

type TColumn = {
  title: string
  dataIndex: string
  colSpan?: number
  render?: (text: string, record: object) => JSX.Element
  align?: string
  key?: string
  ellipsis?: boolean
}

const defaultColumnsHead: TColumn[] = [
  {
    title: 'name',
    dataIndex: 'name',
  },
  {
    title: 'punch clock hours',
    dataIndex: 'hoursAtPoint',
  },
]

const defaultColumnsTail: TColumn[] = [
  {
    title: 'time difference',
    dataIndex: 'timeDifference'
  },
  {
    title: 'status',
    dataIndex: 'status'
  }
]

const observationColumn: TColumn[] = [
  {
    title: 'observation',
    dataIndex: 'observation',
    ellipsis: true,
    render: (text: string, record: any): JSX.Element => (
      <Tooltip title={record.observation}>
        <CellDescription>{record.observation}</CellDescription>
      </Tooltip>
    )
  }
]

const AnalysisHours = (): JSX.Element => {
  const { t } = useTranslation('namespaces')
  const timeAnalysis = useTimeAnalysis()
  const pagination = usePagination()
  const { createDialog } = useContext(DialogContext)
  const [formDiferenceHours] = Form.useForm()
  const [intervalDate, setIntervalDate] = useState<RangeValue<moment.Moment>>()
  const [lessThan, setLessThan] = useState<number>()
  const [biggerThan, setBiggerThan] = useState<number>()
  const [checkButton, setCheckButton] = useState(false)
  const [approvedButton, setApprovedButton] = useState(false)
  const [divergence, setDivergence] = useState(true)
  const [isLoadingFilter, setIsLoadingFilter] = useState(false)
  const [data, setData] = useState<object[]>([])
  const [columns, setColumns] = useState<TColumn[]>(data !== null && data?.length > 0 ? defaultColumnsHead : [])
  const [selectedRows, setSelectedRows] = useState<object[]>([])
  const [tableMessage, setTableMessage] = useState<string>(
    t('select a time period to display the hours analysis!', { ns: namespaces.pages.analysisHours }))

  const [employeeName, setEmployeeName] = useState<string>('')
  const [employeeNameFiltered, setEmployeeNameFiltered] = useState<string>('')
  const socket = useSocket()
  const [filterDisagreementsOnly, setFilterDisagreementsOnly] = useState<boolean>(true)

  const approvedActionColumns: TColumn[] = [
    {
      title: 'actions',
      dataIndex: 'icon',
      key: 'action',
      align: 'center',
      render: (text: string, record: any): JSX.Element => (
        <>
          <ActionsTable>
            <Tooltip placement='bottom' title={t('approve', { ns: namespaces.common })}>
              <a onClick={(): void => openApproveEmployeeModal(record.key)}>
                <ApproveIcon />
              </a>
            </Tooltip>

            <Tooltip placement='bottom' title={t('details', { ns: namespaces.common })}>
              <a onClick={(): void => openDetailsDivergence(record.key)}>
                <DetailsIcon />
              </a>
            </Tooltip>
          </ActionsTable>
        </>
      )
    }
  ]

  const disapprovedActionColumns: TColumn[] = [
    {
      title: 'actions',
      dataIndex: 'icon',
      key: 'action',
      align: 'center',
      render: (text: string, record: any): JSX.Element => (
        <Tooltip placement='bottom' title={t('delete', { ns: namespaces.common })}>
          <a onClick={(): Promise<void> => disapproveSingleEmployee(record.key)}>
            <DisapproveIcon />
          </a>
        </Tooltip>
      )
    }
  ]

  const approveSingleEmployee = async (id: number, observation: string = ''): Promise<void> => {
    if (!intervalDate) return
    const [from, to] = intervalDate

    const params: TApproveEmployeesParams = {
      approvalList: [{
        employeeId: id,
        note: observation
      }],
      startPeriod: Moment(from).format('DD/MM/YYYY'),
      endPeriod: Moment(to).format('DD/MM/YYYY'),
    }
    const response = await timeAnalysis.approveEmployees(params)
    if (!response) return

    notification.success({
      message: t('the employee was successfully approved', { ns: namespaces.pages.analysisHours }),
      placement: 'topRight',
    })

    handleFilter()
  }

  const openDetailsDivergence = (id: number): void => {
    if (!intervalDate) return

    const [from, to] = intervalDate

    createDialog({
      id: 'modal',
      open: false,
      Component: DetailsDivergence,
      props: {
        id,
        open: true,
        startDate: from,
        endDate: to,
      }
    })
  }

  const openApproveEmployeeModal = (id: number): void => {
    createDialog({
      id: 'modal',
      open: false,
      Component: ApproveEmployee,
      props: {
        intervalDate,
        selectedRows,
        id,
        approveEmployee: approveSingleEmployee
      }
    })
  }

  const disapproveSingleEmployee = async (id: number): Promise<void> => {
    if (!intervalDate) return
    const [from, to] = intervalDate

    const params: TApproveEmployeesParams = {
      approvalList: [{
        employeeId: id,
        note: ''
      }],
      startPeriod: Moment(from).format('DD/MM/YYYY'),
      endPeriod: Moment(to).format('DD/MM/YYYY'),
    }
    const response = await timeAnalysis.disapproveEmployees(params)
    if (!response) return

    notification.success({
      message: t('the employee was successfully disapproved', { ns: namespaces.pages.analysisHours }),
      placement: 'topRight',
    })


    handleFilter()
  }

  /**
   * @name disapproveManyEmployee
   * @param arrEmployees object[]
   * @returns retorna a desaprovação de vários colaboradores ao mesmo tempo
   */
  const disapproveManyEmployee = async (arrEmployees: object[]): Promise<void> => {
    if (!intervalDate) return
    const [from, to] = intervalDate

    const targetDisapproveList: any = []
    arrEmployees.map((item: any) => {
      targetDisapproveList.push({
        employeeId: item.key,
        note: ''
      })
    })

    const params: TApproveEmployeesParams = {
      approvalList: targetDisapproveList!,
      startPeriod: Moment(from).format('DD/MM/YYYY'),
      endPeriod: Moment(to).format('DD/MM/YYYY'),
    }

    const response = await timeAnalysis.disapproveEmployees(params)
    if (!response) return

    notification.success({
      message: t('the employee was successfully disapproved', { ns: namespaces.pages.analysisHours }),
      placement: 'topRight',
    })

    handleFilter()
  }

  const openModal = (): void => {
    createDialog({
      id: 'modal',
      open: false,
      Component: ModalNotificationDivergence,
      props: {
        intervalDate,
        selectedRows
      }
    })
  }

  const sendEmailToAllDivergentUsers = (): void => {
    createDialog({
      id: 'modal',
      open: false,
      Component: ModalNotificationDivergence,
      props: {
        intervalDate,
        selectedRows,
        shouldSendToAll: true,
      }
    })
  }

  useEffect(() => {
    if (pagination.data.current) handleFilter()
  }, [pagination.data.current, pagination.data.pageSize])

  useEffect(() => {
    handleFilter()
  }, [checkButton, approvedButton])

  /* useEffect(() => {
    pagination.setDefaultPagination()
    handleFilter()
  }, [employeeNameFiltered]) */


  useEffect(() => {
    if (employeeName === '' && Boolean(intervalDate)) setEmployeeNameFiltered('')
  }, [employeeName])

  const buildTableColumns = (responseColumns: TJira[]): void => {
    if (!responseColumns?.length) return

    const mappedJiraColumns = responseColumns?.map((response) => ({
      title: response.name,
      dataIndex: response.name
    }))

    const mappedColumnsHead = defaultColumnsHead?.map((columns) => ({
      title: t(columns.title, { ns: namespaces.pages.analysisHours }),
      dataIndex: columns.dataIndex
    }))

    const mappedColumnsTail = defaultColumnsTail?.map((columns) => ({
      title: t(columns.title, { ns: namespaces.pages.analysisHours }),
      dataIndex: columns.dataIndex
    }))

    const mappedActionColumns = (approvedButton ? disapprovedActionColumns?.map((columns) => ({
      ...columns,
      title: t(columns.title, { ns: namespaces.common }),
    })) : approvedActionColumns?.map((columns) => ({
      ...columns,
      title: t(columns.title, { ns: namespaces.common }),
    })))

    const mappedObservationColumn = observationColumn?.map((columns) => ({
      ...columns,
      title: t(columns.title, { ns: namespaces.pages.analysisHours }),
      dataIndex: columns.dataIndex
    }))

    const buildColumnsWithTail = [
      ...(isLoadingFilter || data === null ? [] : mappedColumnsHead),
      ...mappedJiraColumns,
      ...(checkButton ? [] : mappedColumnsTail),
      ...(isLoadingFilter || !approvedButton ? [] : mappedObservationColumn),
      ...(isLoadingFilter || checkButton ? [] : mappedActionColumns),
    ]

    setColumns(buildColumnsWithTail)
  }

  const populateTable = (responseData: TIndividualTimeAnalysis[]): void => {
    const newData = responseData?.map((item) => {
      const reduced = item.hoursAtWorkedJira.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.companyJiraName]: curr.hoursAtWorked
        }
      }, {})
      return {
        key: item.employeeId,
        name: item.employeeName,
        email: item.employeeEmail,
        hoursAtPoint: item.hoursAtPoint,
        timeDifference: item.timeDifference,
        status: item.status,
        observation: item.observation,
        ...reduced
      }
    })
    setData(newData)
  }

  const handleApproved = (): boolean => {
    if (approvedButton || checkButton) return true
    return false
  }

  const handleFilter = async (): Promise<void> => {
    if (!intervalDate) return
    setIsLoadingFilter(true)
    const [from, to] = intervalDate

    const currentPage = pagination.data.current ?? 0
    const params: THourAnalysisParams = {
      startDate: formatDateToServer(from),
      endDate: formatDateToServer(to),
      lessThan,
      biggerThan,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      pageSize: pagination.data.pageSize ?? 1,
      onlyDivergence: !checkButton,
      name: employeeNameFiltered == '' && employeeName !== '' ? employeeName : employeeNameFiltered,
      approved: handleApproved()
    }

    const response = await timeAnalysis.requestAnalysis(params)
    setIsLoadingFilter(false)
    if (!response) return
    const { content } = response


    buildTableColumns(content?.jiras)
    pagination.changeTotalPages(content?.pageable.totalPages)
    setTableMessage(response.detailMessage)
    populateTable(content?.timeAnalysis)
  }

  const handleAgreementEmployees = (): void => {
    setLessThan(0)
    setBiggerThan(0)
    formDiferenceHours.resetFields()
    setDivergence(false)
    setApprovedButton(false)
    setCheckButton(true)
    setColumns([])
    setData([])
    setSelectedRows([])
    setFilterDisagreementsOnly(false)
    pagination.setDefaultPagination()
  }

  const handleDisagreementsEmployees = (): void => {
    setLessThan(0)
    setBiggerThan(0)
    formDiferenceHours.resetFields()
    setDivergence(true)
    setApprovedButton(false)
    setCheckButton(false)
    setColumns([])
    setData([])
    setSelectedRows([])
    setFilterDisagreementsOnly(true)
    pagination.setDefaultPagination()
  }

  const handleApprovedCheck = (): void => {
    setLessThan(0)
    setBiggerThan(0)
    formDiferenceHours.resetFields()
    setDivergence(true)
    setApprovedButton(true)
    setCheckButton(false)
    setColumns([])
    setData([])
    setSelectedRows([])
    setFilterDisagreementsOnly(false)
    pagination.setDefaultPagination()
  }

  const handleExport = async (): Promise<void> => {
    if (!intervalDate) return
    const stompClient = socket.socketConnect()

    stompClient.activate()

    stompClient.onConnect = async (frame): Promise<void> => {
      const [from, to] = intervalDate

      const params: THourAnalysisWithoutPaginationParams = {
        startDate: formatDateToServer(from),
        endDate: formatDateToServer(to),
        onlyDivergence: true,
        approved: true,
      }

      const response = await timeAnalysis.requestAnalysisToExport(params)

      if (!response) {
        stompClient.deactivate()
        return
      }

      createDialog({
        id: 'importing-files-modal',
        open: true,
        Component: ModalExportReport,
        props: {
          title: 'Relatório em processamento!'
        }
      })


      const subscription = stompClient.subscribe(`/topic/messages/${response.content.requestId}`, (messageOutput: any) => {
        const jsonParse = JSON.parse(messageOutput.body)

        downloadBase64File(
          'file-time-analysis.xlsx',
          jsonParse.body.content.timeAnalysisInBase64File,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )

        notification.success({
          message: t('the file was downloaded successfully', { ns: namespaces.pages.analysisHours }),
          placement: 'topRight',
        })

        subscription.unsubscribe()
        stompClient.deactivate()
      })
    }

    stompClient.onStompError = (frame) => {
      console.log('websocketError', frame)
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('websocketError', { ns: namespaces.common })
      })
      stompClient.deactivate()
    }
  }

  const buttonListItems = [
    {
      key: 3,
      title: t('send notification divergence to all', { ns: namespaces.pages.analysisHours }),
      icon: <MailOutlined />,
      disabled: checkButton || approvedButton,
      action: (): void => {
        sendEmailToAllDivergentUsers()
      }
    },
    {
      key: 1,
      title: t('send notification divergence', { ns: namespaces.pages.analysisHours }),
      icon: <MailOutlined />,
      disabled: checkButton || !selectedRows.length || approvedButton,
      action: (): void => openModal()
    },
    {
      key: 2,
      title: t('export data', { ns: namespaces.common }),
      disabled: checkButton || approvedButton,
      icon: <CloudDownloadOutlined />,
      action: (): Promise<void> => handleExport()
    },
    {
      key: 4,
      title: t('remove all selections', { ns: namespaces.pages.analysisHours }),
      icon: <UsergroupDeleteOutlined />,
      disabled: !approvedButton || !selectedRows.length,
      action: (): void => {
        disapproveManyEmployee(selectedRows)
      }
    },
  ]

  const changeInputValue = (value: string): void => {
    if (value.length < 50) setEmployeeName(value)
  }

  const changeInputLessThan = (value: string): void => {
    if (value == '' || value == 'undefined' || value == 'null' || value == '0') {
      setLessThan(undefined)
    } else {
      const isNan = !isNaN(parseInt(value))
      if (isNan) {
        setLessThan(parseInt(value))
      }
    }
  }

  const changeInputBiggerThan = (value: string): void => {
    if (value == '' || value == 'undefined' || value == 'null' || value == '0') {
      setBiggerThan(undefined)
    } else {
      const isNan = !isNaN(parseInt(value))
      if (isNan) {
        setBiggerThan(parseInt(value))
      }
    }
  }

  return (
    <Content>
      <Header>
        <Title>
          {t('title', { ns: namespaces.pages.analysisHours })}
        </Title>
        <SubRow>
          <SubTitle>
            {t('sub title', { ns: namespaces.pages.analysisHours })}
          </SubTitle>
          <ButtonList
            disabled={!data?.length}
            title={t('options', { ns: namespaces.common })}
            items={buttonListItems}
          />
        </SubRow>
      </Header>
      <Container>
        <FilterAnalysis>
          <DatePickerBox>
            {t('period', { ns: namespaces.common })}
            <DateRangePickerField
              onChangeValue={setIntervalDate}
            />
          </DatePickerBox>

          {
            filterDisagreementsOnly
              ? <>
                <NameInputBox>
                  {t('employeeName', { ns: namespaces.common })}
                  <Input
                    value={employeeName}
                    onChange={(event): void => changeInputValue(event.target.value)}
                    allowClear
                    disabled={!intervalDate}
                    onPressEnter={(): void => {
                      pagination.setDefaultPagination()
                      setEmployeeNameFiltered(employeeName)
                    }}
                  />
                </NameInputBox>
                <Form
                  name='diferenceHours'
                  form={formDiferenceHours}
                  className='form-position-inputs'
                >
                  <DiferenceInputBox>
                    {t('diference', { ns: namespaces.common })}
                    <Form.Item
                      name='biggerNumber'
                    >
                      <InputNumber
                        min={0}
                        placeholder={t('diference bigger', { ns: namespaces.common })}
                        value={biggerThan}
                        onChange={(event): void => changeInputBiggerThan(String(event))}
                        disabled={!divergence || approvedButton || !intervalDate}
                        onPressEnter={(): void => {
                          setBiggerThan(biggerThan)
                        }}
                      />
                    </Form.Item>
                  </DiferenceInputBox>

                  <DiferenceInputBox>
                    &nbsp;
                    <Form.Item
                      name='lessNumber'
                      dependencies={['biggerNumber']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value): Promise<any> {
                            if (value !== null && value < getFieldValue('biggerNumber'))
                              return Promise.reject(new Error(t('smaller canot be smaller than bigger', { ns: namespaces.pages.analysisHours })))

                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        min={0}
                        placeholder={t('diference smaller', { ns: namespaces.common })}
                        value={lessThan}
                        onChange={(event): void => changeInputLessThan(String(event))}
                        disabled={!divergence || approvedButton || !intervalDate}
                        onPressEnter={(): void => {
                          setLessThan(lessThan)
                        }}
                      />
                    </Form.Item>
                  </DiferenceInputBox>
                </Form>
              </>
              : <>
                <NameInputBoxOnly>
                  {t('employeeName', { ns: namespaces.common })}
                  <Input
                    value={employeeName}
                    onChange={(event): void => changeInputValue(event.target.value)}
                    allowClear
                    disabled={!intervalDate}
                    onPressEnter={(): void => {
                      pagination.setDefaultPagination()
                      setEmployeeNameFiltered(employeeName)
                    }}
                  />
                </NameInputBoxOnly>
              </>
          }

          <FilterButtonBox>
            <Button
              shape='default'
              id='button-filter'
              loading={isLoadingFilter}
              type='primary'
              label={t('filter', { ns: namespaces.common })}
              disabled={!intervalDate}
              onClick={async (): Promise<void> => {
                const { response } = await formDiferenceHours.validateFields()

                if (response === undefined) {
                  if (lessThan && biggerThan) {
                    if (isNaN(lessThan) && lessThan !== undefined || isNaN(biggerThan) && biggerThan !== undefined) {
                      return notification.error({
                        message: t('please input valid info', { ns: namespaces.pages.analysisHours }),
                        placement: 'topRight',
                      })
                    }
                  }

                  setIsLoadingFilter(true)
                  pagination.setDefaultPagination()
                  setEmployeeNameFiltered(employeeName)
                  handleFilter()
                  setIsLoadingFilter(false)
                }
              }}
            />
          </FilterButtonBox>
        </FilterAnalysis>
      </Container>
      <Container>
        <Tabs>
          <Button
            id='button-without-disagreements'
            type={(checkButton && !approvedButton) ? 'primary' : 'ghost'}
            label={t('employees without disagreements', { ns: namespaces.pages.analysisHours })}
            onClick={handleAgreementEmployees}
            disabled={isLoadingFilter}
            shape='round'
          />
          <Button
            id='button-with-disagreements'
            type={(!checkButton && !approvedButton) ? 'primary' : 'ghost'}
            label={t('employees with disagreements', { ns: namespaces.pages.analysisHours })}
            onClick={handleDisagreementsEmployees}
            disabled={isLoadingFilter}
            shape='round'
          />
          <Button
            id='button-approved-employees'
            type={approvedButton ? 'primary' : 'ghost'}
            label={t('approved employees', { ns: namespaces.pages.analysisHours })}
            onClick={handleApprovedCheck}
            disabled={isLoadingFilter}
            shape='round'
          />
        </Tabs>
      </Container>
      <DataTable
        pagination={pagination.data}
        data={data}
        columns={columns}
        loading={timeAnalysis.isLoading}
        onChange={pagination.onChangeTable}
        selectableRow={true}
        setSelectedRows={setSelectedRows}
        emptyMessage={t(tableMessage, { ns: namespaces.pages.analysisHours })}
      />
    </Content>
  )
}

export default AnalysisHours