import styled from 'styled-components'
import { List } from 'antd'

export const Content = styled.div`
  padding: 30px 25px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
  height: auto;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`
export const Header = styled.div`
  padding-bottom: 10px;
`
export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 20px;
`

export const GridList = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  font-weight: 700;

  span:last-child {
    text-align: center;
  }
`

export const ListItem = styled(List.Item)`
  display: grid;
  grid-template-columns: 60% 40%;

  span:last-child {
    text-align: center;
  }
`

export const CustomList = styled(List)`
  margin: 0 75px 0 50px;
`