import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'

import { TablePaginationConfig } from 'antd'

const defaultPagination: TablePaginationConfig = {
  defaultCurrent: 1,
  current: 1,
  total: 1,
  pageSize: 10,
  disabled: false,
  showSizeChanger: true,
  pageSizeOptions: [5, 10, 20, 50],
  size: 'small',
  showQuickJumper: true
}

const usePagination = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination)

  const buildPaginationConfig = (): TablePaginationConfig => {
    return {
      ...pagination,
      locale: {
        items_per_page: t('items per page', { ns: namespaces.table }),
        jump_to: t('jump to', { ns: namespaces.table }),
        page: t('page', { ns: namespaces.table })
      }
    } as TablePaginationConfig
  }

  useEffect(() => {
    const _pagination = buildPaginationConfig()
    setPagination(_pagination)
  }, [])

  const onChangeTable = (paginationConfig: TablePaginationConfig): void => {
    const _pagination: TablePaginationConfig = {
      ...paginationConfig,
      current: paginationConfig.current ?? 0
    }

    setPagination(_pagination)
  }

  const changeTotalPages = (totalPages: number): void => {
    setPagination((old) => ({ ...old, total: totalPages * Number(old?.pageSize) }))
  }

  const setDefaultPagination = (): void => {
    const _pagination = {
      ...buildPaginationConfig(),
      ...defaultPagination
    }
    setPagination(_pagination)
  }

  return {
    data: pagination,
    setPagination,
    setDefaultPagination,
    onChangeTable,
    changeTotalPages
  }
}

export default usePagination