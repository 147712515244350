import React, { useMemo } from 'react'
import { Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import { Card, Container } from './styles'
import { UploadFile } from 'antd/lib/upload/interface'


type TProps = {
  onchangeUploadFile: (file: any) => void
  disable: boolean
  maxFile?: number
  isMultipleFile?: boolean
  fileList?: UploadFile<any>[] | undefined
}

const UploadButton = (props: TProps): JSX.Element => {
  const {
    onchangeUploadFile,
    disable,
    maxFile = 1,
    isMultipleFile = false,
    fileList
  } = props

  const { t } = useTranslation('namespaces')

  const { Dragger } = Upload

  const _props = useMemo(() => ({
    name: 'file',
    maxCount: maxFile,
    fileList,
    multiple: isMultipleFile,
    accept: '.xlsx',
    onChange(info: any): void {
      if (isMultipleFile) {
        onchangeUploadFile(info.fileList)
      } else {
        onchangeUploadFile(info.file)
      }
    }
  }), [disable, maxFile, isMultipleFile, fileList])

  return (
    <Container>
      <Dragger
        {..._props}
        showUploadList={false}
        disabled={disable}
        beforeUpload={(): boolean => false}
      >
        <Card>
          <p className='ant-upload-hint'>
            <CloudUploadOutlined />
            {t('drag and drop files, or click here to add them.', { ns: namespaces.common })}
          </p>
        </Card>
      </Dragger>
    </Container>
  )

}

export default UploadButton