import React from 'react'
import Modal from 'components/Modal'
import { Header, Title, LogBox, ClockIcon, BoldText, IconDiv } from './styles'


type TProps ={
  open: boolean
  handleClose: ()=>void
  title:string
}

const ModalExportReport = (props:TProps): JSX.Element => {

  const { open, handleClose, title } = props

  return (
    <Modal width={700} id='ant-modal-log' isModalVisible={open} handleClose={handleClose}>
      <div>
        <Header>
          <Title> {title} </Title>
        </Header>
        <IconDiv>
          <ClockIcon />
        </IconDiv>
        <LogBox>
          <p>
            Sua requisição foi feita com sucesso! Aguarde alguns minutos para o download. Ele será iniciado automaticamente.
          </p>
          <BoldText>
            Importante: O tempo de espera pode variar conforme o tamanho do relatório.
          </BoldText>
        </LogBox>
      </div>
    </Modal>
  )
}
export default ModalExportReport