import { namespaces } from 'i18n/i18n.constants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldBox, FieldDesciption, FieldExample, FieldTitle } from './TemplateJiraOrAttendanceStyle'

const TemplateAppoint = (): JSX.Element => {
  const { t } = useTranslation('namespaces')

  const timeExample = new Date().toLocaleTimeString('pt-br', { hour: '2-digit', minute: '2-digit' })
  const dateExample = new Date().toLocaleDateString('pt-br')
  return (
    <>
      <FieldBox>
        <FieldTitle>Nome</FieldTitle>
        <FieldDesciption>{t('employee description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: Maria Silva</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Admissão</FieldTitle>
        <FieldDesciption>{t('employee admission description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  {dateExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Data referência</FieldTitle>
        <FieldDesciption>{t('attendance register description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  {dateExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Atraso</FieldTitle>
        <FieldDesciption>{t('delay time description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Falta</FieldTitle>
        <FieldDesciption>{t('absence description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Extra executado</FieldTitle>
        <FieldDesciption>{t('overtime description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}:  {timeExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Abono</FieldTitle>
        <FieldDesciption>{t('allowance time description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>

      <FieldBox>
        <FieldTitle>Base</FieldTitle>
        <FieldDesciption>{t('base time description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>
      <FieldBox>
        <FieldTitle>Total trabalhado valorado</FieldTitle>
        <FieldDesciption>{t('total work with appreciation description', { ns: namespaces.template.importFile })}</FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>
      <FieldBox>
        <FieldTitle>Trabalho sem valorização</FieldTitle>
        <FieldDesciption>
          {t('total work without appreciation description', { ns: namespaces.template.importFile })}
        </FieldDesciption>
        <FieldExample>{t('example', { ns: namespaces.template.importFile })}: {timeExample}</FieldExample>
      </FieldBox>
    </>

  )
}

export default TemplateAppoint