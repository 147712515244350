import React, {
  useContext,
  useCallback,
  useMemo
} from 'react'

import { DialogContext } from '../../hooks/dialog/context'

import { Card } from './styles'

type TProps = {
  handleClose: () => void
  handleConfirmation?: () => void
  isModalVisible: boolean,
  children: React.ReactChild
  id: string,
  hasButtons?: boolean
  width?: number
  transitionName?: string
  maskTransitionName?: string
}

const Modal = (props: TProps): JSX.Element => {

  const { handleClose, handleConfirmation, isModalVisible, children, id, hasButtons, width, transitionName, maskTransitionName } = props

  const { removeDialog } = useContext(DialogContext)

  const close = useCallback(
    () => {
      handleClose()
    },
    [id, handleClose, removeDialog]
  )

  const footer = useMemo(() => hasButtons ? hasButtons : null, [hasButtons])

  return (
    <Card
      open={isModalVisible}
      width={width}
      footer={footer}
      onOk={handleConfirmation}
      onCancel={close}
      maskTransitionName={maskTransitionName}
      transitionName={transitionName}
    >
      {children}
    </Card>
  )
}

export default Modal