import React, { useMemo } from 'react'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import useEmployees, { TCompanies, TRelationsJiraName } from 'repositories/useEmployees'

import {
  CustomList,
  GridList,
  ListItem
} from './styles'

type TProps = {
  relations: TRelationsJiraName[]
  companies: TCompanies[]
}

type TRelationItem = {
  name: string
  jira: string
}

const RelateContent = (props: TProps): JSX.Element => {
  const {
    relations,
    companies
  } = props
  const { t } = useTranslation('namespaces')
  const employees = useEmployees()

  const data = useMemo(() => relations.map((relation) => {
    const findCompanyName = companies.find((company) => company.id === relation.jiraCompanyId)
    return {
      name: relation.name,
      jira: String(findCompanyName?.name)
    }
  }), [relations, companies])

  const header = (
    <GridList>
      <span>{t('nameJira', { ns: namespaces.common })}</span>
      <span>{t('company', { ns: namespaces.pages.employeesRelate })}</span>
    </GridList>
  )

  return (
    <>
      {data.length ? (
        <CustomList
          loading={employees.isLoading}
          bordered
          size='small'
          header={header}
          dataSource={data}
          renderItem={(item): JSX.Element => {
            const _item = item as TRelationItem
            return (
              <ListItem key={Math.random()}>
                <span>{_item.name}</span>
                <span>{_item.jira}</span>
              </ListItem>
            )
          }}
        />
      ) : (
        <Empty
          description={t('not related yet', { ns: namespaces.pages.employeesRelate })}
        />
      )}
    </>

  )
}

export default RelateContent
