import styled from 'styled-components'

export const Wrapper = styled.div` 
    padding: 30px;
`

export const Title = styled.h1`
    color: ${({ theme }): any => theme.colors.BLACK};
    font-weight: 400;
    font-size: 40px;
`
export const SubTitle = styled.h2`
    color: ${({ theme }): any => theme.colors.BLACK};
    font-size: 14px;
    font-weight: 300;
`