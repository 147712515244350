import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { Rule } from 'antd/lib/form'
import { LoadingOutlined } from '@ant-design/icons'
import { FormLabelAlign } from 'antd/lib/form/interface'

import { Label } from 'components'
import { IconInfo, FormItem, FormInput, FormInputPassword } from './styles'

type TProps = {
  hidden?: boolean
  required?: boolean
  type?: string
  name: string
  label: string | React.ReactNode
  labelAlign?: FormLabelAlign | undefined
  placeholder?: string
  rules?: Rule[]
  disabled?: boolean
  loading?: boolean
  icon?: React.ReactElement
  haveTooltip?: boolean
  textTooltip?: string
  id: string
  value?: string
}

const TextField = (props: TProps): JSX.Element => {
  const {
    hidden = false,
    required = false,
    label,
    labelAlign = 'left',
    type = 'text',
    value = '',
    name,
    placeholder,
    rules,
    disabled = false,
    id,
    loading = false,
    icon,
    haveTooltip = false,
    textTooltip,
    ...rest
  } = props

  const visible = useMemo(() => {
    if (loading) return <LoadingOutlined />

    if (icon) return icon

    if (haveTooltip) return <IconInfo />

    return <></>
  }, [loading, haveTooltip, icon, textTooltip])

  const _label = typeof label === 'string'
    ? <Label description={String(label)} required={required} />
    : label

  return (
    <FormItem
      hidden={hidden}
      required={false}
      label={_label}
      labelAlign={labelAlign}
      name={name}
      rules={rules}
    >
      {type === 'text' ? (
        <FormInput
          type={type}
          allowClear
          value={value ?? undefined}
          defaultValue={value ?? undefined}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          suffix={
            (haveTooltip || loading || icon) && (
              <Tooltip title={textTooltip}>
                {visible}
              </Tooltip>
            )
          }
          {...rest}
        />
      ) : (
        <FormInputPassword
          type={type}
          allowClear
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          {...rest}
        />
      )}
    </FormItem>
  )
}

export default TextField
