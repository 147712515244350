import React from 'react'
import { Form } from 'antd'
import DatePicker from 'components/DatePicker'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { Rule } from 'antd/lib/form'
import { FormLabelAlign } from 'antd/lib/form/interface'

import { Label } from 'components'
import { Moment } from 'moment'

type TProps = {
  required?: boolean
  name: string
  label: string
  labelAlign?: FormLabelAlign | undefined
  placeholder?: string
  rules?: Rule[]
  disabled?: boolean
  loading?: boolean
  id: string
  initialValue?: Moment
}

const DatePickerField = (props: TProps): JSX.Element => {
  const {
    required = false,
    label,
    labelAlign = 'left',
    name,
    id,
    rules,
    disabled,
    placeholder,
    initialValue,
    ...rest
  } = props

  const _label = <Label description={label} required={required} />

  return (
    <Form.Item
      required={false}
      label={_label}
      labelAlign={labelAlign}
      name={name}
      rules={rules}
      id={id}
    >
      <DatePicker
        style={{ width: '100%' }}
        disabled={disabled}
        locale={locale}
        defaultValue={initialValue}
        format='DD/MM/YYYY'
        placeholder={placeholder}
        {...rest}
      />
    </Form.Item>
  )
}

export default DatePickerField
