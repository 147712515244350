import React, { createContext, useContext, useState, useEffect } from 'react'
import restApi, { storageKey } from 'services/axios'

export type TUserData = {
  access_token: string
  expires_in: number
  refresh_expires_in: number
  refresh_token: string
  token_type: string
  session_state: string
  scope: string
}

type TUseAuth = {
  isSignedIn: boolean
  userData?: TUserData
  hasToken(): boolean
  saveUserData(user: TUserData): void
  removeUserData(): void
}

const AuthContext = createContext<TUseAuth>({} as TUseAuth)

type TProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: TProps): JSX.Element => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [userData, setUserData] = useState<TUserData>()

  useEffect(() => {
    const storageUserData = localStorage.getItem(storageKey)
    if (!storageUserData) return

    const parsedUserData = JSON.parse(storageUserData)
    setUserData(parsedUserData)
    setIsSignedIn(true)
  }, [])

  const saveUserData = (user: TUserData): void => {
    localStorage.setItem(storageKey, JSON.stringify(user))
    setUserData(user)
    setIsSignedIn(true)
    setToken(user.access_token)
  }

  const removeUserData = (): void => {
    localStorage.removeItem(storageKey)
    setUserData(undefined)
    setIsSignedIn(false)
  }

  const setToken = (token: string): void => {
    restApi.defaults.headers.Authorization = `Bearer ${token}`
  }

  const hasToken = (): boolean => Boolean(localStorage.getItem(storageKey))

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        userData,
        hasToken,
        saveUserData,
        removeUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): TUseAuth => {
  const context = useContext(AuthContext)

  return context
}

export { useAuth, AuthProvider }
