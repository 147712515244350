import React, { useState, useEffect } from 'react'
import { RelateModal } from 'components'
import { Form, Select, Row, Col, Input, Button, notification } from 'antd'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import useClients from 'repositories/useClient'

import {
  FormBox,
  SelectProject,
  WidgetBox,
  WidgetBox2,
  WidgetButton,
  PaddedCol,
  PaddedCol2,
  PaddedCol3,
  PaddedTop,
  ButtonPadded,
  RowPadded,
} from './styles'
type TProps = {
  open: boolean
  handleClose: () => void
  keyModal: number
  client: string,
  companyName: string,
  record: any,
  id: string,
  projects: [
    {
      id: number,
      isActive: boolean,
      name: string,
      costCenters: [{ code: string, }]
    }
  ],
}

const ModalCostCenterForm = (props: TProps): JSX.Element => {
  const {
    open,
    handleClose,
    client,
    companyName,
    projects,
    id,
    record,
  } = props
  const clientRepository = useClients()
  const { t } = useTranslation('namespaces')
  const [costCenters, setCostCenters] = useState<Array<string>>([])
  const [formInstance] = Form.useForm()
  const [selectedForIncludeIndexes, setSelectedForIncludeIndexes] = useState<Array<string>>([])
  const [selectedForExcludeIndexes, setSelectedForExcludeIndexes] = useState<Array<string>>([])
  const [isSelected, setIsSelected] = useState(false)
  const [selectedProjectId, setSlectedProjectId] = useState<number | null>(null)
  const [selectedCostCenters, setSelectedCostCenters] = useState<Array<string>>([])
  const [searchText, setSearchText] = useState('')
  const [searchExcludeText, setSearchExcludeText] = useState('')
  const [loading, setLoading] = useState(false)
  const setSelectedForIncludeMethod = async (selected: React.SetStateAction<string[]>): Promise<void> => {
    setSelectedForIncludeIndexes(selected)

  }
  const setSelectedForExcludeMethod = async (selected: React.SetStateAction<string[]>): Promise<void> => {
    setSelectedForExcludeIndexes(selected)

  }
  const changeSelectedForIncludeIndexes = async (index: string): Promise<void> => {
    const temp = []
    for (const key in selectedForIncludeIndexes) {
      temp.push(selectedForIncludeIndexes[key])
    }
    if (temp.includes(index)) {
      temp.splice(temp.indexOf(index))
    } else {
      temp.push(index)
    }
    const myElement = document.getElementById('includeBox')
    const topPos = myElement?.scrollTop ?? 0
    await setSelectedForIncludeMethod(temp)
    document.getElementById('includeBox')?.scrollBy(0, topPos)
  }
  const changeSelectedForExcludeIndexes = async (index: string): Promise<void> => {
    const temp = []
    for (const key in selectedForExcludeIndexes) {
      temp.push(selectedForExcludeIndexes[key])
    }
    if (temp.includes(index)) {
      temp.splice(temp.indexOf(index))
    } else {
      temp.push(index)
    }
    const myElement = document.getElementById('excludeBox')
    const topPos = myElement?.scrollTop ?? 0
    await setSelectedForExcludeMethod(temp)
    document.getElementById('excludeBox')?.scrollBy(0, topPos)
  }
  const addMethod = (): void => {
    const tempInside: React.SetStateAction<string[]> = []
    const tempNotInside: React.SetStateAction<string[]> = []
    for (const i in selectedCostCenters) {
      if (selectedCostCenters[i] !== '' && !tempInside.includes(selectedCostCenters[i]))
        tempInside.push(selectedCostCenters[i])
    }
    for (const i in costCenters) {
      if (costCenters[i] != '' && !selectedForIncludeIndexes.includes(costCenters[i]) && !tempNotInside.includes(costCenters[i]))
        tempNotInside.push(costCenters[i])
    }
    for (const key in selectedForIncludeIndexes) {
      if (selectedForIncludeIndexes[key] !== '' && !tempInside.includes(selectedForIncludeIndexes[key]))
        tempInside.push(selectedForIncludeIndexes[key])
    }
    setSelectedCostCenters(tempInside)
    setCostCenters(tempNotInside)
    setSelectedForIncludeIndexes([])
  }
  const removeMethod = (): void => {
    const tempNotInside: React.SetStateAction<string[]> = []
    const tempInside: React.SetStateAction<string[]> = []
    for (const i in costCenters) {
      if (costCenters[i] !== '' && !tempNotInside.includes(costCenters[i]))
        tempNotInside.push(costCenters[i])
    }
    for (const i in selectedCostCenters) {
      if (selectedCostCenters[i] !== '' && !selectedForExcludeIndexes.includes(selectedCostCenters[i]) && !tempInside.includes(selectedCostCenters[i]))
        tempInside.push(selectedCostCenters[i])
    }
    for (const key in selectedForExcludeIndexes) {
      if (selectedForExcludeIndexes[key] !== '' && !tempNotInside.includes(selectedForExcludeIndexes[key]))
        tempNotInside.push(selectedForExcludeIndexes[key])
    }
    setCostCenters(tempNotInside)
    setSelectedCostCenters(tempInside)
    setSelectedForExcludeIndexes([])
  }
  const saveMethod = async (): Promise<void> => {
    if (selectedProjectId !== null) {
      setLoading(true)
      try {
        await clientRepository.updateCostCenter(true, selectedProjectId, selectedCostCenters)

        notification.success({
          message: t('success', { ns: namespaces.common }),
          description: t('success', { ns: namespaces.pages.costCenterRelate })
        })

        setLoading(false)
      } catch (error) {
        console.log(error)

        notification.error({
          message: t('error', { ns: namespaces.common }),
          description: t('error', { ns: namespaces.pages.costCenterRelate })
        })

        setLoading(false)
      }
    }
    setLoading(false)
  }
  const getAllCostCenterByProject = async (projectId: number): Promise<void> => {
    let response
    setSlectedProjectId(projectId)
    setIsSelected(true)
    try {
      response = await clientRepository.getAllCostCenter(true, projectId, record['jiraCompanyId'],)
      let temp = []
      temp = []
      setCostCenters([])
      for (const ind in response['costCenter']) {
        if (response['costCenter'][ind] !== '')
          temp.push(response['costCenter'][ind])
      }
      setCostCenters(temp as [string])
      setSelectedCostCenters([])
      for (const _key in projects) {
        const _temp = []
        if (projects[_key].id === projectId) {
          for (const i in projects[_key].costCenters) {
            if (projects[_key].costCenters[i].code !== '')
              _temp.push(projects[_key].costCenters[i].code)
          }
          setSelectedCostCenters(_temp)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleCloseLocal = (): void => {
    setCostCenters([])
    setIsSelected(false)
    setSelectedCostCenters([])
    setSelectedForExcludeIndexes([])
    setSlectedProjectId(null)
    handleClose()
  }
  useEffect(() => {
    setCostCenters([])
  }, [])
  return (
    <RelateModal
      id={id}
      title={t('attach', { ns: namespaces.pages.costCenterRelate })}
      isModalVisible={open}
      key={Math.random()}
      handleClose={handleCloseLocal}
      handleConfirmation={/* () => ChangeModal() */(): void => { }}
    >
      {(
        <FormBox key={Math.random()}>
          <Form
            name='formRefresh'
            labelCol={{ span: 7 }}
            labelAlign='left'
          >
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  label={t('company', { ns: namespaces.pages.costCenterRelate })}
                >
                  <Input
                    value={companyName}
                    placeholder={companyName}
                    disabled
                  />
                </Form.Item>
              </Col>
              <PaddedCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  label={t('client', { ns: namespaces.pages.costCenterRelate })}
                >
                  <Input
                    name='client'
                    id='clientName'
                    disabled={true}
                    placeholder={client}
                    value={client}
                  />
                </Form.Item>
              </PaddedCol>
              <PaddedCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                xl={{ span: 6 }}
              >
                <Form.Item
                  label={t('project', { ns: namespaces.pages.costCenterRelate })}
                >
                  <SelectProject>
                    <Select
                      placeholder={t('project', { ns: namespaces.pages.costCenterRelate })}
                      id={'select'}
                      onChange={(_valor: any): '' | Promise<void> => {
                        if (typeof _valor === 'number') {
                          return getAllCostCenterByProject(_valor)
                        }
                        return ''
                      }}
                      defaultValue={selectedProjectId ?? ''}
                      options={projects.map((options) => ({
                        label: options.name,
                        value: options.id
                      }))}
                    />
                  </SelectProject>
                </Form.Item>
              </PaddedCol>
              {
                isSelected && costCenters.length > 0 ?
                  (
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form
                              form={formInstance}
                            >
                              <Form.Item
                                name={'filter'}
                                id='filter'
                              >
                                <Input
                                  placeholder={t('placeholder_input_cost_center', { ns: namespaces.pages.costCenterRelate })}
                                  allowClear
                                  onPressEnter={(): void => { setSearchText(formInstance.getFieldValue('filter')) }}
                                />
                              </Form.Item>
                            </Form>
                          </Col>
                          <PaddedCol>
                            <Button
                              id='button-add'
                              type='primary'
                              onClick={(): void => { setSearchText(formInstance.getFieldValue('filter')) }}
                            >
                              {t('filter', { ns: namespaces.pages.costCenterRelate })}
                            </Button>
                          </PaddedCol>
                          <PaddedCol2>
                            <Button
                              id='button-add'
                              type='ghost'
                              onClick={(): void => { setSearchText('') }}
                            >
                              {t('clean', { ns: namespaces.pages.costCenterRelate })}
                            </Button>
                          </PaddedCol2>
                        </Row>
                      </Col>
                      <PaddedCol3>
                        <Row>
                          <Col>
                            <Form
                              form={formInstance}
                            >
                              <Form.Item
                                name={'filterExclude'}
                                id='filterExclude'
                              >
                                <Input
                                  placeholder={t('placeholder_input_cost_center', { ns: namespaces.pages.costCenterRelate })}
                                  allowClear
                                  onPressEnter={(): void => { setSearchExcludeText(formInstance.getFieldValue('filterExclude')) }}
                                />
                              </Form.Item>
                            </Form>
                          </Col>
                          <PaddedCol>
                            <Button
                              id='button-add'
                              type='primary'
                              onClick={(): void => { setSearchExcludeText(formInstance.getFieldValue('filterExclude')) }}
                            >
                              {t('filter', { ns: namespaces.pages.costCenterRelate })}
                            </Button>
                          </PaddedCol>
                          <PaddedCol2>
                            <Button
                              id='button-add'
                              type='ghost'
                              onClick={(): void => { setSearchExcludeText('') }}
                            >
                              {t('clean', { ns: namespaces.pages.costCenterRelate })}
                            </Button>
                          </PaddedCol2>
                        </Row>
                      </PaddedCol3>
                    </Row>
                  ) : (<div />)
              }
            </Row>
          </Form>
          {
            isSelected && costCenters.length > 0 ?
              (
                <div>
                  <Row gutter={[24, 24]} justify='center'>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 11 }}
                      xl={{ span: 11 }}
                    >
                      <WidgetBox id='includeBox'>
                        {costCenters.filter(
                          (cc) => cc.toUpperCase().includes(searchText.toUpperCase())
                        ).map((teste) => {
                          return (
                            <WidgetButton
                              key={`${teste}-button-div`}
                              id={teste}
                            >
                              <Button
                                disabled={false}
                                type={selectedForIncludeIndexes.includes(teste) ? 'primary' : 'ghost'}
                                id={`${teste}-button`}
                                onClick={(): Promise<void> => changeSelectedForIncludeIndexes(teste)}
                                loading={false}
                              >
                                {`${teste}`}
                              </Button>
                            </WidgetButton>
                          )
                        })}
                      </WidgetBox>
                    </Col>
                    <PaddedTop
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 2 }}
                      xl={{ span: 2 }}
                    >
                      <Row justify='center' align='middle'>
                        <Button
                          id='button-add'
                          type='primary'
                          onClick={(): void => addMethod()}
                        >
                          {t('add', { ns: namespaces.pages.costCenterRelate })}
                        </Button>
                        <ButtonPadded
                          id='button-remove'
                          type='primary'
                          onClick={selectedForExcludeIndexes.length > 0 ? (): void => removeMethod() : (): void => { }}
                        >
                          {t('remove', { ns: namespaces.pages.costCenterRelate })}
                        </ButtonPadded>
                      </Row>
                    </PaddedTop>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 11 }}
                      xl={{ span: 11 }}
                    >
                      <WidgetBox2 id='excludeBox'>
                        {selectedCostCenters.filter(
                          (cc) => cc.toUpperCase().includes(searchExcludeText.toUpperCase())
                        ).map((teste) => {
                          return (
                            <WidgetButton
                              key={`cost-center-${teste}`}
                            >
                              <Button
                                disabled={false}
                                id='button-add'
                                type={selectedForExcludeIndexes.includes(teste) ? 'primary' : 'ghost'}
                                onClick={(): Promise<void> => changeSelectedForExcludeIndexes(teste)}
                                loading={false}
                              >
                                {`${teste}`}
                              </Button>
                            </WidgetButton>
                          )
                        }
                        )}
                      </WidgetBox2>
                    </Col>
                  </Row>
                </div>
              ) : (<div> </div>)
          }
          <RowPadded justify='end' align='bottom'>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 3 }}
              xl={{ span: 3 }}
            >
              <Button
                shape='default'
                disabled={false}
                id='button-confirm'
                type='ghost'
                onClick={(): void => handleCloseLocal()}
                loading={false}
              >
                {t('cancel', { ns: namespaces.pages.costCenterRelate })}
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 3 }}
              xl={{ span: 3 }}
            >
              <Button
                shape='default'
                disabled={false}
                id='button-confirm'
                type='primary'
                onClick={(): Promise<void> => saveMethod()}
                loading={loading}
              >
                {t('save', { ns: namespaces.pages.costCenterRelate })}
              </Button>
            </Col>
          </RowPadded>
        </FormBox>
      )
      }
    </RelateModal>
  )
}

export default ModalCostCenterForm