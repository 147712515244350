import React from 'react'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'

import { Container, Title, Text } from './styles'

const Home = (): JSX.Element => {
  const { t } = useTranslation('namespaces')

  return (
    <Container>
      <Title>{ t('welcome', { ns: namespaces.pages.home }) }</Title>
      <Text>
        { t('sub title', { ns: namespaces.pages.home }) }
      </Text>
      <Text>
        { t('info description', { ns: namespaces.pages.home }) }
      </Text>
      <Text>
        { t('explore and use to its maximum!', { ns: namespaces.pages.home }) }
      </Text>
      <Text>
        { t('team info', { ns: namespaces.pages.home }) }
      </Text>
    </Container>
  )
}

export default Home