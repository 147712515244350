import { Form, FormInstance, Select, Spin, Button, Row, Col, notification } from 'antd'
import { DateRangePickerField } from 'components'
import { TOptions } from 'i18next'
import moment, { Moment } from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import * as XLSX from 'xlsx'
import { RangeValue } from 'rc-picker/lib/interface'
import useClients from 'repositories/useClient'
import useConsolidatedReport from 'repositories/useConsolidatedReport'
import { namespaces } from 'i18n/i18n.constants'
import { useTranslation } from 'react-i18next'
import { downloadBase64File, convertFileExcelToJson, convertBase64ToFile } from 'utils/helpers'
import { DialogContext } from 'hooks/dialog/context'
import ModalDownloadingReport from '../Modal/ModalDownloadingReport'
import useSocket from 'hooks/useSocket'
import { DownloadIconWhite, SessionBodyFormInputs } from '../styles'
import { DownloadOutlined } from '@ant-design/icons'


type TProps = {
  employeeOrCustomer: string;
  form: FormInstance;
  handleWaitingRequest: (flagRequest: boolean) => void;
}

type TClient = {
  id: number
  cnpj: string
  corporateName: string
  projects: TProject[]
}

type TProject = {
  id: number
  name: string
  costCenters: TCostCenter[]
}

type TCostCenter = {
  id: number
  code: string
}

export const ByClient: React.FC<TProps> = ({ form, employeeOrCustomer, handleWaitingRequest }) => {
  const { t } = useTranslation()
  const [clientOptions, setClientOption] = useState<TOptions[] | undefined>([])
  const [isClientOptionsLoading, setIsClientOptionsLoading] = useState<boolean>(false)
  const [projectOptions, setProjectOptions] = useState<TOptions[] | undefined>([])
  const [costCenterOptions, setCostCenterOptions] = useState<TOptions[] | undefined>([])
  const [clients, setClients] = useState<TClient[] | undefined>([])
  const [selectedClient, setSelectedClient] = useState<TClient | undefined>()
  const [hasDate, setHasDate] = useState<boolean>(false)
  const clientRepository = useClients()
  const reportRepository = useConsolidatedReport()
  const { createDialog } = useContext(DialogContext)
  const socket = useSocket()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (employeeOrCustomer !== 'customer') {
      setCostCenterOptions(undefined)
      setProjectOptions(undefined)
    }
  }, [employeeOrCustomer])

  useEffect(() => {
    setLoading(true)
    handleWaitingRequest(true)
    loadClients()
    setLoading(false)
    handleWaitingRequest(false)
  }, [])

  const loadClients = async (): Promise<void> => {
    setIsClientOptionsLoading(true)
    const resp = await clientRepository.getClients()
    setIsClientOptionsLoading(false)
    if (!resp) return
    const tmpClientOptions = resp.content.clients.map((client) => {
      return { label: client.corporateName, value: client.id }
    })
    setClients(resp.content.clients)
    setClientOption(tmpClientOptions)
  }

  const handleSelectDate: React.Dispatch<RangeValue<moment.Moment>> = (dateArray: RangeValue<moment.Moment>) => {
    if (!dateArray) {
      setHasDate(false)
      return
    }
    const values = form.getFieldsValue()
    values['period'] = dateArray
    form.setFieldsValue(values)
    setHasDate(true)
  }

  const handleExport = (): void => {
    setLoading(true)
    handleWaitingRequest(true)

    const stompClient = socket.socketConnect()
    stompClient.activate()

    stompClient.onConnect = async (frame): Promise<void> => {
      form.validateFields().then(async (value) => {
        const [startDate, endDate] = value.period.map((date: Moment) => moment(date).format('YYYY-MM-DD'))
        const params = {
          startDate,
          endDate,
          clientId: value.customer,
          projectId: value.project,
          costCenterId: value.costCenter,
        }

        const response = await reportRepository.getApprovedTime(params)

        if (!response) {
          stompClient.deactivate()
          return
        }

        createDialog({
          id: 'importing-files-modal',
          open: true,
          Component: ModalDownloadingReport,
          props: {
            title: 'Relatório em processamento!'
          }
        })

        const subscription = stompClient.subscribe(`/topic/messages/${response.content.requestId}`, (messageOutput) => {
          const jsonParse = JSON.parse(messageOutput.body)

          const resultConvertFile = convertBase64ToFile(
            `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${jsonParse.body.content.reportBase64File}`,
            'nameTargetFile'
          )

          // convert file to json
          const fileReader = new FileReader()
          const resultConvertJson = new Promise((resolve, reject) => {
            fileReader.onload = (evt): void => {
              const arrayBufferString = evt?.target?.result
              const workbookTarget = XLSX.read(arrayBufferString, { type: 'binary' })
              const workSheetName = workbookTarget.SheetNames[0]
              const workSheetTarget = workbookTarget.Sheets[workSheetName]
              const dataJson = XLSX.utils.sheet_to_csv(workSheetTarget, {})
              resolve(convertFileExcelToJson(dataJson))
            }
            fileReader.onerror = (error): void => reject(error)
          })
          fileReader.readAsBinaryString(resultConvertFile)

          resultConvertJson.then((convertJson: any): void => {
            if (convertJson && convertJson.length <= 1) {
              notification.warning({
                message: t('no results were found for generating the file', { ns: namespaces.pages.analysisHours }),
                placement: 'topRight',
              })
              subscription.unsubscribe()

              setLoading(false)
              handleWaitingRequest(false)
              stompClient.deactivate()

              return
            }

            downloadBase64File(
              'relatorio_de_consolidação.xlsx',
              jsonParse.body.content.reportBase64File,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
            notification.success({
              message: t('the file was downloaded successfully', { ns: namespaces.pages.analysisHours }),
              placement: 'topRight',
            })
            subscription.unsubscribe()
            setLoading(false)
            handleWaitingRequest(false)
            stompClient.deactivate()

          })
        })
      }).catch((err) => {
        setLoading(false)
        handleWaitingRequest(false)

        console.error(err)
        stompClient.deactivate()
      })
    }

    stompClient.onStompError = (frame) => {
      console.log('websocketError', frame)
      notification.error({
        message: t('error', { ns: namespaces.common }),
        description: t('websocketError', { ns: namespaces.common })
      })
      stompClient.deactivate()
    }
  }

  const handleSelectClient = async (value: number): Promise<void> => {
    form.setFieldsValue({
      project: undefined,
      costCenter: undefined
    })

    if (!value) {
      setSelectedClient(undefined)
      setProjectOptions(undefined)
      return
    }
    const mappedClients = clients?.map((client) => {
      return client.id
    })

    const clientIndex = mappedClients?.indexOf(value)

    if (!clients) return
    if (!(typeof clientIndex === 'number')) return

    const mappedProjects = clients[clientIndex].projects.map((project) => {
      return { label: project.name, value: project.id }
    })

    setSelectedClient(clients[clientIndex])
    setProjectOptions(mappedProjects)
  }

  const handleSelectProject = (value: number): void => {
    form.setFieldsValue({
      costCenter: undefined
    })

    if (!value) {
      setCostCenterOptions(undefined)
      return
    }
    const mappedProjects = selectedClient?.projects.map((project) => {
      return project.id
    })

    const projectIndex = mappedProjects?.indexOf(value)

    if (!(typeof projectIndex === 'number')) return

    const mappedCostCenters = selectedClient?.projects[projectIndex].costCenters.map((costCenter) => {
      return { label: costCenter.code, value: costCenter.id }
    })

    setCostCenterOptions(mappedCostCenters)
  }

  return (
    <Spin spinning={loading}>
      <SessionBodyFormInputs>
        <Row className='prop-inputs' gutter={[8, 24]}>
          <Col span={7}>
            <span className='span-input'>
              {t('title_period', { ns: namespaces.pages.consolidated })}
            </span>
          </Col>
          <Col span={17}>
            <Form.Item
              name='period'
            >
              <DateRangePickerField onChangeValue={handleSelectDate} />
            </Form.Item>
          </Col>
        </Row>

        <Row className='prop-inputs' gutter={[8, 24]}>
          <Col span={7}>
            <span className='span-input'>
              {t('title_select_customer', { ns: namespaces.pages.consolidated })}
            </span>
          </Col>
          <Col span={17}>
            <Form.Item
              name='customer'
            >
              <Select
                placeholder={t('select customer', { ns: namespaces.pages.consolidated })}
                options={clientOptions}
                onChange={handleSelectClient}
                disabled={clientRepository.isLoading}
                loading={isClientOptionsLoading}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>


        <Row className='prop-inputs' gutter={[8, 24]}>
          <Col span={7}>
            <span className='span-input'>
              {t('title_select_project', { ns: namespaces.pages.consolidated })}
            </span>
          </Col>
          <Col span={17}>
            <Form.Item
              name='project'
            >
              <Select
                placeholder={t('select project', { ns: namespaces.pages.consolidated })}
                options={projectOptions}
                onChange={handleSelectProject}
                disabled={!form.getFieldValue('customer')}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>


        <Row className='prop-inputs' gutter={[8, 24]}>
          <Col span={7}>
            <span className='span-input'>
              {t('title_select_cost_center', { ns: namespaces.pages.consolidated })}
            </span>
          </Col>
          <Col span={17}>
            <Form.Item
              name='costCenter'
            >
              <Select
                placeholder={t('select cost center', { ns: namespaces.pages.consolidated })}
                options={costCenterOptions}
                disabled={!form.getFieldValue('project')}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        {
          !hasDate ? (
            <Row gutter={[8, 24]}>
              <Col span={7} />
              <Col span={17}>
                <Form.Item>
                  <Button
                    shape='default'
                    size='large'
                    id='disabled-export-button'
                    className='export-icon border-buttons-default'
                    disabled
                  >
                    <DownloadOutlined size={36} />
                    {t('export_file', { ns: namespaces.pages.consolidated })}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )
            : (
              <Row gutter={[8, 24]}>
                <Col span={7} />
                <Col span={17}>
                  <Form.Item>
                    <Button
                      shape='default'
                      size='large'
                      id='report-export-button'
                      className='export-icon button-export-consolidated border-buttons-default'
                      onClick={handleExport}
                      disabled={loading}
                    >
                      <DownloadIconWhite size={36} />
                      {t('export_file', { ns: namespaces.pages.consolidated })}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )
        }
      </SessionBodyFormInputs>
    </Spin>
  )
}
