import React, { useEffect } from 'react'
import { useAuth } from 'hooks/useAuth'
import { storageKey } from 'services/axios'
import useLogin from 'repositories/useLogin'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { namespaces } from 'i18n/i18n.constants'
import { Form, Input } from 'antd'
import { initialValues, validation } from './validations'
import {
  Container,
  Cover,
  LoginBox,
  BackgroundIMG,
  Logo,
  Title,
  Label,
  LoginButton,
  CompanySignature
} from './styles'
import LogoIMG from 'assets/loginLogo.png'


export type TLogin = {
  email: string
  password: string
}

const Login = (): JSX.Element => {
  const login = useLogin()
  const auth = useAuth()
  const navigate = useNavigate()
  const [form] = Form.useForm<TLogin>()
  const { t } = useTranslation('namespaces')

  useEffect(() => {
    const requestExistToken = Boolean(localStorage.getItem(storageKey))
    if (requestExistToken) navigate('/')
  }, [])

  const userLogin = async (values: TLogin): Promise<void> => {
    const body = new URLSearchParams()
    body.append('grant_type', 'password')
    body.append('username', values.email)
    body.append('password', values.password)
    body.append('client_id', process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string)

    const response = await login.requestToken(body)
    if (!response) return
    auth.saveUserData(response.data)
    navigate('/')
  }

  return (
    <Container>
      <Cover>
        <BackgroundIMG>
          <Logo src={LogoIMG} alt='logo' />
        </BackgroundIMG>
      </Cover>
      <LoginBox>
        <Title>Login</Title>
        <Form
          name='basic'
          initialValues={initialValues}
          layout='vertical'
          onFinish={userLogin}
          form={form}
          autoComplete='off'
        >
          <Form.Item
            label={<Label>{t('email', { ns: namespaces.common })}: </Label>}
            rules={validation('email', t)}
            name='email'
            id='input-email'
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<Label>{t('password', { ns: namespaces.common })}: </Label>}
            rules={validation('password', t)}
            name='password'
            id='input-email'
          >
            <Input.Password />
          </Form.Item>
          <LoginButton
            type='primary'
            htmlType='submit'
            loading={login.isLoading}
          >
            {t('login', { ns: namespaces.common })}
          </LoginButton>
        </Form>

        <CompanySignature>
          Developed with ♥ by
          <a
            href='https://monitoratec.com.br'
            target='_blank'
            className='linkGreenCompany'
            rel='noreferrer'
          >
            &nbsp;@Monitora
          </a>
        </CompanySignature>
      </LoginBox>
    </Container>
  )
}

export default Login