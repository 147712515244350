import { namespaces } from 'i18n/i18n.constants'

export const pt = {
  [namespaces.layout]: {
    myInformations: 'Minhas informações',
    administrative: 'Administrativo',
    configurations: 'Configurações',
    myHours: 'Minhas horas',
    myCertificates: 'Meus certificados',
    myMdus: 'Meus MDUs',
    employees: 'Colaboradores',
    employeesUpdate: 'Atualizar colaboradores',
    employeesRelate: 'Relacionar colaboradores',
    employeesRegister: 'Cadastro de colaborador',
    costCenterRelate: 'Relacionar centro de custo',
    analysisHours: 'Análise de horas',
    fileImport: 'Importar arquivos',
    viewFile: 'Consultar arquivos',
    emailConfiguration: 'Email de notificação',
    myProfile: 'Meu perfil',
    consolidated: 'Consolidados',
    exit: 'Sair',
    close_period: 'Fechar período'
  },
  [namespaces.common]: {
    edit: 'Editar',
    date: 'Data',
    options: 'Opções',
    search: 'Pesquisar',
    login: 'Entrar',
    password: 'Senha',
    cancel: 'Cancelar',
    name: 'Nome',
    nameClock: 'Nome no relógio de ponto',
    nameJira: 'Nome no Jira',
    action: 'Ação',
    age: 'Idade',
    address: 'Endereço',
    associate: 'Associar',
    neighborhoods: 'Bairro',
    city: 'Cidade',
    state: 'Estado',
    area: 'Área',
    leadership: 'Liderança',
    status: 'Status',
    email: 'Email',
    save: 'Salvar',
    'drag and drop files, or click here to add them.': 'Arraste e solte arquivos ou clique aqui para adicioná-los.',
    'please wait': 'Por favor, aguarde...',
    'delete': 'Excluir',
    'password is required': 'Informe a sua senha',
    'it is not a valid email': 'Informe um email válido',
    actions: 'Ações',
    'name file': 'Nome do arquivo',
    'added to': 'Adicionado em',
    type: 'Tipo',
    size: 'Tamanho',
    'are you sure you want to delete the document?': 'Tem certeza que deseja excluir o arquivo?',
    'to view': 'Visualizar',
    close: 'Fechar',
    'jira hours': 'Horas no Jira {{number}}',
    filter: 'Filtrar',
    observation: 'Observação',
    'start date': 'Data inicial',
    'end date': 'Data final',
    'sheet': 'Planilha',
    'unknown': 'Desconhecido',
    'export data': 'Exportar arquivo',
    send: 'Enviar',
    'employeeName': 'Nome do colaborador',
    'diference bigger': 'Maior que (em horas):',
    'diference smaller': 'Menor que (em horas):',
    'diference': 'Diferença de horas',
    'period': 'Período',
    remove: 'Remover',
    approve: 'Aprovar',
    'error': 'Erro',
    'success': 'Sucesso',
    details: 'Detalhes',
    emailEmployee: 'Email do colaborador',
    confirm: 'Confirmar',
    clean_filter: 'Limpar filtro',
    ascending: 'Ascendente',
    descending: 'Descendente',
    datePicker: 'pt_BR',
    'websocketError': 'Erro na comunicação via websocket',
    'view': 'Visualizar',
    'genericError': 'Desculpe, houve um erro ao executar esta ação'
  },
  [namespaces.table]: {
    'items per page': 'por página',
    'total of': 'Total de',
    'jump to': 'Vá para',
    'page': 'Página'
  },
  [namespaces.pages.login]: {
    welcome: 'Bem Vindo',
    'user is required': 'Informe o seu email'
  },
  [namespaces.pages.home]: {
    welcome: 'Olá, seja bem-vindo ao Finance Tracker!',
    'sub title': 'Nosso software tem como objetivo centralizar ações e informações importantes, para facilitar o dia a dia de nossos colaboradores em todos os seguimentos e cargos, Colaboradores, Administrativo, Rh, Gerência e Diretoria.',
    'info description': 'Desenvolvido levando em consideração o usuário como centro do objetivo, essa plataforma é dinâmica e auto-explicativa, potencializando o seu uso ao máximo para auxilio de atividades recorrentes como, horas total trabalhadas, upload de arquivos como certificações ou planilhas, MDUs, etc.',
    'explore and use to its maximum!': 'Explore e use ao máximo!',
    'team info': 'A nova versão do Finance Tracker contou com a participação dos colaboradores: Alan Victor, André Nogueira, Breno Anacleto, Caio Ferreira, Cedric de Abreu, Cesar Pierobom, Francisco Bandeira, Geraniel da Motta, Guilherme Leal, Heloisa Berniche, Igor Ferreira, Jhonatan Francisco, Jônatas Laet, José Wellyson Marques Dantas, Julio Cesar Chiuchi Araujo, Leonardo Tinassi, Luis Fernando Farnezi Brito, Matheus Quintino, Roberthy da Silva, Raul Donaire, Viviane Medeiros e Wallace Ferrante.'
  },
  [namespaces.pages.employees]: {
    title: 'Colaboradores',
    'sub title': 'Tenha total controle de dados de todos os seus colaboradores. Mantenha a lista atualizada para o melhor uso dos dados.',
    'info description': 'Você não tem uma lista de Colaboradores. Faça o upload da lista de colaboradores em ‘Atualizar colaboradores’ no menu lateral, e tenha sua lista de colaboradores atualizada.',
  },
  [namespaces.pages.employeesUpdate]: {
    title: 'Atualizar Colaboradores',
    'sub title': 'Faça o upload da planilha de colaboradores e tenha acesso rápido a todos os colaboradores da empresa. Mantenha a lista atualizada para o melhor uso dos dados.',
    'upload file': 'Upload de planilhas',
    'wait for the employee list to load.': 'Aguarde o carregamento da lista de colaboradores.',
    'employees updated successfully!': 'Colaboradores atualizados com sucesso!',
  },
  [namespaces.pages.employeesRelate]: {
    title: 'Relacionar colaborador',
    'sub title': 'Relacione os colaboradores. Padronize o nome de usuário em todos os locais de batidas de hora, fazendo com que o usuário tenha os mesmos nomes no ponto e no Jira.',
    'company': 'Empresa',
    'companyPlaceholder': 'Selecione uma empresa',
    'project': 'Projeto',
    'start hour': 'Hora de início',
    'info description': 'Você ainda não importou nenhum arquivo. Faça o upload dos arquivos em "importar arquivo" no menu lateral, e então relacione os colaboradores.',
    'not related yet': 'Ainda não foi relacionado',
    'employee updated successfully!': 'Colaborador atualizado com sucesso!',
    'jira name is mandatory': 'Nome Jira é obrigatório!',
    'cannot be more than 50 characters': 'Não pode possuir mais que 50 caracteres!',
    'company is mandatory': 'Empresa é obrigatório!',
    'total of relationships': 'Total de relacionamentos',
    'employee already linked to this company': 'Colaborador já vinculado a esta empresa',
    title_modal: 'Editar colaborador',
    notify_update_email_success: 'O email do colaborador foi atualizado com sucesso!',
    placeholderEmployeeEmail: 'Digite o email do colaborador',
    'sorting direction': 'Direção de ordenação'
  },
  [namespaces.pages.settings]: {
    title: 'Configurações',
    'sub title': 'Aqui você encontra configurações do sistema, email de notificação e outras configurações.',
    'notification email': 'Email de notificação',
    'set up and send automated emails easier.': 'Configure e envie emails automáticos de maneira mais fácil.',
    'email configuration was completed successfully': 'Configuração de email concluída com sucesso!',
    'smtp server': 'Servidor SMTP',
    'smtp connection port': 'Porta de Conexão SMTP',
    'smtp user': 'Usuário SMTP',
    'smtp password': 'Senha SMTP',
    'default sender name': 'Nome padrão do remetente',
    'use ssl/tl connection': 'Usar conexão SSL/TL',
    'authenticated email': 'Email autenticado',
    'smtp server is mandatory': 'Servidor SMPT é obrigatório',
    'smtp port is mandatory': 'A porta SMTP é obrigatória',
    'smtp port cannot exceed 99999': 'Porta SMPT não pode exceder 99999',
    'smtp user is mandatory': 'Usuário SMPT é obrigatório',
    'it is not a valid email': 'Não é um email válido',
    'cannot be more than 100 characters': 'Não pode possuir mais que 100 caracteres',
    'default sender name is mandatory': 'Nome padrão do remetente é obrigatório',
    'interval between emails is mandatory': 'intervalo entre emails é obrigatorio',
    'bulk email interval': 'Intervalo entre envios',
    'bulk email': 'Email em massa',
    'bulk interval mandatory': 'O intervalo entre envio de emails é obrigatório',
    'bulk interval characters': 'O intervalo entre envio de emails não pode conter mais que 10 caracteres',
    'bulk interval max integer exceeded': 'O intervalo entre envio de emails não pode exceder 2147483647 milissegundos',
    'bulk interval min': 'O intervalo entre envio de emails deve ser maior ou igual a 0 milissegundos',
    cannotBeMore70Characters: 'Não pode possuir mais que 70 caracteres',
  },
  [namespaces.pages.descriptionAnalysis]: {
    'send error': 'Erro ao solicitar o envio da notificação de descrição',
    'send success': 'Notificação de descrição enviada com sucesso',
    'notify all': 'Notificar todos',
    'notificate selected': 'Notificar selecionados',
    'description analysis': 'Análise de descrição de trabalho',
    'description info': 'Importe arquivos e analise os colaboradores que não adicionaram descrição no log de horas do Jira.',
    'select': 'Selecione um intervalo de tempo para exibir a análise de descrição!',
    'queryOkEmptyResult': 'Não foram encontradas análises de descrição ineficiente e/ou inexistente para o período e/ou nome selecionado.',
    'name': 'Nome',
    'worklog detail': 'Detalhes da descrição',
    'date': 'Data',
    'time': 'Tempo',
    'description': 'Descrição',
    'close': 'Fechar',
    'send notification': 'Enviar notificação',
    'time limit': 'Limite para correção',
    'cancel': 'Cancelar',
    'send': 'Enviar',
    'shipping history': 'Histórico de envio',
    'responsible': 'Responsáveis',
    'the correction date cannot be less than the current one': 'O limite para a correção não pode ser inferior a data atual',
    'the emails were sent succesfully': 'Os email(s) foram enviados com sucesso.',
    'there was an error sending the emails': 'Emails parcialmente enviados. Clique em detalhes para saber qual(ais) email(s) apresentou(aram) falha.',
    'correction limit is required': 'Campo "Limite para correção" é obrigatório',
    'bulk request sent': 'Uma solicitação de envio de email em massa foi cadastrada, voce receberá o resultado através de uma notificação.',
    'project': 'Projeto',
    'jira': 'Jira',
    'collaboratorError': 'Desculpe, houve um erro ao buscar os colaboradores para a análise.',
    'analysisError': 'Desculpe, houve um erro ao realizar a análise.'
  },
  [namespaces.pages.employeesRegister]: {
    'registerTitle': 'Cadastro de colaborador PJ',
    'registerSubTitle': 'Cadastre os usuários que não fazem uso do sistema de registro de ponto',
    'name': 'Nome',
    'admissionDate': 'Data de admissão',
    'save': 'Salvar',
    'registerSuccess': 'Cadastrado com sucesso',
    'editSuccess': 'Editado com sucesso',
    'registerError': 'Ocorreu um erro, porfavor tente novamente',
    'edit': 'Editar',
    'include': 'Incluir',
    'validationError': 'Informações invalidas, por favor verifique e tente novamente',
    'validationEmailMax50': 'Email deve conter no maximo 50 caracteres',
    'validationNameReq': 'Nome é obrigatório',
    'validationNameMax100': 'Nome deve conter no maximo 100 caracteres',
    'validationAdmissionReq': 'Data de admissão é obrigatória',
    'modalTitleCreate': 'Incluir colaborador PJ',
    'modalTitleEdit': 'Editar colaborador PJ',
    'validationTypeEmail': 'Informe um email válido',
    'updateEmail': 'Atualizar email'
  },
  [namespaces.pages.analysisHours]: {
    title: 'Análise de horas',
    'sub title': 'Importe os arquivos e analise as horas de ponto e Jira. Tenha maior controle das horas pontuadas corretamente, e das horas divergentes.',
    'info description': 'Você ainda não importou nenhum arquivo. Faça o upload dos arquivos em ‘Importar arquivo’ no menu lateral, e então analise as horas dos colaboradores.',
    'employees without disagreements': 'Sem divergência',
    'employees with disagreements': 'Com divergência',
    'difference between hours': 'Diferença entre horas',
    'successfully filtered file!': 'Arquivo filtrado com sucesso!',
    'analyze employees with and without divergence': 'Analise os colaboradores com e sem divergência',
    'time difference': 'Diferença entre horas',
    'status': 'Status',
    'observation': 'Observação',
    'name': 'Nome',
    'punch clock hours': 'Horas do ponto',
    'select a time period to display the hours analysis!': 'Selecione um intervalo de tempo para exibir a análise de horas!',
    'No hours analysis found for the search criteria entered': 'Não foram encontradas análises de horas para o período e/ou nome selecionado.',
    'send notification divergence': 'Enviar notificação de divergência',
    'send notification divergence to all': 'Enviar notificação de divergência a todos',
    'remove all selections': 'Remover todos os selecionados',
    'send to all error': 'Erro ao solicitar o envio da notificação de divergencia a todos',
    'send error': 'Erro ao solicitar o envio da notificação de divergencia',
    'correction limit': 'Limite para correção',
    'responsible': 'Responsáveis',
    'shipping history': 'Histórico de envio',
    'the emails were sent succesfully': 'Os email(s) foram enviados com sucesso.',
    'there was an error sending the emails': 'Emails parcialmente enviados. Clique em detalhes para saber qual(ais) email(s) apresentou(aram) falha.',
    'details': 'Detalhes',
    'details modal title': 'Detalhes das divergências',
    'bulk request sent': 'Uma solicitação de envio de email em massa foi cadastrada, voce receberá o resultado através de uma notificação.',
    'request sent': 'Uma solicitação de envio de email foi cadastrada, voce receberá o resultado através de uma notificação.',
    'correction limit is required': 'Campo "Limite para correção" é obrigatório',
    'the file was downloaded successfully': 'A exportação do arquivo foi realizada com sucesso!',
    'the correction date cannot be less than the current one': 'A data de correção não pode ser inferior a atual',
    'approve an employee': 'Aprove um Colaborador',
    'approve an employee with divergence': 'Aprove um Colaborador com divergências. Se necessário, faça uma observação.',
    'approve': 'Aprovar',
    'remove employees': 'Remover Colaboradores.',
    'remove approved employees with divergence': 'Remover os colaboradores aprovados com divergência.',
    'the employee was successfully approved': 'O Colaborador foi aprovado com sucesso!',
    'the employee was successfully disapproved': 'O Colaborador foi desaprovado com sucesso!',
    'approved employees': 'Aprovados com divergência',
    'special employees': 'Especiais',
    close: 'Fechar',
    title_details_divergence: 'Detalhes divergentes',
    subtitle_details_divergence: 'Visualize o detalhamento de cada item divergente',
    'no results were found for generating the file': 'Nenhum resultado foi encontrado para a geração do arquivo.',
    'smaller canot be smaller than bigger': 'O valor informado não deve ser menor que o campo "Maior que"',
    'please input valid info': 'Favor, inserir um valor válido nos campos "maior que" e "menos que"',
    'data column title': 'Data',
    'clock in column title': 'Ponto',
    'jira column title': 'Jira',
    'divergence column title': 'Diferença',
    'details request general error': 'Desculpe, houve um erro ao buscar os detalhes de divergencias.',
    'noUsersFoundWithDivergences': 'Não foram encontrados usuarios com divergencias',
    'errorTimeAnalysisById': 'Erro ao realizar a analise detalhada por usuario',
    'errorTimeAnalysisByFilter': 'Erro ao realizar a analise',

  },
  [namespaces.pages.importFile]: {
    title: 'Importar arquivos',
    'sub title': 'Faça o upload das planilhas de horas de ponto e Jira dos colaboradores, e tenha tudo armazenado no mesmo local.',
    'import files and stay up to date.': 'Importe arquivos e mantenha-se atualizado.',
    'file imported successfully!': 'Dados importados com sucesso!',
    'files were partially imported.': 'Dados importados parcialmente. Favor verificar o log de erros.',
    'error importing data': 'Ocorreu um erro ao importar seus dados. Favor verificar o log de erros.',
    'incorrect file format': 'Formatação de arquivo incorreta.',
    'duplicated file': 'Já existe um arquivo importado com mesmo nome e tamanho.',
    'questions about file template': 'Dúvidas sobre o template de importação',
    'view log': 'Visualizar Log',
    'download log': 'Exportar log',
    'files were partially consolidated.': 'Alguns dos dados enviados já estão aprovados. Estes dados não foram alterados.',
    'error importing the file': 'Erro ao importar o arquivo {fileName}',
    'the file was successfully imported': 'O arquivo {fileName} foi importado com sucesso!',
    'The file has been imported, but some of its data has already been consolidated previously.': 'O arquivo {fileName} foi importado, porém alguns dos seus dados já foram consolidados previamente.'
  },
  [namespaces.pages.consultFiles]: {
    title: 'Consultar arquivos',
    'sub title': 'Consulte os arquivos  que foram importados.',
    'see the imported files.': 'Consulte os arquivos importados.',
    'info description': 'Você não possui uma lista de arquivos importados . Realize essa importação em “Importar arquivos” no menu lateral.',
    'start date': 'Data inicial',
    customer: 'Cliente',
    project: 'Projeto',
    'refresh': 'Atualizar',
    key: 'Chave',
    abstract: 'Resumo',
    'cost center': 'Centro de custo',
    'display name': 'Nome de exibição',
    'time spent': 'Tempo gasto',
    'job description': 'Descrição do trabalho',
    'success files': 'Arquivos importados com sucesso',
    'error files': 'Arquivos importados com erro',
    'warning subtitle': ' ATENÇÃO: A importação de planilhas utiliza muitos recursos do servidor, então o sistema pode apresentar instabilidade caso esta rotina esteja sendo executada.'
  },
  [namespaces.pages.consolidated]: {
    title: 'Consolidados',
    subtitle: 'Visualize quantas horas os colaboradores ou clientes possuem em cada projeto.',
    employee: 'Colaborador',
    customer_other: 'Clientes',
    'select customer': 'Selecione...',
    'select project': 'Selecione...',
    'select cost center': 'Selecione...',
    export: 'Exportar',
    'search employee': 'Pesquisar...',
    'error prefix': 'Por favor, selecione o',
    period: 'período',
    customer_one: 'Cliente',
    project: 'projeto',
    title_period: 'Período',
    title_select_customer: 'Cliente',
    title_select_project: 'Projeto',
    title_select_cost_center: 'Centro de custo',
    title_search_employee: 'Pesquisar colaborador',
    export_file: 'Exportar arquivo',
    type_export: 'Tipo',
  },
  [namespaces.pages.period]: {
    title: 'Fechar período',
    subtitle: 'Realize o fechamento e a reabertura de período de pontos consolidados.',
    close_period: 'Deseja fechar o período de {period}?',
    reopen_period: 'Deseja reabrir o período de {period}?',
    close_period_success: 'Período de {period} fechado com sucesso!',
    reopen_period_success: 'Período de {period} reaberto com sucesso!',
    closing_date: 'Data de fechamento',
    empty_period: 'Você não possui períodos fechados.',
    reopen: 'Reabrir',
  },
  [namespaces.errors]: {
    'unauthorized': 'Acesso não autorizado.',
    'default': 'Um erro inesperado aconteceu. Tente novamente mais tarde.',
    'session closed': 'Sua sessão foi encerrada. Por favor, faça login novamente!',
    'internal server error': 'Erro interno no servidor',
    'the email settings were not found': 'As configurações de email não foram encontradas',
    'Illegal Address': 'Colaborador "{user}" está com o email vazio ou nulo',
    'Invalid Addresses': 'O email "{user}" está inválido',
    'invalid_grant': 'Usuário ou senha inválidos',
    'Couldn\'t connect to host': 'Não foi possível conectar ao host',
    'Authentication unsuccessful': 'Não foi possível realizar a autenticação, email ou senha inválidos',
    'STARTTLS is required to send mail': 'O Protocolo TLS é obrigatório para o envio do email',
    'unable to find a collaborator': 'Colaborador não encontrado. Verifique se o nome está correto',
    'some data is already approved': 'Este arquivo não pôde ser excluído pois alguns de seus dados já foram validados.',
    email_already_registered: 'O email informado já está vinculado para um colaborador!',
    'There is no consolidated for the reported period.': 'Não há consolidado para o período reportado.',
    'There are one or more periods closed on the reported date.': 'Há um ou mais períodos fechados para a data informada.',
    'It was not possible to close this period, as there are employees with divergence. Please check the time analysis.': 'Não foi possível encerrar este período, pois há colaboradores com divergência. Por favor, verifique a análise de horas.'
  },
  [namespaces.template.modalImportingFiles]: {
    textBody1: 'Acesse a rotina',
    textBody2: 'Consultar Arquivos',
    textBody3: ' para visualizar o status da importação!',
    boldText1: 'Importante: O tempo de importação pode variar conforme o tamanho do arquivo.',
    boldText2: 'Durante o processamento, poderá haver instabilidade no sistema! Aguarde a finalização do processo!',
  },
  [namespaces.template.importFile]: {
    'template import': 'Template de importação',
    'required fields description': 'Verifique abaixo os campos necessários para importar uma planilha de horas em cada tipo',
    'jira sheet import': 'Importação planilha de Jira',
    'rm timesheet import': 'Importação planilha de ponto RM',
    'saturno timesheet import': 'Importação planilha de ponto Saturno',

    'employee required fields': 'Campos necessários do colaborador',
    'company required fields': 'Campos necessários da empresa',

    'company description': 'Informe de quem o Jira está sendo importado',
    'customer description': 'Informe o nome do cliente',
    'project description': 'Informe o nome do projeto',
    'jira description': 'Informe o título do Jira trabalhado',
    'cost center description': 'Informe o centro de custo vinculado ao Jira',
    'employee name description': 'Informe o nome do colaborador',
    'register activity date description': 'Informe a data do registro da atividade no formato dia/mês/ano',
    'register worked time description': 'Informe o tempo trabalhado no Jira no formato decimal',

    'employee description': 'Informe o nome do colaborador cadastrado no sistema de ponto',
    'employee admission description': 'Informe a data de admissão do colaborador no formato dia/mês/ano',
    'attendance register description': 'Informe a data de registro do ponto no formato dia/mês/ano',
    'delay time description': 'Informe o tempo de atraso do dia no formato hora:minutos',
    'absence description': 'Informe o tempo de falta do dia no formato hora:minutos',
    'overtime description': 'Informe o tempo extra executado do dia no formato dia/mês/ano',
    'allowance time description': 'Informe o tempo de abono do dia no formato hora:minutos',
    'base time description': 'Informe o tempo base do dia no formato hora:minutos',

    'employee registration': 'Informe a matricula do colaborador no sistema de ponto',
    'employee termination description': 'Informe a data de desligamento do colaborador no formato dia/mês/ano',

    'total work with appreciation description': 'Informe o tempo total de trabalho valorado do dia hora:minutos',
    'total work without appreciation description': 'Informe o tempo de trabalho sem valorização do dia no formato hora:minutos',
    'example': 'Exemplo'
  },
  [namespaces.pages.costCenterRelate]: {
    'page_title': 'Relacionar centro de custo',
    'page_subTitle': 'Relacione o centro de custo a seu respectivo projeto/cliente.',
    'jiraCompany_formItemLabel': 'Empresa',
    'client_formItemLabel': 'Cliente',
    'table_column_id': 'ID',
    'table_column_client': 'Cliente',
    'table_column_status': 'Status',
    'status_active': 'Ativo',
    'status_inactive': 'Inativo',
    'button_filter_text': 'Filtrar',
    'button_reset_text': 'Limpar',
    'success': 'Centro de custo alterado com sucesso',
    'error': 'Ocorreu um erro ao tentar alterar o centro de custo, tente novamente',
    'attach': 'Vincular centro de custo',
    'project': 'Projeto',
    'company': 'Empresa',
    'client': 'Cliente',
    'add': 'Adicionar ->',
    'placeholder_input_cost_center': 'Digite o centro de custo',
    'filter': 'Filtrar',
    'clean': 'Limpar',
    'remove': '<- Remover',
    'cancel': 'Cancelar',
    'save': 'Salvar',
  },
}