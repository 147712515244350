import styled from 'styled-components'
import { CheckOutlined } from '@ant-design/icons'

export const Content = styled.div`
  padding: 30px 25px;
  color: ${({ theme }): any => theme.colors.BLACK_PEARL};
`

export const Header = styled.div`
  padding-bottom: 10px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 47px;
  padding-bottom: 24px;
`

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 159.69%;
  padding-bottom: 20px;
`

export const SubRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_MD}) {
    flex-direction: column;
  }
`

export const FilterAnalysis = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 25px;
`

export const DatePickerBox = styled.div`
  padding-right: 10px;
  padding-bottom: 21px;
  font-weight: bold;
`

export const SizeButton = styled.div`
  width: 10rem;

  button {
    height: 33px;
  }
`

export const SizeInfoDataNull = styled.div`
  margin: 2rem;
`

export const ApproveIcon = styled(CheckOutlined)`
  color: ${({ theme }): any => theme.colors.DARK_GREEN};
`

export const IconInfoDataNull = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.3rem;
    color: #FAAD14;
    margin-right: .3rem;
  }
`

// export const ApproveIcon = styled(CheckOutlined)`
//   color: ${({ theme }) => theme.colors.DARK_GREEN};
// `

export const FilterContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
padding: 10px 0px 0px 10px;
`