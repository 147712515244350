import { Rule } from 'antd/lib/form'
import { TFunction } from 'i18next'
import { TForm } from '.'
import { namespaces } from 'i18n/i18n.constants'

export const initialValues: TForm = {
  clockName: '',
  email: '',
  nameJira: '',
  selectCompany: 0
}

export const validation = (key: string, t: TFunction<'namespaces', undefined>) => {
  switch (key) {
    case 'email':
      return [{
        type: 'email',
        message: t('it is not a valid email', { ns: namespaces.common })
      },
      {
        max: 70,
        message: t('cannotBeMore70Characters', { ns: namespaces.pages.settings })
      }] as Rule[]

    case 'nameJira':
      return [{
        required: true,
        message: t('jira name is mandatory', { ns: namespaces.pages.employeesRelate })
      },
      {
        max: 50,
        message: t('cannot be more than 50 characters', { ns: namespaces.pages.employeesRelate })
      }] as Rule[]

    case 'selectCompany':
      return [{
        required: true,
        message: t('company is mandatory', { ns: namespaces.pages.employeesRelate })
      }] as Rule[]

    default:
      [] as Rule[]
  }
}