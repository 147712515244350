import type { Moment } from 'moment'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import generatePicker from 'antd/es/date-picker/generatePicker'

/*
    O ANT DESIGN V5+ nao usa mais o Momentjs por padrao, por isso devemos
    ou usar o Dayjs ou criar um componente como este para voltar o
    comportamento padrao

    https://ant.design/docs/react/use-custom-date-library
*/

const DatePicker = generatePicker<Moment>(momentGenerateConfig)

export default DatePicker